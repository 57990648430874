<div class="main-right-wrapper">
    <div class="dashboard-container">
        <div (click)="location.back()" class="back-title"><img src="./assets/images/svg/back-arrow-color.svg " alt="print-logo" />  Back</div>
    </div>
    <div class="sales-box-wrapper">
        <div class="box-titile-wrapper">
            <div class="box-titile-left">
                <div class="box-titile-sec">
                    <div class="box-titile">{{ vendorCommission?.name | titlecase }}</div>
                    <button *ngIf="vendorCommission?.status == 1" class="btn button-primary active">Active</button>
                    <button *ngIf="vendorCommission?.status == 0" class="btn button-primary inactive">Inactive</button>
                </div>
                <div class="box-email">{{ vendorCommission?.email }}</div>
            </div>
            <div class="box-titile-right">
                <button class="btn primary padding-btn-custom " (click)="addPayment()"> Add Payment</button>
            </div>
        </div>
        <div class="box-phone-sec">
            <div class="box-phone"><img src="./assets/images/svg/icon-phone.svg" alt="print-logo" />{{vendorCommission?.mobile}}</div>
            <div class="box-location"><img src="./assets/images/svg/map-pin-icon.svg" alt="print-logo" />{{vendorCommission?.address}}</div>
        </div>
        <div class="qr-code-wrapper-sec">
            <div class="qr-code-wrapper qr-code-flex">
                <div class="qr-code-sec">
                    <div class="qr-code-title">Total QR Memory Codes</div>
                    <div class="qr-code-num">{{ vendorCommission?.vendor_total_qrcodes }}</div>
                </div>
                <div class="qr-code-sec">
                    <div class="qr-code-title">QR Memory Codes Issued</div>
                    <div class="qr-code-num">{{ vendorCommission?.vendor_sold_qrcodes }}</div>
                </div>
                <div class="qr-code-sec">
                    <div class="qr-code-title">Active Profiles</div>
                    <div class="qr-code-num">{{ vendorCommission?.active_profiles }}</div>
                </div>
                <div class="qr-code-sec">
                    <div class="qr-code-title">Commission</div>
                    <div class="qr-code-num">{{ vendorCommission?.commission }}%</div>
                </div>
            </div>
            <div class="qr-code-wrapper qr-code-flex commission-left-vendor position-relative">
                <div class="qr-code-sec vendor-center">
                    <div>
                        <div class="qr-code-title">Commission Amount</div>
                        <div class="qr-code-num">${{vendorCommission?.total_commission | number : '1.2-2'}}</div>
                    </div>
                </div>
                <div class="qr-code-sec vendor-center">
                    <div>
                        <div class="qr-code-title">Commission Paid</div>
                        <div class="qr-code-num">${{vendorCommission?.commission_paid | number : '1.2-2'}} </div>
                    </div>
                </div>
                <div class="qr-code-sec vendor-center">
                    <div>
                        <div class="qr-code-title">Remaining Amount</div>
                        <div class="qr-code-num">${{vendorCommission?.total_commission - vendorCommission?.commission_paid | number : '1.2-2'}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-data-table [colFields]="colFields" [tableData]="tableData" (deleteVendorPayment)="deletePayment($event)"></app-data-table>
