import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class VendorDetailsService {

  constructor(public http: HttpClient) { }
  getVenodrData(userId: number) {
    return this.http.get(`${environment.apiAdminUrl}get-vendor-commission?user_id=${userId}`);
  }
  getVendorDetails(userId: number) {
    return this.http.get(`${environment.apiAdminUrl}get-vendor-commission?user_id=${userId}`);
  }
  getData(userId: number){
    return this.http.get(`${environment.apiAdminUrl}vendor-transaction/${userId}`);
  }
  addVendorTransaction(payloads:any){
    return this.http.post(`${environment.apiAdminUrl}add-vendor-transaction?${payloads}`,'');
  }
  // exportSalesReport(params) {
  //   if(params!==undefined){
  //     return this.http.get(`${environment.apiAdminUrl}export-vendor_commission-report?${params}&timezone=${moment.tz.guess()}`);
  //   }else{
  //     return this.http.get(`${environment.apiAdminUrl}export-vendor_commission-report?timezone=${moment.tz.guess()}`);
  //   }
  // }

  exportSalesReport(params?: any) {
    const timezone = moment.tz.guess(); // Get the local timezone
    const apiUrl = `${environment.apiAdminUrl}export-vendor_commission-report?timezone=${timezone}`;
  
    const requestUrl = params ? `${apiUrl}&${params}` : apiUrl;
  
    this.http.get(requestUrl, {
      responseType: 'arraybuffer' // Expecting an array buffer response for file download
    }).subscribe(response => this.downLoadFile(response, 'application/ms-excel', 'Vendor commission.xlsx'));
  }
  
  downLoadFile(data: any, type1: string, filename: string) {
    const blob = new Blob([data], { type: type1 });
    const url = window.URL.createObjectURL(blob);
    
    const a = document.createElement('a'); // Create an anchor element
    a.href = url;
    a.download = filename; // Set the file name
    document.body.appendChild(a); // Append it to the DOM
    a.click(); // Trigger download
    document.body.removeChild(a); // Clean up by removing the anchor element
    window.URL.revokeObjectURL(url); // Revoke the object URL to free memory
  }
}
