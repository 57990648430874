export const AGENT_DETL_COLS = [
    {
        headerName: 'QR Memory Codes',
        fieldName: 'serial_number',
        // nestedField:null,
        fieldType: 'TEXT',
        sortOrder:true,
        orderby:'serial_number'
    },
    {
        headerName: 'Box Number',
        fieldName: 'box_no',
        nestedField:null,
        fieldType: 'TEXT',
        sortOrder:false
    },
    {
        headerName: 'Issued To',
        fieldName: 'name',
        // nestedField:'name',
        fieldType: 'TEXT',
        sortOrder:true,
        orderby:'name'
    },
    {
        headerName: 'Issued On',
        fieldName: 'sold_on',
        // nestedField:null,
        fieldType: 'DATE',
        sortOrder:true,
        orderby:'sold_on'
    },
    {
        headerName: 'Status',
        fieldName: 'qr_status',
        fieldType: 'STATUSSOLD',
        sortOrder: false
    }
];
