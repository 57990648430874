export const USER_MNGMNT_COLS = [
    {
        headerName: 'Name',
        fieldName: 'name',
        fieldType: 'IMG_TEXT',
        sortOrder: true
    },
    {
        headerName: 'Email ID',
        fieldName: 'email',
        fieldType: 'TEXT',
        sortOrder: true
    },
    {
        headerName: 'Phone Number',
        fieldName: 'contact_number',
        fieldType: 'TEXT',
        sortOrder: false
    },
    {
        headerName: 'Type',
        fieldName: 'account_type',
        fieldType: 'USER_TEXT',
        sortOrder: true
    },
    // {
    //     headerName: 'QR Memory Code',
    //     fieldName: 'serial_number',
    //     fieldType: 'TEXT',
    //     sortOrder: false
    // },
    // {
    //     headerName: 'Deceased Member',
    //     fieldName: 'first_name',
    //     fieldType: 'DEC_TEXT',
    //     sortOrder: true
    // },
    // {
    //     headerName: 'Payment Mode',
    //     fieldName: 'payment_mode',
    //     fieldType: 'MODE',
    //     sortOrder: true
    // },
    {
        headerName: 'Added On',
        fieldName: 'created_at',
        fieldType: 'DATE',
        sortOrder: true
    },
    {
        headerName: 'Status',
        fieldName: 'status',
        fieldType: 'STATUS',
        sortOrder: true
    },
    {
        headerName: '',
        fieldName: '',
        fieldType: 'action',
    },
];

