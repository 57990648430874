import { MagicNumber } from './app-magic-string';

export const FILTER_DATA = {
  duration: null,
  status: null,
  search: null,
  page: MagicNumber.one,
  order: null,
  order_by: null,
  download_csv: null,
  payment_mode:null,
  vendor_id : null,
  admin_shipping_status : null,
  account_type: null
};
