<div style="margin-top: 25px;">
    <div class="modal-header add-sales-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal"
            aria-label="Close"(click)="modalRef.hide();reset()"></button>
    </div>
    <div class="modal-body">
        <h1 class="modal-export-title">Commission Filter</h1>
        <h1 class="modal-export-subtitle">Select the month and year you want to see</h1>
        <form  class="modal-form" [formGroup]="customFilter" (ngSubmit)="submitFilter()">
            <div class="form-floating form-floating-search  birth-input date-picker-sec">
                <mat-form-field appearance="fill" (mousedown)="picker.open()" (click)="picker.open()">
                    <mat-label>Select Date Range</mat-label>
                    <mat-date-range-input (click)="picker.open()" (mousedown)="picker.open()"  [rangePicker]="picker" [max]="tomorrow"  [readonly]="true">
                      <input matStartDate placeholder="MM/DD/YY - MM/DD/YY" formControlName="start_date">
                      <input matEndDate formControlName="end_date">
                    </mat-date-range-input>
                    <mat-datepicker-toggle [disabled]="false" matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker  [disabled]="false" #picker></mat-date-range-picker>
                  </mat-form-field>
                  <mat-error class="invalid-input-text-date" *ngIf="customFilter.controls.start_date.invalid && customFilter.controls.start_date.touched">
                    Start date is required.
                </mat-error>
                <mat-error class="invalid-input-text-date" *ngIf="!customFilter.controls.start_date.invalid && customFilter.controls.start_date.touched && customFilter.controls.end_date.invalid && customFilter.controls.end_date.touched">
                    End date is required.
                </mat-error>
            </div>
            <button type="submit" [disabled]="customFilter.invalid" class="btn primary w-100 mb-3">Filter</button>
        </form>
    </div>
</div>