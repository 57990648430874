import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentManagementService {

  constructor(public http: HttpClient) { }

  getData() {
    return this.http.get(`${environment.apiUrl}get_static_content_list`);
  }
  getContentByPage(page: string) {
    return this.http.get(`${environment.apiUrl}get_static_content?page=${page}`);
  }
  updateContentByPage(fd) {
    return this.http.post(`${environment.apiUrl}update_static_content?`, fd);
  }
}
