import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { FilterData } from '../app-interface';
import { FileUploadResponse } from '../modal/fileUploadResponse';
import { ServerResponse } from '../modal/serverResponse';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class AdManagementService {

  constructor(public http: HttpClient) { }

  getData(params: FilterData) { 
    return this.http.get(`${environment.apiAdminUrl}get-ad-space-clicks?${params}&timezone=${moment.tz.guess()}`);
  }

  getAdData() {
    return this.http.get(`${environment.apiAdminUrl}get-ad-spaces`);
  }

  changeAdStatus(adId,status) {
    return this.http.put(`${environment.apiAdminUrl}change-ad-space-status?ad_space_id=${adId}&status=${status}`, null);
  }

  editAdSpace(params) {
    return this.http.post(`${environment.apiAdminUrl}edit-ad-space?${params}`, '');
  }

  getAdList() {
    return this.http.get(`${environment.apiUrl}get-ad-spaces`);
  }

  uploadFile = (file: File) => this.uploadFileToServer(file);

  uploadFileToServer = (file: File, filePath?: string) => {
    const url = environment.apiUrl + 'common/image-upload';
    const data = new FormData();
    data.append('image', file);
      data.append('base_path', 'ad_space');
    return this.http.post<ServerResponse<FileUploadResponse>>(url, data).pipe(
      map(res => {
        if (res.data?.url) {
          return res.data.url;
        }
        throw 'Oops something went wrong.';
      })
    ).toPromise();
  };
};
