import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agent-management',
  templateUrl: './agent-management.component.html'
})
export class AgentManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
