import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../../common/app-interface';
import { MagicNumber } from '../../../../app/common/app-magic-string';
import { DeactiveAccountComponent } from '../../../../app/common/modal/deactive-account/deactive-account.component';
import { ViewInfomationComponent } from '../../../../app/common/modal/view-infomation/view-infomation.component';
import { InformationManagementService } from '../../../../app/common/services/information-management.service';

@Component({
  selector: 'app-information-detail',
  templateUrl: './information-detail.component.html'
})
export class InformationDetailComponent {
  public informationId;
  public informationInfo;
  constructor(public modalService: BsModalService, public activateRoute: ActivatedRoute,
    public informationService: InformationManagementService, public toastr: ToastrService,
    public router: Router,
    public modelref: BsModalRef) {
    this.activateRoute.params.subscribe((params) => {
      if (params.id) {
        this.informationId = params.id;
        this.informationDetails();
      }
    });
  }


  /**
   * API Call for getting information details
  */
  informationDetails() {
    this.informationService.getinformationDetails(this.informationId).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.informationInfo = res.data[MagicNumber.zero];
        this.informationInfo.galleryPictures = JSON.parse(this.informationInfo.other_picture);
      }
    });
  }

  /**
     * API Call for deactiving the account
    */
  deactiveAccount() {
    if (this.informationInfo.status === MagicNumber.one) {
      const initialState = { data: { userId: this.informationId, type: 'information' } };
      this.modelref = this.modalService.show(DeactiveAccountComponent, { class: 'max-width-605 modal-dialog-centered', initialState });
      this.modelref.content.deactiveEvent.subscribe((res) => {
        if (res.type === 'information') {
          this.informationDetails();
        }
      });
    } else {
      this._activeAccountant();
    }
  }

  /**
     * API Call for activating the account
    */
  private _activeAccountant() {
    const payload = {
      is_deactivate: 1,
      userId: this.informationId
    };
    this.informationService.deactiveAccount(payload).subscribe((res: IResponse) => {
      this.toastr.success(res.message);
      this.informationDetails();
    });
  }

  /**
     * To open the view information modal
    */
  editInformation() {
    this.modalService.show(ViewInfomationComponent, { class: 'max-width-1029 modal-dialog-centered' });
  }
  /**
     * To navigate to list
    */
  movetoList() {
    this.router.navigate(['/information-management/information-list']);
  }
}
