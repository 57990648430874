import { Component, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IResponse } from '../../app-interface';
import { MagicNumber } from '../../app-magic-string';
import { ViewInfomationComponent } from '../../modal/view-infomation/view-infomation.component';
import { InformationManagementService } from '../../services/information-management.service';
import { UtilityService } from '../../services/utility.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-information-details',
  templateUrl: './information-details.component.html'
})
export class InformationDetailsComponent {
  public tabName = 'aboutUs';
  @Input() informationData;
  @Input() isEdit;
  public galleryPictures = [];
  public previewImage;

  public qrcodeImage = null;

  constructor(public modalService: BsModalService, public utilityService: UtilityService,public toastr: ToastrService,
    public modelref: BsModalRef, private readonly _informationService: InformationManagementService) { }


  /**
   * To edit the information
  */
  editInformation(editSection, type1: string, fieldName: string) {
    const initialState = { data: { section: editSection, type: type1 } };
    this.modelref = this.modalService.show(ViewInfomationComponent, { class: 'max-width-1029 modal-dialog-centered', ignoreBackdropClick: true, initialState });
    this.modelref.content.event.subscribe((res) => {
      if (res.data) {
        this._informationService.updateInformationDetailsById(res.data, this.informationData && this.informationData.id, fieldName).subscribe((res1: IResponse) => {
          this.informationData = res1.data[0];
        });
      }
    });
  }

  /**
     * To get the qr code
    */
  getQrCode() {
    const id = this.isEdit ? this.informationData.id : this.informationData.id;
    this.utilityService.getQrCode(id).subscribe((res: IResponse) => {
      this.qrcodeImage = res.data;
      this.informationData.qrCode = res.data;
      this.informationData = { ...this.informationData };
    });
  }
  /**
     * Opening the video in new tab
    */
  showVideo(video: string) {
    window.open(video, '_blank');
  }

  /**
   * Extracting name from json
  */
  extractNameFromJson(array) {
    if (array) {
      return JSON.parse(array);
    }
  }

  /**
     * To delete the image
     * @param index is required
    */
  deleteImage(index: number,id) {
    this.informationData && this.informationData.member_gallery.splice(index, 1);
    this._informationService.deleteMedia(id).subscribe((res: IResponse) => {
      this.toastr.success(res.message);
      });

  }

  /**
     * To delete the video
     * @param i is required
    */
  deleteVideo(i: number) {
    const videoData = this.extractNameFromJson(this.informationData && this.informationData.video);
    videoData.splice(i, 1);
    this._informationService.updateInformationDetailsById(JSON.stringify(videoData), this.informationData && this.informationData.id, 'video').subscribe((res: IResponse) => {
      this.informationData.video = res.data[MagicNumber.zero].video;
    });
  }

  transformPhoneNumber(value: string): string {
    if (!value) {
      return '';
    }
    
    // Example: Formatting the phone number as 8-(XXX)XXX-XXXX
    const formattedNumber = `+1-(${value.slice(0, MagicNumber.three)})${value.slice(MagicNumber.three, MagicNumber.six)}-${value.slice(MagicNumber.six)}`;
    
    return formattedNumber;
  }

  copyMemoryCode(url) {
    const routeUrl = url;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.innerText = routeUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success('QR Memory Code Copied');
  }
}
