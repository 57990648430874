export const QRCODE_MNGMNT_COLS = [
  {
      headerName: 'QR Memory Code',
      fieldName: 'serial_number',
      nestedField:null,
      fieldType: 'ADDRESS',
      sortOrder:true,
      orderby:'serial_number'
  },
  {
    headerName: 'Box Number',
    fieldName: 'box_no',
    nestedField:null,
    fieldType: 'TEXT',
    sortOrder:false
    },
  {
      headerName: 'Generated On',
      fieldName: 'created_at',
      fieldType: 'DATE',
      sortOrder:true,
      orderby:'generated_on'
  },
  {
    headerName: 'Agent',
    fieldName: 'vendor_name',
    fieldType: 'TEXT',
    sortOrder:true,
    orderby:'vendor_name'
},
  {
      headerName: 'Issued To',
      fieldName: 'user_name',
      fieldType: 'TEXT',
      sortOrder:true,
      orderby:'name'
  },
  {
      headerName: 'Issued On',
      fieldName: 'sold_on',
      fieldType: 'DATE',
      sortOrder:true,
      orderby:'assigned_on'
  },
  {
      headerName: 'Activated On',
      fieldName: 'user_created_at',
      fieldType: 'DATE',
      sortOrder:false,
      orderby:'activated_on'
  },
  {
      headerName: 'Status',
      fieldName: 'qr_status',
      fieldType: 'QR_STATUS',
      sortOrder: false,
      orderby:'qr_status'
  },
  {
    headerName: 'Action',
    fieldName: 'added_by',
    fieldType: 'QR_ICON',
    sortOrder:false,
    orderby:null
}

];

