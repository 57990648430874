import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../../common/app-interface';
import { environment } from '../../../../environments/environment';
import { ContentManagementService } from '../../../../app/common/services/content-management.service';
import { UtilityService } from '../../../../app/common/services/utility.service';

@Component({
  selector: 'app-cms-detail',
  templateUrl: './cms-detail.component.html'
})
export class CmsDetailComponent {
  public cmsForm: FormGroup;
  public page;
  public cmsName;
  public isSubmitted = false;
  public picturesArr = ['', '', ''];
  public tinyMceKey;
  constructor(public formBuilder: FormBuilder, public activatedRoute: ActivatedRoute, public cmsService: ContentManagementService, public router: Router, 
    public toastr: ToastrService, public utilityService: UtilityService) {
    this.tinyMceKey = environment.tinyMceKey;
    this.cmsForm = this.formBuilder.group({
      content: ['', Validators.required]
    });
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.page = params.id;
        this.cmsDetail();
      }
    });
  }


  get content() { return this.cmsForm.get('content'); }

  /**
     * API Call for getting the cms details based on page
    */
  cmsDetail() {
    this.cmsService.getContentByPage(this.page).subscribe((res: any) => {
      if (res && !res.errors) {
        this.cmsForm.patchValue({ content: res.data.content });
        this.cmsName = res.data.title;
        if (res.data.images && JSON.parse(res.data.images).length) {
          this.picturesArr.splice(0, JSON.parse(res.data.images).length, ...JSON.parse(res.data.images));
        }
      }
    });
  }
  /**
   * API Call for saving the changes
  */
  saveChanges() {
    this.isSubmitted = true;
    if (this.cmsForm.valid) {
      const payload = {
        page: this.page,
        content: this.cmsForm.value.content,
        images: []
      };

      this.picturesArr.forEach((e) => {
        if (e !== '') {
          payload.images.push(e);
        }
      });
      const fd = new FormData();
      fd.append('page', this.page);
      fd.append('content', this.cmsForm.value.content);
      fd.append('images', JSON.stringify(payload.images));
      this.cmsService.updateContentByPage(fd).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toastr.success(res.message);
          this.router.navigate(['/content-management']);
        }
      });
    } else {
      this.cmsForm.markAllAsTouched();
    }
  }

  /**
     * API Call for uploading the images
     * @param event and @param i required
  */
  fileSelected(event, i?) {
    const fd = new FormData();
    fd.append('image', event.target.files[0]);
    this.utilityService.uploadImage(fd, 'other').subscribe((res: any) => {
      if (res && !res.errors) {
        this.toastr.success('Image uploaded successfully');
        if (i >= 0) {
          this.picturesArr[i] = res.data.url;
        } else {
          this.picturesArr.push(res.data.url);
        }
      }
      event.target.value = '';
    });
  }
  /**
   * To delete the image
   * @param i is required
  */
  deleteImage(i) {
    this.picturesArr.splice(i, 1, '');
  }
}
