import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../../common/app-interface';
import { DeactiveAccountComponent } from '../../../../app/common/modal/deactive-account/deactive-account.component';
import { AgentEditProfileComponent } from '../../../../app/common/modal/agent-edit-profile/agent-edit-profile.component';
import { AgentManagementService } from '../../../../app/common/services/agent-management.service';
import { AGENT_DETL_COLS } from './agent-detail.col.def';
import { CustomFilterComponent } from '../../../common/modal/custom-filter/custom-filter.component';
import { CommonService } from '../../../common/services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MagicNumber } from '../../../common/app-magic-string';
@Component({
  selector: 'app-agent-detail',
  templateUrl: './agent-detail.component.html'
})
export class AgentDetailComponent {

public agentId;
  public agentDetails;
  public colFields = AGENT_DETL_COLS;
  public tableData = {
    tableHeading: 'Assigned QR Memory Codes', tableType: 'agdetail',
    serviceName: 'dashboardService',
    containerClass: 'table-container-sales-detail', headClass: 'table-head-user', rowClass: 'table-row-user',
  hideSearch: true,
  hideFilter: true,
  vendor_id : null,
  showUnassignQR: true
  };
  cssClass1 ='modal-dialog modal-dialog-centered';
  commissionDetails:any;
  customFilter : FormGroup;
  params: any;
  filterStatus = 'Current Month';
  customFilterModalOpen = false;
  filterOld = 'Current Month';

  constructor(public modalService: BsModalService, public activateRoute: ActivatedRoute, public agentService: AgentManagementService,
    public toastr: ToastrService, public modelref: BsModalRef, public router: Router , public commonService : CommonService,public formBuilder: FormBuilder) {
    this.activateRoute.params.subscribe((params) => {
      if (params.id) {
        this.agentId = params.id;
        this.getAgentDetails();
      }
    });
    this.tableData.vendor_id = this.agentId;
    this.customFilter = this.formBuilder.group({
      start_date: [new Date().toISOString().slice(MagicNumber.zero,MagicNumber.eight) + '01', [Validators.required]],
      end_date: [new Date().toISOString().slice(MagicNumber.zero,MagicNumber.ten), [Validators.required]],
    });
    this.getCommission();
    this.commonService.commissionData.subscribe((res)=>{
      this.commissionDetails = res?.data;
      res?.status ? this.filterStatus = 'Custom' : '';
    });
    this.commonService.commissionDataStatus.subscribe((res)=>{
      res?.status ? this.filterStatus = this.filterOld : '';
    });
  }



  /**
   * API Call for deactivating the account
  */
  deactiveAccount() {
    if (this.agentDetails.status === 1) {
      const initialState = { data: { userId: this.agentId, type: 'user' } };
      this.modelref = this.modalService.show(DeactiveAccountComponent, { class: 'max-width-605 modal-dialog-centered', initialState });
      this.modelref.content.deactiveEvent.subscribe((res) => {
        if (res.type === 'user') {
          this.getAgentDetails();
          this.getCommission();
        }
      });
    } else {
      this.activateAccount();
    }
  }

  /**
   * To open the edit profile
  */
  editProfile() {
    const initialState = { data: this.agentDetails };
    this.modelref = this.modalService.show(AgentEditProfileComponent, { class: 'max-width-605 modal-dialog-centered', initialState });
    this.modelref.content.editEvent.subscribe((res) => {
      if (res) {
        this.getAgentDetails();
      }
    });
  }
  /**
   * API Call for getting user details
  */
  getAgentDetails() {
    this.agentService.getAgentDetails(this.agentId).subscribe((res: any) => {
      if (res && !res.errors) {
        this.agentDetails = res.data.data[0];
      }
    });
  }
  /**
   * API Call for activating the account
  */
  activateAccount() {
    const payload = {
      is_deactivate: 1,
      userId: this.agentId
    };
    this.agentService.deactiveAccount(payload).subscribe((res: IResponse) => {
      this.toastr.success(res.message);
      this.getAgentDetails();
      this.getCommission();
    });
  }
  /**
   * To view the member information details
  */
  ViewMemberDetails() {
    this.router.navigate(['/agent-management/agent-detail', this.agentDetails?.member_id]);
  }
  /**
     * To navigate list
    */
  movetoList() {
    this.router.navigate(['/agent-management/agent-list']);
  }

  customFilterModal() {
    this.modelref = this.modalService.show(CustomFilterComponent, { class: this.cssClass1 });
  }

  getCommission(value?){
    this.params = `vendor_id=${this.agentId}&start_date=${this.customFilter.value.start_date}&end_date=${this.customFilter.value.end_date}`;
    const parts = ['vendor_id=', this.agentId];
    if (value !== 'total') {
      parts.push('&start_date=', this.customFilter.value.start_date, '&end_date=', this.customFilter.value.end_date);
    }
    this.params = parts.join('');
    this.agentService.customSalesFilter(this.params).subscribe((res: any) => {
      if (res && !res.errors) {
        this.commissionDetails = res.data;
        this.modelref.hide();
      }
    });
  }

  searchStatus(value :string) {
    const selectedValue = value;
    switch (selectedValue) {
      case 'Custom':
        this.filterStatus = 'Custom';
        this.customFilter.patchValue({
          start_date:'',
          end_date: ''
        });
        this.customFilterModal();
        break;
      case 'month':
        this.filterStatus = 'Current Month';
        this.filterOld = 'Current Month';
        this.customFilter.patchValue({
          start_date: new Date().toISOString().slice(MagicNumber.zero,MagicNumber.eight) + '01',
          end_date: new Date().toISOString().slice(MagicNumber.zero,MagicNumber.ten)
        });
        this.getCommission();
        break;
      case 'year':
        this.filterStatus = 'Current Year';
        this.filterOld = 'Current Year';

        this.customFilter.patchValue({
          start_date: this.getCalulateStartYear(),
          end_date: this.getCalulateEndYear()
        });
        this.getCommission();
        break;
      case 'total':
        this.filterStatus = 'Total';
        this.filterOld = 'Total';
        this.getCommission('total');
        break;
        default:
        break;
    }
  }
 

  getCalulateStartYear(){
    const date = new Date();
    date.setMonth(MagicNumber.zero); // set month to December (index 11)
    date.setDate(MagicNumber.one); // set day to 31
    return date.toISOString().substr(MagicNumber.zero, MagicNumber.ten); // extract the first 10 characters (YYYY-MM-DD)
  }
  getCalulateEndYear() {
    const date = new Date();
    date.setMonth(MagicNumber.eleven); // set month to December (index 11)
    date.setDate(MagicNumber.thirtyOne); // set day to 31
    return date.toISOString().substr(MagicNumber.zero, MagicNumber.ten); // extract the first 10 characters (YYYY-MM-DD)
}
}

