import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(public http: HttpClient) { }
  getQrCode(memberId: number) {
    return this.http.post(`${environment.apiUrl}download-qr?id=${memberId}`, '');
  }

  getQRCodeSerialNumber(serialNumber: string) {
    return this.http.post(`${environment.apiUrl}download_qr_memory_code?serial_number=${serialNumber}`, '');
  }

  uploadImage(file, type) {
    return this.http.post(`${environment.apiUrl}common/image-upload?base_path=${type}`, file);
  }
  async downloadCode(qrCode: string) {
    fetch(qrCode)
      .then(response =>
        response.blob()
      )
      .then(blob => {
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.download = qrCode.replace(/^.*[\\\/]/, '');
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  printCode(qrCode: string) {
    const win = window.open('');
    win.document.write('<img src="' + qrCode + '" onload="window.print();window.close()"+"width="100%" height="100%" />');
    win.focus();
  }

  getStates() {
    return this.http.get(`${environment.apiUrl}common/states`);
  }

  getCities(stateId:number){
    return this.http.get(`${environment.apiUrl}common/cities?state_id=${stateId}`);
  }

  getFAQ(params){
    return this.http.get(`${environment.apiAdminUrl}get-faq-list?${params}`);
  }

  addFaq(params) {
    return this.http.post(`${environment.apiAdminUrl}create-faq`, params);
  }

  editFaq(id,params) {
    return this.http.put(`${environment.apiAdminUrl}edit-faq/${id}`, params);
  }

  deleteFaq(payloads:any){
    return this.http.delete(`${environment.apiAdminUrl}delete-faq?${payloads}`);
  }

}
