export const WAREHOUSE_COLS = [
  {
      headerName: 'Memory Code',
      fieldName: 'serial_number',
      // nestedField:null,
      fieldType: 'TEXT',
      sortOrder: true,
      orderby:'serial_number'
  },
  {
    headerName: 'Box Number',
    fieldName: 'box_no',
    // nestedField:null,
    fieldType: 'TEXT',
    sortOrder: false
  },
  {
      headerName: 'Generated On',
      fieldName: 'created_at',
      fieldType: 'DATE',
      sortOrder: true,
      orderby:'generated_on'
  },
  {
    headerName: 'Action',
    fieldName: 'added_by',
    fieldType: 'QR_ICON',
    sortOrder: false,
    orderby:null
  }
];

