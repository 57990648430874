<!-- Add profile Modal -->
<div class="modal-header add-sales-header">
    <button type="button" class="btn-close" (click)="modalRef.hide()" data-bs-dismiss="modal"
        aria-label="Close"></button>
</div>
<div class="modal-body add-sales-padding">
    <h1 class="modal-title">Edit</h1>
    <form [formGroup]="editFaq" (ngSubmit)="submitFaq()" class="modal-form">
        <div class="form-floating"
            [ngClass]="question?.invalid && (question?.dirty || question?.touched)?'invalid-input':''">
            <input type="text" class="form-control" formControlName="question" id="floatingInput"
                placeholder="question">
            <label for="floatingInput">Question</label>
            <ng-container *ngIf="question?.invalid && (question?.dirty || question?.touched)">
                <span class="invalid-input-text-err" *ngIf="question?.errors?.required">Question is
                    required</span>
            </ng-container>
        </div>
        <div class="textarea-message mb-20">
            <div class="form-floating textarea-after"
                [ngClass]="answer?.invalid && (answer?.dirty || answer?.touched)?'invalid-input':''">
                <textarea class="form-control" (focus)="reasonLabel = !reasonLabel" style="height: 126px"
                    maxlength="250" formControlName="answer" id="floatingInput" placeholder="Answer">
                        </textarea>
                <label for="floatingInput">Answer</label>
                <ng-container *ngIf="answer?.invalid && (answer?.dirty || answer?.touched)">
                    <span class="invalid-input-text-err" *ngIf="answer?.errors?.required">Answer is
                        required</span>
                </ng-container>
            </div>
        </div>

        <button type="submit" class="btn primary w-100"
            [disabled]="!(editFaq.valid) || (editFaq.pristine)">Update</button>
    </form>
</div>
