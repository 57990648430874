import { Component } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DeactiveAccountComponent } from '../../../../app/common/modal/deactive-account/deactive-account.component';
import { INFORMATION_MNGMNT_COLS } from './information-management.col.def';

@Component({
  selector: 'app-information-list',
  templateUrl: './information-list.component.html'
})
export class InformationListComponent {
  public colFields = INFORMATION_MNGMNT_COLS;
  public tableData = {
    tableHeading: 'Information Management', tableType: 'information', showInformationData: true,
    serviceName: 'informationService', routerLink: '/information-management/information-detail'
  };
  constructor(public modalService: BsModalService) {

  }



  /**
     * To open the deactive account profile
    */
  deactiveAccount() {
    this.modalService.show(DeactiveAccountComponent, { class: 'max-width-605 modal-dialog-centered' });
  }
}
