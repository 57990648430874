import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../app-interface';
import { AgentManagementService } from '../../services/agent-management.service';

@Component({
  selector: 'app-unassign-qrcode',
  templateUrl: './unassign-qrcode.component.html'
})
export class UnassignQrcodeComponent implements OnInit {

  public showPass = false;
  public data;
  public unassignEvent = new EventEmitter();
  showCountryCode = true;

  constructor(public formBuilder: FormBuilder, public modalRef: BsModalRef, public agentService: AgentManagementService, public toaster: ToastrService) {
  }
  ngOnInit(): void {
  }

  unassignQrCode() {
      this.agentService.unassignQrCod(this.data).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toaster.success(res.message);
          this.unassignEvent.emit({ res: 200 });
          this.modalRef.hide();
        }
      });
  }

}
