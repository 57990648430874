import { Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DeactiveAccountComponent } from '../../../../app/common/modal/deactive-account/deactive-account.component';
import { GuestUserManagementService } from '../../../../app/common/services/guest-user-management.service';
import { GUEST_USER_MNGMNT_COLS } from './guest-user-management.col.def';
@Component({
  selector: 'app-guest-users-list',
  templateUrl: './guest-users-list.component.html'
})
export class GuestUsersListComponent {
  public colFields = GUEST_USER_MNGMNT_COLS;
  public tableData = {
    tableHeading: 'Guest User Management', tableType: 'guest-user', serviceName: 'guestUserService',
    containerClass: 'table-container-guest-user', headClass: 'table-head-user', rowClass: 'table-row-user',
    bodyClass: 'table-body-user', showExportGuestData: true
  };

  constructor(public modalService: BsModalService, public bsModalRef: BsModalRef,
    private readonly _guestUserService: GuestUserManagementService) { }



  deactiveAccount() {
    this.bsModalRef = this.modalService.show(DeactiveAccountComponent);
  }
}
