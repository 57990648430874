<div class="container-fluid">
  <div class="row">
    <div class="col-12 p-0">
      <div class="signin-container">
        <!-- sign in start here -->
        <div class="signin-logo">
          <img src="./assets/images/svg/signin-logo.svg" alt="signin-logo" />
        </div>

        <form class="login-form" action="dashboard.html">
          <div class="signin-section">
            <span class="modal-title" id="exampleModalLabel"
              ><img
                src="./assets/images/svg/header-infinity.svg"
                alt="header-infinity"
                class="modal-header-img"
            /></span>
            <h1 class="signin-title">Reset Password</h1>
            <p class="sub-head">Create a new password</p>
            <div class="login-form-wrapper">
              <form
                class="login-container"
                [formGroup]="resetPasswordForm"
                (ngSubmit)="onReset()"
              >
                <div class="form-floating pw-margin-bottom" [ngClass]="password?.invalid && (password?.dirty || password?.touched)?'error-pw':''">
                  <input
                    [type]="isShow?'text':'password'"
                    class="form-control"
                    id="password"
                    placeholder="Password"
                    formControlName="password"
                    maxlength="50"
        
                  />
                  <label for="password">New Password</label>
                  <span class="hide-eye" (click)="isShow=!isShow">
                    <img
                      src="assets/images/{{isShow?'show':'hide'}}-password.svg"
                      alt="hide-password"
                    />
                  </span>
                </div>
                <ng-container *ngIf="password?.invalid && (password?.dirty || password?.touched)">
                  <span class="invalid-input-text" *ngIf="password?.errors?.required">New Password is
                    required</span>
                  <span class="invalid-input-text" *ngIf="password?.errors?.pattern">New Password must be 8 to 20 characters, atleast one alpha-numeric, one special character, one uppercase and one lowercase.</span>
                </ng-container>
                <div class="form-floating">
                  <input
                    [type]="showConfirm?'text':'password'"
                    class="form-control"
                    id="password"
                    placeholder="Password"
                    formControlName="confirmPassword"
                    maxlength="50"
                  />
                  <label for="password">Confirm New Password</label>
                  <span class="hide-eye" (click)="showConfirm=!showConfirm">
                    <img
                      src="assets/images/{{showConfirm?'show':'hide'}}-password.svg"
                      alt="show-password"
                    />
                  </span>
                </div>
                <ng-container *ngIf="confirmPassword?.invalid && (confirmPassword?.dirty || confirmPassword?.touched)">
						<span class="invalid-input-text" *ngIf="confirmPassword?.errors?.required">Confirm Password is
							required</span>
					</ng-container>
          <span class="invalid-input-text"
						*ngIf="(confirmPassword?.valid || password?.valid) && (confirmPassword?.value != password?.value) && confirmPassword?.value.length!=0">New
						Password and
						Confirm Password must match
					</span>
                <div class="form-action">
                  <button class="btn primary w-100" type="submit">
                    Reset Password
                  </button>
                </div>
                <p class="signup-para text-center">
                  Back to <a routerLink="/signin" class="signup-link">Sign In</a>
                </p>
              </form>
              <span class="signin-footer">
                <img
                  src="./assets/images/svg/footer-heart.svg"
                  alt="footer-heart"
                  class="modal-footer-img"
                />
              </span>
            </div>
          </div>
        </form>
      </div>
      <!-- end signin container wrapper -->
    </div>
  </div>
</div>
