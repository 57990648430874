import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../common/app-interface';
import { MagicNumber } from '../../common/app-magic-string';
import { AuthService } from '../../../app/common/services/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent {
  public changePasswordForm: FormGroup;
  public oldShow = false;
  public newShow = false;
  public currentShow = false;
  constructor(public formBuilder: FormBuilder, public modalRef: BsModalRef, public authService: AuthService,
    public toastr: ToastrService, public router: Router) {
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      cnfrmPassword: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
    });
  }




  /**
   * form param return
  */

  get oldPassword() { return this.changePasswordForm.get('oldPassword'); }
  get newPassword() { return this.changePasswordForm.get('newPassword'); }
  get cnfrmPassword() { return this.changePasswordForm.get('cnfrmPassword'); }

  /**
   * On Change Password form submit
  */
  onChangePassword() {
    if (this.changePasswordForm.valid) {
      if (this.changePasswordForm.value.oldPassword !== this.changePasswordForm.value.newPassword) {
        const params = {
          oldPassword: this.changePasswordForm.value.oldPassword,
          newPassword: this.changePasswordForm.value.newPassword,
          cnfrmPassword: this.changePasswordForm.value.cnfrmPassword
        };
        this.authService.changePasswordAdmin(params).subscribe((res: IResponse) => {
          if (res && !res.errors) {
            this.modalRef.hide();
            this.toastr.success(res.message);
            localStorage.clear();
            this.router.navigate(['/signin']);
          } else {
            this.toastr.error(res.message);
          }
        });
      } else {
        this.toastr.error('Old password and new password cannot be same');
      }
    } else {
      this.changePasswordForm.markAllAsTouched();
    }
  }
}
