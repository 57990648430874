export const INFORMATION_MNGMNT_COLS = [
    {
        headerName: 'Member Name',
        fieldName: 'name',
        fieldType: 'TEXT',
        sortOrder: true
    },
    {
        headerName: 'Birth Date',
        fieldName: 'dob',
        fieldType: 'DATE',
        sortOrder: true
    },
    {
        headerName: 'Day of Passing',
        fieldName: 'dod',
        fieldType: 'DATE',
        sortOrder: true
    },
    {
        headerName: 'Hometown',
        fieldName: 'cemetery_name',
        fieldType: 'LOCATION',
        sortOrder: true
    },
    {
        headerName: 'Cemetry',
        fieldName: 'burial_cemetery_name',
        fieldType: 'LOCATION',
    },
    {
        headerName: 'Added By',
        fieldName: 'added_by',
        fieldType: 'TEXT',
        sortOrder: true
    },
    {
        headerName: 'Added On',
        fieldName: 'created_at',
        fieldType: 'DATE',
        sortOrder: true
    },
    {
        headerName: 'Status',
        fieldName: 'status',
        fieldType: 'STATUS',
        sortOrder: true
    },
    {
        headerName: '',
        fieldName: '',
        fieldType: 'action',
    },

];


