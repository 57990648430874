<div class="modal-dialog modal-dialog-centered max-width-607">
    <div class="modal-content p-0">
        <div class="modal-header">
            <button type="button" class="btn-close edit-about-close" (click)="modalRef.hide()" data-bs-dismiss="modal"
                aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <h1 class="modal-title margin-bottom-21">QR Code</h1>
            <form action="" class="modal-form edit-about-form">
                <div class="about-section qr-code-section padding-remove">
                    <div class="qr-code-wrapper w-100 mx-auto padding-40">
                        <span class="qr-code-img margin-copy-img">
                            <img [src]="qrCodeData?.url" alt="qr-code-image" class="qr-scan-img" />
                        </span>
                        <p class="memory-code-text  copy-code-style">{{data?.qrInfo?.serial_number}} <span
                                class="copy-img cursor-pointer"
                                (click)="copyMemoryCode(data?.qrInfo?.serial_number)"><img
                                    src="assets/images/icons-copy.svg" alt="copy-logo" />
                            </span></p>
                        <div class="qr-code-btn">

                        </div>
                        <div *ngIf="qrCodeData?.member_id" class="preview-btn-col">
                            <a class="text-preview" href="{{memorialUrl + qrCodeData?.member_id}}"
                                target="blank">{{memorialUrl + qrCodeData?.member_id}}</a>
                        </div>
                    </div>
                </div>
                <div class="edit-about-btn qr-code-btn-padding">
                    <button type="button" class="btn btn-outline-primary margin-right-25"
                        (click)="utilityService.printCode(qrCodeData?.url)">Print Code</button>
                    <button type="button" class="btn primary"
                        (click)="utilityService.downloadCode(qrCodeData?.url)">Download Code</button>
                </div>
            </form>
        </div>

    </div>
</div>
