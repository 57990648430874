import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../app-interface';
import { MagicNumber } from '../../app-magic-string';
import { AgentManagementService } from '../../services/agent-management.service';

@Component({
  selector: 'app-agent-edit-profile',
  templateUrl: './agent-edit-profile.component.html'
})
export class AgentEditProfileComponent implements OnInit {
  public editProfile: FormGroup;
  public showPass = false;
  public data;
  public editEvent = new EventEmitter();
  showCountryCode = true;
  constructor(public formBuilder: FormBuilder, public modalRef: BsModalRef, public agentService: AgentManagementService, public toaster: ToastrService) {
    this.editProfile = this.formBuilder.group({
      fullName: ['', [Validators.required, Validators.pattern(/^(?!^\d+$)[a-zA-Z0-9\s]*[a-zA-Z][a-zA-Z0-9\s]*$/)]],
      emailId: ['', [Validators.required, Validators.pattern(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      // password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}'),
      //   Validators.maxLength(MagicNumber.fifteen)]],
        mobile: ['', [Validators.required, Validators.minLength(MagicNumber.ten), Validators.maxLength(MagicNumber.ten),Validators.pattern(/^[0-9]+$/)]],
      address: ['', [Validators.required,Validators.maxLength(MagicNumber.hundred)]],
      // commission: ['', [Validators.required, Validators.pattern(/^([1-9]\d|\d|100)$|^0\d$|^00\d$/)]],
      commission: ['', [Validators.required, Validators.pattern(/^(0*(\d{1,2}(\.\d+)?)|\.\d+|100(\.0+$)?)$/)]]
    });
  }

  ngOnInit(): void {
    this.editProfile.patchValue({
      fullName: this.data && this.data.name,
      emailId: this.data && this.data.email,
      // password: this.data && this.data.password,
      mobile: this.data && this.data.mobile,
      address:this.data && this.data.address,
      commission: this.data && this.data.commission
    });
  }
  get fullName() { return this.editProfile.get('fullName'); }
  get emailId() { return this.editProfile.get('emailId'); }
  // get password() { return this.editProfile.get('password');}
  get mobile() { return this.editProfile.get('mobile'); }
  get address() { return this.editProfile.get('address'); }
  get commission() { return this.editProfile.get('commission'); }

  /**
   * API Call for submitting the user profile
  */
  submitProfile() {
    if (this.editProfile.valid) {
      const params = '&full_name=' + this.editProfile.value.fullName + '&email=' +
      this.editProfile.value.emailId + '&mobile=' + this.editProfile.value.mobile + '&address=' + this.editProfile.value.address + 
      '&commission=' + this.editProfile.value.commission;
      this.agentService.updateAgentDetailsById(params,this.data.id).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toaster.success(res.message);
          this.editEvent.emit({ res: 200 });
          this.modalRef.hide();
        }
      });
    } else {
      this.editProfile.markAllAsTouched();
    }
  }
}
