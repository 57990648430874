<!-- Generate QR code Manually -->
<div class="modal-dialog modal-dialog-centered max-width-607">
    <div class="modal-content p-0">
        <div class="modal-header">
            <button type="button" class="btn-close" (click)="onClosed()" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body pb-8">
            <h1 class="modal-title margin-bottom-12">Generate QR Memory Code</h1>
            <p class="qr-modal-subtitle">Choose how many QR Memory codes you want to generate</p>
            <form [formGroup]="generateQrCodeForm" (ngSubmit)="generateQrCode()" class="modal-form edit-about-form">

                <div class="about-section qr-code-section padding-remove padding-35">
                    <div class="w-100 padding-40 radio-btn-wrapper">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" (click)="onTypeChange($event)" id="quickGenerate" value="quick" formControlName="qrCodeType">
                            <label class="form-check-label" for="quickGenerate">
                                Quick Generate
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="manualGenerate" (click)="onTypeChange($event)" value="manual" formControlName="qrCodeType">
                            <label class="form-check-label" for="manualGenerate">
                                Manually Choose
                            </label>
                        </div>
                    </div>

                    <div class="form-floating" *ngIf="qrCodeType?.value=='manual'" [ngClass]="qrCodeNumber?.invalid && (qrCodeNumber?.dirty || qrCodeNumber?.touched)?'invalid-input':''">
                        <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com" formControlName="qrCodeNumber" maxlength="3" onkeydown="if(event.key==='.'){event.preventDefault();}" appNumbersonly>
                        <label for="floatingInput">Enter Qty.</label>
                        <ng-container *ngIf="qrCodeNumber?.invalid && (qrCodeNumber?.dirty || qrCodeNumber?.touched)">
                            <span class="invalid-input-text-err" *ngIf="qrCodeNumber?.errors?.required">Quantity is required</span>
    
                            <span class="invalid-input-text-err" *ngIf="qrCodeNumber?.errors?.pattern">allow upto 3 digit numbers
                    </span>
                        </ng-container>
                    </div>

                    <div class="qr-code-number" *ngIf="qrCodeType?.value=='quick'">
                        <div class="form-floating" *ngFor="let code of QRCodes; let i = index">
                            <div for="floatingInput" class="form-control number-input-label" (click)="setIndex(i,code)" [class.active]="selectedIndex === i">{{code}}</div>
                        </div>
                    </div>
                </div>
                      <div *ngIf="!rememberAssign" class="select-custom-view">
                          <ng-select placeholder="{{selectedOption ? '' : 'Select Agent'}}" class="select-custom mb-3" [(ngModel)]="selectedOption" [ngModelOptions]="{standalone: true}">
                            <ng-option id="select-items" *ngFor="let option of agentDetails" [value]="option.id" (click)="selectOption(option)">{{option?.name | titlecase}}</ng-option>
                         </ng-select>
                      </div>
                <div class="qr-code-check-sec">
                    <div class="qr-code-check">
                        <div class="form-check form-check-qr">
                            <input (change)="updateCheckedOptions($event)" class="form-check-input" type="checkbox" id="flexCheckDefault">
                            <label class="form-check-label" for="flexCheckDefault">
                            </label>
                        </div>
                    </div>
                    <div class="qr-code-check-text">Assign to EternallyMe Warehouse</div>
                </div>

                <div class="edit-about-btn qr-code-btn-padding">
                    <button type="submit" class="btn primary w-100">Generate QR Memory Code</button>
                </div>
            </form>
        </div>
    </div>
</div>