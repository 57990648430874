import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../app-interface';
import { DashboardAgentService } from '../../services/dashboard-agent.service';

@Component({
  selector: 'app-mark-as-sold',
  templateUrl: './mark-as-sold.component.html'
})
export class MarkAsSoldComponent implements OnInit {
  public showPass = false;
  public data;
  public soldEvent = new EventEmitter();
  showCountryCode = true;
  constructor(public formBuilder: FormBuilder, public modalRef: BsModalRef, public dashboardService: DashboardAgentService, public toaster: ToastrService) {
  }

  ngOnInit(): void {
  }
  /**
   * API Call for submitting the user profile
  */
  markAsSold() {
      this.dashboardService.markAsSold(this.data.id).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toaster.success(res.message);
          this.soldEvent.emit({ res: 200 });
          this.modalRef.hide();
        }
      });
  }
}
