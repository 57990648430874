export const AGENT_MNGMNT_COLS = [
    {
        headerName: 'Name',
        fieldName: 'name',
        fieldType: 'TEXT',
        sortOrder: true
    },
    {
        headerName: 'Email ID',
        fieldName: 'email',
        fieldType: 'TEXT',
        sortOrder: false
    },
    {
        headerName: 'Phone Number',
        fieldName: 'mobile',
        fieldType: 'TEXT',
        sortOrder: false
    },
    {
        headerName: 'Commission',
        fieldName: 'commission',
        fieldType: 'COMM',
        sortOrder: true
    },
    {
        headerName: 'Total QR Memory Codes',
        fieldName: 'vendor_total_qrcodes',
        fieldType: 'TEXTRED',
        sortOrder: false
    },
    {
        headerName: 'QR Memory Codes Issued',
        fieldName: 'vendor_sold_qrcodes',
        fieldType: 'TEXTRED',
        sortOrder: false
    },
    {
        headerName: 'Active Profiles',
        fieldName: 'active_profiles',
        fieldType: 'TEXT',
        sortOrder: true
    },
    {
        headerName: 'Status',
        fieldName: 'status',
        fieldType: 'STATUS',
        sortOrder: false
    },
    {
        headerName: 'Action',
        fieldName: '',
        fieldType: 'action',
    },

];
