export const VENDOR_DETAILS_COLS = [
    {
      headerName: 'Amount',
      fieldName: 'amount',
      fieldType: 'DOLLAR',
      fieldAlterName: 'DOLLAR',
      sortOrder: true
    },
    {
      headerName: 'Date',
      fieldName: 'created_at',
      fieldType: 'DATE',
      fieldAlterName: null,
      sortOrder: false
    },
    {
      headerName: 'Time',
      fieldName: 'created_at',
      fieldType: 'TIME',
      fieldAlterName: null,
      sortOrder: false
    },
    {
        headerName: 'Action',
        fieldName: 'VendorDetails',
        fieldType: 'action',
    },
  ];
  
  
  
  