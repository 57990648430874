export const PAYMENT_MNGMNT_COLS = [
  {
    headerName: 'Name',
    fieldName: 'name',
    fieldType: 'PAYMENT_TEXT',
    fieldAlterName: 'full_name',
    sortOrder: true
  },
  {
    headerName: 'Email ID',
    fieldName: 'email',
    fieldType: 'PAYMENT_TEXT',
    fieldAlterName: 'basic_plan_email',
    sortOrder: true
  },
  {
    headerName: 'Transaction ID',
    fieldName: 'transaction_key',
    fieldType: 'PAYMENT_TEXT',
    fieldAlterName: null,
    sortOrder: false
  },
  {
    headerName: 'QR Memory Code',
    fieldName: 'serial_number',
    fieldType: 'TEXT',
    fieldAlterName: null,
    sortOrder: false
  },
  {
    headerName: 'Paid On',
    fieldName: 'created_at',
    fieldType: 'DATE',
    fieldAlterName: null,
    sortOrder: false
  },
  {
    headerName: 'Amount',
    fieldName: 'amount',
    fieldType: 'DOLLAR',
    fieldAlterName: 'DOLLAR',
    sortOrder: false
  },
  {
    headerName: 'Status',
    fieldName: 'status',
    fieldType: 'STATUS_PAYMENT',
    fieldAlterName: null,
    sortOrder: true
  },
];
