<div class="member-details-section">
    <ul>
        <li>
            <a href="javascript:;" [ngClass]="tabName == 'aboutUs' ? 'active' : ''" (click)="tabName = 'aboutUs'">
                About</a>
        </li>
        <li>
            <a href="javascript:;" [ngClass]="tabName == 'hobbies' ? 'active' : ''" (click)="tabName = 'hobbies'">
                Hobbies</a>
        </li>
        <li>
            <a href="javascript:;" [ngClass]="tabName == 'career' ? 'active' : ''" (click)="tabName = 'career'">
                Career & Accomplishments</a>
        </li>
        <li>
            <a href="javascript:;" [ngClass]="tabName == 'family' ? 'active' : ''" (click)="tabName = 'family'">
                Family</a>
        </li>
        <li>
            <a href="javascript:;" [ngClass]="tabName == 'favorites' ? 'active' : ''" (click)="tabName = 'favorites'">
                Favorite Memories</a>
        </li>
        <li>
            <a href="javascript:;" [ngClass]="tabName == 'gallery' ? 'active' : ''" (click)="tabName = 'gallery'">
                Gallery</a>
        </li>
        <li>
            <a href="javascript:;" [ngClass]="tabName == 'qrCode' ? 'active' : ''"
                (click)="tabName = 'qrCode'; getQrCode()">
                QR Code/ Memory Code Details</a>
        </li>
    </ul>
</div>
<!-- about section start here -->
<div class="about-section" *ngIf="tabName == 'aboutUs'">
    <p [innerHTML]="informationData?.about_memorial"></p>
    <div class="info-edit-btn" *ngIf="isEdit">
        <button class="btn btn-outline-secondary" (click)="
        editInformation(
          informationData?.about_memorial,
          'About',
          'about_memorial'
        )
      ">
            <img src="./assets/images/svg/info-edit-logo.svg " alt="info-edit-logo " />
            Edit
        </button>
    </div>
</div>
<!-- hobbies section start here -->

<div class="about-section hobbies-section" *ngIf="tabName == 'hobbies'">
    <p [innerHTML]="informationData?.hobbies"></p>

    <div class="info-edit-btn" *ngIf="isEdit">
        <button class="btn btn-outline-secondary"
            (click)="editInformation(informationData?.hobbies, 'Hobbies', 'hobbies')">
            <img src="./assets/images/svg/info-edit-logo.svg " alt="info-edit-logo " />
            Edit
        </button>
    </div>
</div>

<!-- career section start here -->
<div class="about-section career-section" *ngIf="tabName == 'career'">
    <p [innerHTML]="informationData?.career_accomplishments"></p>

    <div class="info-edit-btn" *ngIf="isEdit">
        <button class="btn btn-outline-secondary" (click)="
        editInformation(
          informationData?.career_accomplishments,
          'Career & Accomplishments',
          'career_accomplishments'
        )
      ">
            <img src="./assets/images/svg/info-edit-logo.svg " alt="info-edit-logo " />
            Edit
        </button>
    </div>
</div>

<!-- family section start here -->
<div class="about-section family-section" *ngIf="tabName == 'family'">
    <p [innerHTML]="informationData?.about_family"></p>

    <div class="info-edit-btn" *ngIf="isEdit">
        <button class="btn btn-outline-secondary" (click)="
        editInformation(informationData?.about_family, 'Family', 'about_family')
      ">
            <img src="./assets/images/svg/info-edit-logo.svg " alt="info-edit-logo " />
            Edit
        </button>
    </div>
</div>
<!-- Favorite section start here -->
<div class="about-section favorite-section" *ngIf="tabName == 'favorites'">
    <p [innerHTML]="informationData?.favorite_memories"></p>

    <div class="info-edit-btn" *ngIf="isEdit">
        <button class="btn btn-outline-secondary" (click)="
        editInformation(
          informationData?.favorite_memories,
          'Favorite Memories',
          'favorite_memories'
        )
      ">
            <img src="./assets/images/svg/info-edit-logo.svg " alt="info-edit-logo " />
            Edit
        </button>
    </div>
</div>

<!-- Gallery section start here -->
<div class="about-section gallery-section" *ngIf="tabName == 'gallery'">
    <div class="gallery-wrapper">
        <ul>
            <li *ngFor="let picture of informationData?.member_gallery;let i=index">
                <span *ngIf="picture?.type===0">
                    <img [src]="picture?.path" alt="gallery-img1 " class="gallery-img"
                        (click)="staticModal.show();previewImage=picture?.path" />
                    <button type="button" class="btn btn-link delete-img-btn" *ngIf="picture?.path!='' && isEdit"><img
                            src="./assets/images/svg/delete-img-icon.svg" (click)="deleteImage(i,picture?.id)"
                            alt="delete-img"></button>
                </span>
                <span *ngIf="picture?.type===1">
                    <video *ngIf="picture?.path!=''" (click)="showVideo(picture?.path)" alt="view-all-img-2"
                        class="gallery-img">
                        <source [src]="picture?.path" type="video/mp4" />
                    </video>

                    <div class="video-icon"><img src="assets/images/svg/video-img.svg" alt="Eternally me video image"
                            (click)="showVideo(picture?.path)" /></div>

                    <button type="button" class="btn btn-link delete-img-btn" *ngIf="picture?.path!='' && isEdit"><img
                            src="./assets/images/svg/delete-img-icon.svg" (click)="deleteImage(i,picture?.id)"
                            alt="delete-img"></button>
                </span>
            </li>

        </ul>
    </div>
</div>

<!-- QR code section start here -->
<div class="about-section qr-code-section" *ngIf="tabName == 'qrCode'">
    <p class="qr-code-para">Shipping Details: <span class="qr-code-text"><img src="./assets/images/svg/phone-logo.svg"
                alt="contact">{{transformPhoneNumber(informationData?.shipping_address?.contact_number)}}</span> <span
            class="location-img qr-code-text"><img src="./assets/images/svg/location-logo-code.svg" alt="address">{{
            informationData?.shipping_address?.address }}, {{
            informationData?.shipping_address?.user_stripe_details_city_name }}, {{
            informationData?.shipping_address?.user_stripe_details_state_name }}, US</span>
    </p>
    <div class="qr-code-wrapper mx-auto">
        <span class="qr-code-text">QR Code Assigned</span>
        <span class="qr-code-img margin-copy-img"><img [src]="qrcodeImage" alt="qr-code-img "
                class="qr-scan-img" /></span>
        <p class="memory-code-text copy-code-style">
            {{ informationData?.member_subscription?.serial_number }} <span class="copy-img cursor-pointer"
                (click)="copyMemoryCode(informationData?.member_subscription?.serial_number)"><img
                    src="assets/images/icons-copy.svg" alt="copy-logo" />
            </span>
        </p>
        <div class="qr-code-btn">
            <button type="button" (click)="utilityService.printCode(qrcodeImage)" class="btn btn-link">
                <img src="./assets/images/svg/print-logo.svg " alt="print-logo" /> Print
                Code
            </button>
            <button type="button" (click)="utilityService.downloadCode(qrcodeImage)" class="btn btn-link">
                <img src="./assets/images/svg/download-logo.svg " alt="download-logo " />
                Download Code
            </button>
        </div>
    </div>
</div>

<!-- Image Preview start here -->

<div class="modal image-preview-modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}"
    tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-sm image-preview-modal-dialog">
        <div class="modal-content image-preview-modal-content">
            <div class="modal-body">
                <div class="image-preview-wrapper">
                    <img [src]="previewImage" alt="image-preview" class="image-preview img-fluid" />
                    <button type="button" class="btn btn-link image-preview-close-btn" (click)="staticModal.hide()"><img
                            src="assets/images/svg/image-preview-close.svg" alt="close-btn"
                            class="img-fluid" /></button>
                </div>
            </div>

        </div>
    </div>
</div>
