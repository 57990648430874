import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AdManagementService } from '../../common/services/ad-management.service';
import { FormGroup } from '@angular/forms';
import { IResponse } from '../../common/app-interface';
import { DeleteFaqComponent } from '../../common/modal/delete-faq/delete-faq.component';
import { UtilityService } from '../../common/services/utility.service';
import { AddFaqComponent } from '../../common/modal/add-faq/add-faq.component';
import { EditFaqComponent } from '../../common/modal/edit-faq/edit-faq.component';
import { AccordionConfig } from 'ngx-bootstrap/accordion';
import { MagicNumber } from '../../common/app-magic-string';


export function getAccordionConfig(): AccordionConfig {
  return Object.assign(new AccordionConfig(), { closeOthers: true });
}

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss'],
  providers: [{ provide: AccordionConfig, useFactory: getAccordionConfig }]

})
export class FaqsComponent implements OnInit {
  public ad1Form: FormGroup;
  public ad2Form: FormGroup;
  defaultOpen = true;
  @Output() deleteFaqPoint = new EventEmitter();


  // public colFields = AD_MNGMNT;
  public tableData = {
    tableHeading: 'Ad Management', tableType: 'Advertisement',
    serviceName: 'adService', routerLink: '/ad-managment',
    containerClass: 'table-container-ad-management', headClass: 'table-head-user', rowClass: 'table-row-user',
   showExport: true, hideFilter: true
  };
  adDetails: any;
  imageUploading = false;
  showAd1 = false;
  showAd2 = false;
  num1 = MagicNumber.twoThousand;
  num2 = MagicNumber.oneZeroTwoFour;
  num3 = MagicNumber.five;
  faqData;





  // ad1Detail: adParam;

  constructor(public modalService: BsModalService, public adService: AdManagementService,
     public utilityService: UtilityService,
    public toastr: ToastrService, public modelref: BsModalRef) {
     }


  ngOnInit(): void {
    this.getFaqContent();
  }


  submitAd1() {
    this.ad1Form.markAllAsTouched();
    if (this.imageUploading) {
      this.toastr.warning('Warn!', 'Please wait...');
      return;
    }

    if (this.ad1Form.invalid) {
      this.toastr.error('All Fields are Mandatory. Please Fill all details.');
      return;
    }
    const formValue = this.ad1Form.getRawValue();

    const editAd1 = {

      ad_space_id: formValue.id,
      name: formValue.ad1Name,
      placement: formValue.ad1Placement,
      url: formValue.ad1Url,
      image: formValue.ad1Image
    };

    const params = 'ad_space_id=' + formValue.ad1Id + '&name=' + formValue.ad1Name + '&placement=' +
    formValue.ad1Placement + '&url=' + formValue.ad1Url + '&image=' +
    formValue.ad1Image;

      this.adService.editAdSpace(params).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toastr.success(res.message);
          this.ngOnInit();
        }
      });
    }


      getFaqContent() {
        const params = `page=1&limit=100`;
       this.utilityService.getFAQ(params).subscribe((res: IResponse) => {
         if (res && !res.errors) {
           this.faqData = res.data;
         }
       });
     }

     addFaq() {
      this.modelref = this.modalService.show(AddFaqComponent, { class: 'max-width-605 modal-dialog-centered edit-faq-modal' });
      this.modelref.content.addEvent.subscribe((res) => {
        if (res) {
        this.getFaqContent();
        }
      });
    }

    editFaq(data) {
      const initialState = { data: { id: data.id, question:data.question, answer:data.answer} };
      this.modelref = this.modalService.show(EditFaqComponent, { class: 'max-width-605 modal-dialog-centered edit-faq-modal', initialState });
      this.modelref.content.editEvent.subscribe((res) => {
        if (res) {
          this.getFaqContent();
        }
      });
  }

      deleteFaq(data) {
        const initialState = { data: { id: data.id } };
        this.modelref = this.modalService.show(DeleteFaqComponent, { class: 'max-width-526 modal-dialog-centered delete-faq-modal', initialState });
        this.modelref.content.deleteFaqPoint.subscribe((res) => {
          if (res.type) {
            this.deleteFaqPoint.emit(true);
            this.getFaqContent();
          }
        });
    }

}
