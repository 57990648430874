import { Component } from '@angular/core';

@Component({
  selector: 'app-information-management',
  templateUrl: './information-management.component.html'
})
export class InformationManagementComponent {



}
