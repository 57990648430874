<div class="modal-dialog modal-dialog-centered max-width-607">
  <div class="modal-content p-0">
     <div class="modal-header">
        <button type="button" class="btn-close edit-about-close" (click)="modalRef.hide()" data-bs-dismiss="modal"
           aria-label="Close"></button>
     </div>
     <div class="modal-body qr-code-body">
        <h1 class="modal-title">{{data?.qrInfo?.user_name ? data?.qrInfo?.user_name : 'NA'}}</h1>
        <p class="text">{{data?.qrInfo?.user_stripe_details_email ? data?.qrInfo?.user_stripe_details_email : 'NA'}}</p>
        <p class="text phone-number">{{data?.qrInfo?.contact_number ? data?.qrInfo?.contact_number : 'NA'}}</p>
        <div class="address-with-btn">
           <div class="address">
              <img src="./assets/images/svg/map-pin.svg" alt="pin" />
              {{ 
              data?.qrInfo?.user_stripe_details_address ? 
              data?.qrInfo?.user_stripe_details_address + 
              (data?.qrInfo?.user_stripe_details_state_name ? ', ' + data?.qrInfo?.user_stripe_details_state_name : '')+
              (data?.qrInfo?.user_stripe_details_city_name ? ', ' + data?.qrInfo?.user_stripe_details_city_name : '') + 
              (data?.qrInfo?.user_stripe_details_zip_code ? ', ' + data?.qrInfo?.user_stripe_details_zip_code : '') 
              : 'NA'
              }}
           </div>
           <button type="button" class="btn btn-link" (click)="openModal()" *ngIf="data.qrInfo.contact_id">
           <img src="./assets/images/svg/info-edit-logo.svg" alt="edit-logo" />
           Edit
           </button>
        </div>
        <form action="" class="modal-form qr-code-container">
           <div class="code-image">
              <img [src]="qrCodeData?.url" alt="qr-code-image" class="qr-scan-img" />
           </div>
           <p class="memory-code-text margin-remove copy-code-style">Memory Code: {{data?.qrInfo?.serial_number}}</p>
        </form>
        <div class="edit-address-container" [ngClass]="{ 'active': isEditAddressContainerActive }">
           <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
           <div class="title">Edit Address</div>
           <form [formGroup]="editAddressForm" class="edit-address-form" (ngSubmit)="onSubmit()">
           <div class="form-floating">
              <input type="text" class="form-control" formControlName="deliveryAddress">
              <label for="floatingInput">Address</label>
           </div>
           <div *ngIf="editAddressForm.get('deliveryAddress').invalid && (editAddressForm.get('deliveryAddress').dirty || editAddressForm.get('deliveryAddress').touched)" class="error-message">
              <small class="text-danger">Address is required</small>
           </div>
           <div class="form-floating">
              <input type="text" class="form-control" id="zipCode" formControlName="zipCode" maxlength="10" (keypress)="handleZipCodeInput($event)">
              <label for="zipCode">Zip Code</label>
           </div>
           <div *ngIf="editAddressForm.get('zipCode').invalid && (editAddressForm.get('zipCode').dirty || editAddressForm.get('zipCode').touched)" class="error-message">
              <p><small class="text-danger" *ngIf="editAddressForm.get('zipCode').errors.required">Zip Code is required</small></p>
              <p><small class="text-danger" *ngIf="editAddressForm.get('zipCode').errors.pattern">Zip Code must be numeric</small></p>
              <p><small class="text-danger" *ngIf="editAddressForm.get('zipCode').errors.minlength">Zip Code must be at least 5 characters</small></p>
              <p><small class="text-danger" *ngIf="editAddressForm.get('zipCode').errors.maxlength">Zip Code must be at most 10 characters</small></p>
           </div>
           <div class="form-floating state-input right-margin-0">
              <select class="form-select plan-form-select" formControlName="selectedState" (change)="onStateChange($event)">
                 <option value="" selected>Select State</option>
                 <option *ngFor="let state of states" [value]="state.id">{{ state.name }}</option>
              </select>
              <label for="stateDropdown">State</label>
           </div>
           <div *ngIf="editAddressForm.get('selectedState').invalid && (editAddressForm.get('selectedState').dirty || editAddressForm.get('selectedState').touched)" class="error-message">
              <small class="text-danger">State is required</small>
           </div>
           <div class="form-floating state-input right-margin-0">
              <select class="form-select plan-form-select" formControlName="selectedCity">
                 <option value="" selected>Select City</option>
                 <option *ngFor="let city of cities" [value]="city.id">{{ city.name }}</option>
              </select>
              <label for="cityDropdown">City</label>
           </div>
           <div *ngIf="editAddressForm.get('selectedCity').invalid && (editAddressForm.get('selectedCity').touched)" class="error-message">
              <small class="text-danger">City is required</small>
           </div>
           <div class="btn-container">
              <button type="submit" class="btn primary" [disabled]="editAddressForm.invalid || !formChanges">Save</button>
           </div>
           </form>
        </div>
     </div>
  </div>
</div>