import { Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GenerateQrcodeComponent } from '../../../app/common/modal/generate-qrcode/generate-qrcode.component';
import { WAREHOUSE_COLS } from './warehouse-management.component.col.def';
import { WarehouseManagementService } from '../../../app/common/services/warehouse-management.service';

@Component({
  selector: 'app-warehouse-management',
  templateUrl: './warehouse-management.component.html',
  styleUrls: ['./warehouse-management.component.scss']
})
export class WarehouseManagementComponent {
  public colFields =  WAREHOUSE_COLS;
  public tableData = {
    tableHeading: 'Warehouse', tableType: 'warehouse', serviceName: 'warehouseService',
    containerClass: 'table-container-warehouse', headClass: 'table-head-warehouse', showWarehouseQR: true,
    rowClass: 'table-row-warehouse', bodyClass: 'table-body-warehouse', hideDownloadCsv: true, showExportWarehouseData: true
  };
  paramsAg: string;
  constructor(public modalService: BsModalService, public bsModalRef: BsModalRef, public _warehouseService: WarehouseManagementService) { }



  generateQrCode() {
    this.modalService.show(GenerateQrcodeComponent, { class: 'max-width-607 modal-dialog-centered' });
  }

  /**
   *QR Code Call back
   @param event is action of an event
  */
  QRCallback(event: Array<number>) {
    if (event) {
      this.bsModalRef = this.modalService.show(GenerateQrcodeComponent,
        {
          animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false,
          class: 'max-width-607 modal-dialog-centered'
        });
      this.bsModalRef.content.onClose.subscribe((result) => {
        if (result.close) {
          if(result.generateQrCodeForm.agentID){
            this.paramsAg = 'number=' + result.generateQrCodeForm.qrCodeNumber + '&vendor_id=' + result.generateQrCodeForm.agentID;
           } else{
             this.paramsAg = 'number=' + result.generateQrCodeForm.qrCodeNumber;
           }
          this._warehouseService.generateQRCode(this.paramsAg).subscribe();
        }
      });
    };
  }
}
