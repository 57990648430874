import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../app-interface';
import { MagicNumber } from '../../app-magic-string';
import { AgentManagementService } from '../../services/agent-management.service';
import { QrcodeManagementService } from '../../services/qrcode-management.service';

@Component({
  selector: 'app-assign-qr-agent',
  templateUrl: './assign-qr-agent.component.html',
  styleUrls: ['./assign-qr-agent.component.scss']
})
export class AssignQrAgentComponent implements OnInit {

  public data;
  public manageEvent = new EventEmitter();
  public manageQrCodeForm: FormGroup;
  public QRCodes = [MagicNumber.ten, MagicNumber.fifty, MagicNumber.hundred, MagicNumber.twoHundred, MagicNumber.fiveHundred];
  public selectedIndex: number = MagicNumber.zero;
  @Output() onClose = new EventEmitter();
  agentDetails;
  dropdownOpen = false;
  selectedOption: any;
  selectedOptionId: any;
  rememberAssign = false;
  remAssign = false;

  constructor(public formBuilder: FormBuilder, public modalRef: BsModalRef , public modalService: BsModalService, 
    public qrcodeService : QrcodeManagementService, public toaster: ToastrService,  public agentService: AgentManagementService) {
    this.manageQrCodeForm = this.formBuilder.group({
      selectedOption: ['']
    });
  }


  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  selectOption(option: string) {
    this.selectedOption = option['name'];
    this.selectedOptionId = option['id'];
    this.dropdownOpen = false;
  }

  ngOnInit(): void {
    this.getAgent();
  }

  getAgent() {
    this.qrcodeService.getAgentList().subscribe((res) => {
      this.agentDetails = res['data'];
    });
  }





  /**
   * form param return
  */

  get qrCodeType() { return this.manageQrCodeForm.get('qrCodeType'); }

  get qrCodeNumber() { return this.manageQrCodeForm.get('qrCodeNumber'); }



  /**
   * On generate QR code form submit
  */
  manageQrCode() {
    if (this.manageQrCodeForm.valid) {
      const data = {
        close: true,
        manageQrCodeForm: this.manageQrCodeForm.value
      };
      this.onClose.next(data);
      this.onClosed();
    } else {
      this.manageQrCodeForm.markAllAsTouched();
    }
  }

    assignQrCode() {
      if(!this.selectedOption){
        this.toaster.error('Please select agent');
        return;
      }
      this.agentService.assignQrCodeToAgent(this.data,this.selectedOption).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toaster.success(res.message);
          this.manageEvent.emit({ res: 200 });
          this.modalRef.hide();
        }
      });
  }

  onClosed() {
    this.modalService.hide();
  }


  /**
   * Activate Selected QR Code
   * @param index is an selected index
   * @param value is selected Item
  */
  setIndex(index: number, value: number) {
    this.selectedIndex = index;
    this.manageQrCodeForm.controls.qrCodeNumber.setValue(value);
  }

  /**
   * Change event type
   * @param event is an action
  */

}

