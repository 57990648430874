<!-- Edit profile Modal -->
<div class="modal-dialog modal-dialog-centered max-width-605">
    <div class="modal-content">
        <div class="modal-header">
            <div class="modal-header-wrapper"><img src="./assets/images/svg/header-infinity-white.svg"
                    alt="header-infinity.svg" class="modal-header-img" /></div>
            <button type="button" class="btn-close" (click)="modalRef.hide()" data-bs-dismiss="modal"
                aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <h1 class="modal-title">Edit Details</h1>
            <form [formGroup]="editProfile" (ngSubmit)="submitProfile()" class="modal-form">
                <div class="form-floating"
                    [ngClass]="fullName?.invalid && (fullName?.dirty || fullName?.touched)?'invalid-input':''">
                    <input type="text" class="form-control" formControlName="fullName" id="floatingInput"
                        placeholder="name@example.com" maxlength="50">
                    <label for="floatingInput">Full Name</label>
                    <ng-container *ngIf="fullName?.invalid && (fullName?.dirty || fullName?.touched)">
                        <span class="invalid-input-text-err" *ngIf="fullName?.errors?.required">Full Name is
                            required</span>
                        <span class="invalid-input-text-err" *ngIf="fullName?.errors?.pattern">Full Name is
                            invalid</span>
                    </ng-container>
                </div>
                <div class="form-floating"
                    [ngClass]="emailId?.invalid && (emailId?.dirty || emailId?.touched)?'invalid-input':''">
                    <input [attr.disabled]="true" type="text" class="form-control" formControlName="emailId"
                        id="floatingInput">
                    <label for="floatingInput">Email ID</label>
                    <ng-container *ngIf="emailId?.invalid && (emailId?.dirty || emailId?.touched)">
                        <span class="invalid-input-text-err" *ngIf="emailId?.errors?.required">Email is
                            required</span>
                        <span class="invalid-input-text-err" *ngIf="emailId?.errors?.pattern">Email is
                            invalid</span>


                    </ng-container>
                </div>
                <div class="form-floating"
                    [ngClass]="contactNumber?.invalid && (contactNumber?.dirty || contactNumber?.touched)?'invalid-input':''">
                    <input type="text" class="form-control" formControlName="contactNumber" id="floatingInput"
                        placeholder="name@example.com" minlength="10" maxlength="10" appNumbersonly
                        (focusin)="showCountryCode=true " (focusout)="contactNumber?.errors?.required?showCountryCode=false:
                    '' ">
                    <label for="floatingInput">Phone Number (+1)</label>
                    <ng-container *ngIf="contactNumber?.invalid && (contactNumber?.dirty || contactNumber?.touched)">
                        <span class="invalid-input-text-err" *ngIf="contactNumber?.errors?.required">Phone Number is
                            required</span>
                        <span class="invalid-input-text-err" *ngIf="contactNumber?.errors?.minlength">Phone Number
                            should not
                            be less than 10 numbers</span>
                    </ng-container>
                </div>

                <button type="submit" class="btn primary w-100">Save Changes</button>
            </form>
        </div>
        <div class="modal-footer">
            <div class="modal-footer-wrapper">
                <img src="./assets/images/svg/footer-heart-white.svg" alt="footer-heart" class="modal-footer-img" />
            </div>
        </div>
    </div>
