<div [ngClass]="{'main-right-wrapper' : tableData.tableHeading !=='Dashboard'  }">
    <div [ngClass]="{'dashboard-container' : !tableData.vendor_id && tableData.tableHeading !=='Dashboard' }">
        <h1 *ngIf="tableData.tableHeading !=='Dashboard' && tableData.tableHeading !=='Ad Management'"
            [ngClass]="router.url.includes('agent-detail')?'border-none':'' || router.url.includes('vendor-detail')?'border-none':''"
            class="section-title title-border-bottom">
            {{ tableData?.tableHeading }}
        </h1>
        <div class="user-management-header" [ngClass]="(tableData?.tableType) == 'qrCode' ? 'qr-memory-header': ''">
            <div
                [ngClass]="(tableData.tableHeading) !== 'Assigned QR Memory Codes' ? 'user-mangement-header-left': 'assigned-mangement-header-left'">
                <div class="input-group search-input-group" *ngIf="!tableData?.hideSearch">
                    <input type="text" class="form-control form-control-search" placeholder="Search"
                        aria-label="Enter name" aria-describedby="basic-addon2" name="search" maxlength="30"
                        [(ngModel)]="searchData" (keyup)="keyFunc($event)" (keyup.enter)="searchGenericStatus()" />
                    <span class="input-group-text search-input-logo" id="basic-addon2"
                        (click)="searchGenericStatus()"><img src="./assets/images/svg/search-icon.svg"
                            alt="search" /></span>
                </div>
                <div class="qr-duration-wrapper">
                    <span *ngIf="tableData?.tableType === 'qrCode' || tableData?.tableType === 'user' || tableData?.tableType === 'guest-user'"
                        class="duration-label status-label">Duration:</span>
                    <select class="form-select select-custom-height" aria-label="Default select example"
                        *ngIf="!tableData?.hideFilter && tableData?.tableType !== 'cms' && tableData?.tableType !== 'warehouse' && tableData?.tableType !== 'order' && tableData?.tableType !== 'vendorCommission'"
                        (change)="searchDuration($event)" [(ngModel)]="filterData.duration" name="duration"
                        [ngClass]="tableData?.tableType === 'payment' || tableData?.tableType === 'information' ? 'ms-auto' : ''">
                        <option value=7>Last 7 Days</option>
                        <option value=30 selected>Last 30 Days</option>
                        <option value=60>Last 60 Days</option>
                        <option value=180>Last Quarter</option>
                        <option value=365>Last Year</option>
                        <option value=null>All Time</option>
                    </select>
                </div>
                <div
                    [ngClass]="{'qr-duration-wrapper qr-status-wrapper custom-select-right': (tableData.tableHeading === 'Transactions' || tableData.tableHeading === 'Information Management'), 'qr-duration-wrapper qr-status-wrapper assign-status status-select-wrap': (tableData.tableHeading === 'Assigned QR Memory Codes') , 'qr-duration-wrapper qr-status-wrapper': (tableData.tableHeading !== 'Transactions' && tableData.tableHeading !== 'Information Management' && tableData.tableHeading !== 'Assigned QR Memory Codes')}">
                    <span
                        *ngIf="(tableData?.tableType === 'qrCode' || tableData?.tableType === 'agent' || tableData?.tableType === 'agdetail')"
                        class="status-label">Status:</span>
                    <select *ngIf="tableData?.tableType === 'qrCode' || tableData?.tableType === 'agdetail'"
                        class="form-select select-custom-height " aria-label="Default select example"
                        (change)="searchStatus($event)" [(ngModel)]="filterData.status">
                        <option value=null selected>All</option>
                        <option value=0>Issued</option>
                        <option value=1>Active</option>
                        <option value=2>Inactive</option>
                        <option value=3>Assigned</option>
                        <option value=4>Returned</option>
                    </select>
                    <span *ngIf="tableData?.tableType === 'vendorCommission'" class="status-label">Status:</span>
                    <select *ngIf="tableData?.tableType === 'agent' || tableData?.tableType === 'vendorCommission'"
                        class="form-select select-custom-height" aria-label="Default select example"
                        (change)="searchStatus($event)" [(ngModel)]="filterData.status">
                        <option value=null selected>All</option>
                        <option value=1>Active</option>
                        <option value=0>Inactive</option>
                    </select>



                    <span *ngIf="tableData?.tableType === 'user'" class="status-label">Type:</span>
                    <select *ngIf="tableData?.tableType === 'user'"
                        class="form-select user-management-state select-custom-height"
                        aria-label="Default select example" (change)="searchType($event)"
                        [(ngModel)]="filterData.account_type">
                        <option value=null selected>All</option>
                        <option value=1>Owner</option>
                        <option value=2>Co-Owner</option>
                        <option value=3>Funeral Home</option>
                    </select>

                    <span *ngIf="tableData?.tableType === 'order'" class="duration-label status-label">Status:</span>
                    <select *ngIf="tableData?.tableType === 'order'" class="form-select select-custom-height"
                        aria-label="Default select example" (change)="searchShipping($event)"
                        [(ngModel)]="filterData.admin_shipping_status" name="duration">
                        <option value=null selected>Select</option>
                        <option value=Ordered>Ordered</option>
                        <option value=Processing>Processing</option>
                        <option value=Re-Shipped>Re-Shipped</option>
                        <option value=Completed>Completed</option>
                    </select>
                </div>



            </div>

            <div class="user-management-btn">
                <button class="btn primary btn-outline-primary padding-btn-custom ms-auto" type="button"
                    (click)="exportUserData()" *ngIf="tableData?.exportUserData">
                    Export Data
                </button>
                <button class="btn btn-outline-primary padding-btn-custom no-wrap-qr-code ms-auto assig-agent"
                    *ngIf="tableData?.showWarehouseQR && onGenerateQRIdList.length > 0" (click)="assignQRCodeAgent()">
                    Assign Agent
                </button>
                <button class="btn primary btn-outline-primary padding-btn-custom ms-auto" type="button"
                    (click)="exportGuestData()" *ngIf="tableData?.showExportGuestData">
                    Export Data
                </button>

                <button class="btn primary btn-outline-primary padding-btn-custom ms-auto" type="button"
                    (click)="exportWarehouseData()" *ngIf="tableData?.showExportWarehouseData">
                    Export Data
                </button>
                <button class="btn primary btn-outline-primary padding-btn-custom ms-auto" type="button"
                    (click)="exportTransactionData()" *ngIf="tableData?.showTransactionExport">
                    Export Data
                </button>
                <button class="btn primary btn-outline-primary padding-btn-custom ms-auto" type="button"
                    (click)="exportInformationData()" *ngIf="tableData?.showInformationData">
                    Export Data
                </button>
                <button class="btn primary btn-outline-primary padding-btn-custom ms-auto" type="button"
                    (click)="exportOrderData()" *ngIf="tableData?.showOrder">
                    Export Data
                </button>
                <button class="btn primary padding-btn-custom ms-auto generate-btn"
                    *ngIf="tableData && tableData?.showGenerateQR && onGenerateQRIdList.length === 0"
                    (click)="generateQrCode()">
                    Generate QR Memory Code
                </button>
                <button class="btn btn-outline-primary padding-btn-custom no-wrap-qr-code ms-auto"
                    *ngIf="tableData?.showGenerateQR && onGenerateQRIdList.length > 0" (click)="manageQRCode()">
                    <!-- {{(onGenerateQRIdList.length>1)? 'Manage QR Memory Codes' : 'Manage QR Memory Code'}} -->
                    Manage
                </button>
                <button class="btn primary padding-btn-custom no-wrap-qr-code ms-auto"
                    *ngIf="tableData?.showGenerateQR && onGenerateQRIdList.length > 0" (click)="downloadQRCode()">
                    Download
                </button>
                <button class="btn primary btn-outline-primary padding-btn-custom ms-auto qr-memory-export"
                    type="button" (click)="exportQrCodeData()"
                    *ngIf="tableData?.exportQrCode && tableData?.showGenerateQR && onGenerateQRIdList.length === 0">
                    Export Data
                </button>
                <button class="btn btn-outline-primary padding-btn-custom unassign-btn-qr-code ms-auto"
                    *ngIf="tableData?.tableType === 'agdetail' && tableData?.showUnassignQR && unassignQrVendor.length > 0"
                    (click)="unassignQrCode(tableData.id)">
                    {{(tableData?.showUnassignQR && unassignQrVendor.length > 1) ? 'Unassign QR Memory Codes' :
                    'Unassign QR Memory Code' }}
                </button>
                <button class="btn primary padding-btn-custom ms-auto" type="button" (click)="addProfile()"
                    *ngIf="tableData?.showAddSales">
                    Add Sales Agent
                </button>
                <button class="btn btn-outline-primary padding-btn-custom ms-auto margin-left-export" type="button"
                    (click)="exportSalesReport()" *ngIf="tableData?.showAddSales">
                    Export Sales Report
                </button>
                <button class="btn btn-outline-primary padding-btn-custom ms-auto margin-left-export" type="button"
                    (click)="exportSalesReportVendor()" *ngIf="tableData?.showAddSalesVendor">
                    Export Sales Report
                </button>
                <button class="btn btn-outline-primary padding-btn-custom ms-auto margin-left-export" type="button"
                    (click)="filterData.download_csv = 1; searchTable()"
                    *ngIf="tableData?.showExport && dataSource?.length > 0">
                    Export
                </button>
            </div>
        </div>
    </div>

    <!--  Table start from here  -->
    <div class="table-container" [ngClass]="tableData?.containerClass ? tableData?.containerClass : ''">
        <div class="table-head" [ngClass]="tableData?.headClass ? tableData?.headClass : ''">
            <div class="table-row" [ngClass]="tableData?.rowClass ? tableData?.rowClass : ''">
                <div class="th" *ngIf="tableData?.tableType != 'cms'">&nbsp;</div>
                <div class="th" *ngIf="tableData?.tableType === 'qrCode' || tableData?.tableType === 'warehouse'">
                    <div class="form-check form-check-qr">
                        <input class="form-check-input" type="checkbox" [disabled]="masterQR"
                            [(ngModel)]="isMasterChecked" (change)="
              checkUncheckAll(isMasterChecked)
            " id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                        </label>
                    </div>
                </div>

                <div class="th" *ngIf="tableData?.tableType === 'agdetail'">
                    <div class="form-check form-check-qr">
                        <input class="form-check-input" type="checkbox" [disabled]="masterAgent"
                            [(ngModel)]="isMasterChecked" (change)="
              checkUncheckAllQR(isMasterChecked)
            " id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                        </label>
                    </div>
                </div>

                <ng-container *ngFor="let column of colFields">
                    <div class="th">
                        {{ column.headerName }}
                        <img *ngIf="column.headerName && !tableData?.hideSort && column.sortOrder"
                            src="assets/images/svg/sort-icon-down.svg" alt="sort icon" (click)="sortTable(column,'asc')"
                            class="sort-icon-down" />
                        <img *ngIf="column.headerName && !tableData?.hideSort && column.sortOrder"
                            src="assets/images/svg/sort-icon-up.svg" alt="sort icon" (click)="sortTable(column,'desc')"
                            class="sort-icon-up" />
                    </div>
                </ng-container>
                <div class="th" *ngIf="tableData?.tableType != 'cms'"></div>
            </div>
        </div>

        <div *ngIf="dataSource?.length > 0" class="table-body"
            [ngClass]="tableData.bodyClass ? tableData.bodyClass : ''">
            <!--  repeat this div  -->
            <div class="table-row"
                *ngFor="let data of dataSource | paginate: { itemsPerPage: paginationData?.per_page, currentPage: paginationData?.current_page , totalItems: paginationData?.total}">
                <div class="td" *ngIf="tableData?.tableType != 'cms'">&nbsp;</div>
                <div class="td" *ngIf="tableData?.tableType === 'qrCode' || tableData?.tableType === 'warehouse'">
                    <div class="form-check form-check-qr">
                        <input class="form-check-input" type="checkbox" [disabled]="data.sold_on || data.vendor_name"
                            [(ngModel)]="data.isChecked" id="flexCheckDefault" (change)="
              fetchCheckedIDs(dataSource)">
                        <label class="form-check-label" for="flexCheckDefault"> </label>
                    </div>
                </div>
                <div class="td" *ngIf="tableData?.tableType === 'agdetail'">
                    <div class="form-check form-check-qr">
                        <input class="form-check-input" [disabled]="data.sold_on" type="checkbox"
                            [(ngModel)]="data.isChecked" id="flexCheckDefault" (change)="
              fetchCheckedIDsQR(dataSource)">
                        <label class="form-check-label" for="flexCheckDefault"> </label>
                    </div>
                </div>

                <ng-container *ngFor="let column of colFields">
                    <div title="{{data[column.fieldName]}}" (click)="moveToDetail(data)" class="td"
                        *ngIf="column.fieldType == 'TEXT'">
                        {{ data[column.fieldName] ? data[column.fieldName] : "-" }}
                    </div>
                    <div class="td" *ngIf="column.fieldType == 'SHIPPING_ADDRESS'">
                        {{ data?.user_stripe_details_address ? data.user_stripe_details_address : "-" }}
                        {{data?.user_stripe_details_city_name ? data.user_stripe_details_city_name : " "}}
                        {{data?.user_stripe_details_state_name ? data.user_stripe_details_state_name : " "}}
                    </div>
                    <div title="{{data[column.fieldName]}}" (click)="moveToDetail(data)" class="td"
                        *ngIf="column.fieldType == 'IMG_TEXT'">
                        <img [src]="data?.profile_picture || 'assets/images/svg/user-logo.svg'" alt="user-img"
                            class="profile-user-img">
                        {{ data[column.fieldName] ? data[column.fieldName] : "-" }}
                    </div>
                    <div title="{{data[column.fieldName]}}" (click)="showDeliveryAddress(data)" class="td"
                        *ngIf="column.fieldType == 'ADDRESS' && (column.headerName == 'QR Memory Code' || column.headerName == 'Memory Code')">
                        {{ data[column.fieldName] ? data[column.fieldName] : "-" }}
                    </div>
                    <div title="{{data[column.fieldName]}}" (click)="moveToDetail(data)" class="td"
                        *ngIf="column.fieldType == 'DOLLAR' && column.fieldAlterName !=='DOLLAR'">
                        ${{ data[column.fieldName] | number : '1.2-2'}}
                    </div>
                    <div title="{{data[column.fieldName]}}" (click)="moveToDetail(data)" class="td"
                        *ngIf="column.fieldType == 'DOLLAR' && column.fieldAlterName =='DOLLAR'">
                        ${{ data[column.fieldName] | number : '1.2-2' }}
                    </div>
                    <div title="{{data[column.fieldName]}}" (click)="moveToDetail(data)" class="td"
                        [ngClass]="{'text-red' : !data[column.fieldName]}" *ngIf="column.fieldType == 'TEXTRED'">
                        {{ data[column.fieldName] ? data[column.fieldName] : "0" }}
                    </div>
                    <div title="{{data[column.fieldName]}}" (click)="moveToDetail(data)" class="td"
                        *ngIf="column.fieldType == 'DEC_TEXT'">
                        {{ data?.first_name ? data.first_name : "-" }} {{data?.middle_name ? data.middle_name : " "}}
                        {{data?.last_name ? data.last_name : " "}}
                    </div>
                    <div title="{{data[column.fieldName]}}" class="td" *ngIf="column.fieldType == 'DEC_TEXT_GUEST'">
                        {{ data[column.fieldName] ? data[column.fieldName]?.name : "-" }}
                    </div>
                    <div title="{{data[column.fieldName]}}" class="td" *ngIf="column.fieldType == 'OPT'">
                        {{ data[column.fieldName] == '1' ? 'Yes' : "No" }}
                    </div>
                    <div (click)="moveToDetail(data)" class="td" *ngIf="column.fieldType == 'COMM'">
                        {{ data[column.fieldName] ? data[column.fieldName]+'%' : "0%" }}
                    </div>
                    <div (click)="moveToDetail(data)" class="td" *ngIf="column.fieldType == 'PLAN'">
                        {{ data[column.fieldName] ? data[column.fieldName] : "Basic" }}
                    </div>
                    <div title="{{data[column.fieldName]}}" class="td" *ngIf="column.fieldType == 'PAYMENT_TEXT'">
                        {{ data[column.fieldName] === null ? data[column.fieldAlterName]: data[column.fieldName]}}
                    </div>
                    <div class="td" *ngIf="column.fieldType == 'MODE'">
                        {{ data[column.fieldName] === 1?'Online':'Offline'}}
                    </div>
                    <div class="td" *ngIf="column.fieldType == 'USER_TEXT'">
                        {{ data.account_type == 1 ? 'Owner' : data.account_type == 3 ? 'Funeral Home' :
                        data.account_type == 2 ?
                        'Co-Owner' : '-'}}
                    </div>
                    <div (click)="moveToDetail(data)" class="td"
                        *ngIf="column.fieldType == 'TEXT_OBJ' && column.nestedField != null">
                        {{ data[column.fieldName] && data[column.fieldName][column.nestedField] ?
                        data[column.fieldName][column.nestedField] : "-" }}
                    </div>
                    <div (click)="moveToDetail(data)" class="td active-btn-td" *ngIf="column.fieldType == 'STATUS'">
                        <button class="btn btn-primary"
                            [ngClass]="data.email_verification_token?'unassigned':data.status == 1 ? ' btn btn-primary active' :'inactive'">
                            {{ data.email_verification_token?'Unverified': data.status == 1 ? "Active" : "Inactive" }}
                        </button>
                    </div>
                    <div class="td active-btn-td" *ngIf="column.fieldType == 'STATUS_PAYMENT'">
                        <button class="btn btn-primary"
                            [ngClass]="
              data.status === 1 ? ' btn btn-primary active' : data.status === 2 ? 'failed' : data.status === 3 ? 'canceled' : data.status === 0 ? 'pending' :'active'">
                            {{ data.status === 1 ? 'Success' : data.status === 3 ? 'Cancelled' : data.status === 2 ?
                            'Failed' : data.status === 0 ? 'Pending' : 'Success'}}
                        </button>
                    </div>
                    <div class="td active-btn-td" *ngIf="column.fieldType == 'QR_STATUS'">
                        <button *ngIf="data.qr_status == '0'" class="btn btn-primary issued">Issued</button>
                        <button *ngIf="data.qr_status == '1'" class="btn btn-primary active">Active</button>
                        <button *ngIf="data.qr_status == '2'" class="btn btn-primary inactive">Inactive</button>
                        <button *ngIf="data.qr_status == '3'" class="btn btn-primary assigned">Assigned</button>
                        <button *ngIf="data.qr_status == '4'" class="btn btn-primary returned">Returned</button>
                    </div>
                    <div class="td active-btn-td" *ngIf="column.fieldType == 'SH_STATUS'">
                        <button *ngIf="!data.shipping_status" class="btn btn-primary empty-status">-</button>
                        <button [title]="data.status_details ? data.status_details : ''"
                            *ngIf="data.shipping_status == 'DELIVERED'"
                            class="btn btn-primary delivered">Delivered</button>
                        <button [title]="data.status_details ? data.status_details : ''"
                            *ngIf="data.shipping_status == 'UNKNOWN'" class="btn btn-primary ordered">Unknown</button>
                        <button [title]="data.status_details ? data.status_details : ''"
                            *ngIf="data.shipping_status == 'FAILURE'"
                            class="btn btn-primary processing">Failure</button>
                        <button [title]="data.status_details ? data.status_details : ''"
                            *ngIf="data.shipping_status == 'RETURNED'"
                            class="btn btn-primary re-shipped">Returned</button>
                        <button [title]="data.status_details ? data.status_details : ''"
                            *ngIf="data.shipping_status == 'TRANSIT'"
                            class="btn btn-primary re-shipped">Transit</button>
                        <button [title]="data.status_details ? data.status_details : ''"
                            *ngIf="data.shipping_status == 'PRE_TRANSIT'"
                            class="btn btn-primary re-shipped">Pre-Transit</button>
                    </div>
                    <div class="td active-btn-td" *ngIf="column.fieldType == 'CH_STATUS'">
                        <button *ngIf="!data.admin_shipping_status" class="btn btn-primary empty-status">-</button>
                        <button *ngIf="data.admin_shipping_status == 'Ordered'"
                            class="btn btn-primary ordered">Ordered</button>
                        <button *ngIf="data.admin_shipping_status == 'Processing'"
                            class="btn btn-primary processing">Processing</button>
                        <button *ngIf="data.admin_shipping_status == 'Re-Shipped'"
                            class="btn btn-primary re-shipped">Re-Shipped</button>
                        <button *ngIf="data.admin_shipping_status == 'Completed'"
                            class="btn btn-primary delivered">Completed</button>
                    </div>
                    <div (click)="moveToDetail(data);" class="td" *ngIf="column.fieldType == 'LOCATION'">
                        <ng-container *ngIf="column.fieldName=='cemetery_name'">
                            {{ data && data.city_name && data.city_name.name }}, {{ data && data.state_name &&
                            data.state_name.code }}, US
                        </ng-container>
                        <ng-container *ngIf="column.fieldName=='burial_cemetery_name'">
                            {{ data && data.burial_city_name && data.burial_city_name.name }}, {{ data &&
                            data.burial_state_name && data.burial_state_name.code }}, US
                        </ng-container>
                    </div>
                    <div (click)="moveToDetail(data)" class="td" *ngIf="column.fieldType == 'TIME'">
                        {{ data[column.fieldName] ? (data[column.fieldName] | date: "hh:mm a") : "-" }}
                    </div>
                    <div (click)="moveToDetail(data)" class="td" *ngIf="column.fieldType == 'DATE'">
                        {{ data[column.fieldName] ? (data[column.fieldName] | date: "MM/dd/yyyy") : "-" }}
                    </div>
                    <div class="td" *ngIf="column.fieldType == 'DATE_OBJ' && column.nestedField != null">
                        {{ data[column.fieldName] && data[column.fieldName][column.nestedField] ?
                        (data[column.fieldName][column.nestedField] | date: "MM/dd/yyyy") : "-" }}
                    </div>
                    <div [ngClass]="data[column.fieldName2] != 0 ? 'td' : 'td wrong-msg'"
                        *ngIf="column.fieldType == 'QRSOLDTOTAL'">
                        {{data[column.fieldName1]+'/'+data[column.fieldName2]}}
                    </div>
                    <div *ngIf="column.fieldType == 'SOLD/UNSOLD'" class="td qr-btn-td three-dot-style">
                        <div class="three-dot-menu"
                            [ngClass]="{'three-dot-menuop' : !(data?.qr_status == '3' || data?.qr_status == '1' || data?.qr_status == '0') || showAction}">
                            <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1"
                                [ngClass]="{'three-dot-menuop' : !(data?.qr_status == '3' || data?.qr_status == '2')}"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="assets/images/svg/3-dots-horizontal.svg" alt="" class="3-dots-icon"
                                    id="inactive-icon" />
                            </button>
                            <ul class="dropdown-menu" [ngClass]="{'d-none' : !(data?.qr_status == '3') || showAction}"
                                aria-labelledby="dropdownMenuButton1">
                                <li *ngIf="data?.qr_status == '3'">
                                    <a class="dropdown-item" (click)="markAsSold(data,data.created_at)">Mark As
                                        Issued</a>
                                </li>
                                <li *ngIf="data?.qr_status == '3'">
                                    <a class="dropdown-item" (click)="markAsReturned(data)">Return</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="td active-btn-td" *ngIf="column.fieldType == 'STATUSSOLD'">

                        <button *ngIf="data.qr_status == '0'" class="btn btn-primary issued">Issued</button>
                        <button *ngIf="data.qr_status == '1'" class="btn btn-primary active">Active</button>
                        <button *ngIf="data.qr_status == '2'" class="btn btn-primary inactive">Inactive</button>
                        <button *ngIf="data.qr_status == '3'" class="btn btn-primary assigned">Assigned</button>
                        <button *ngIf="data.qr_status == '4'" class="btn btn-primary returned">Returned</button>

                    </div>
                    <div *ngIf="column.fieldType == 'QR_ICON'" class="td qr-btn-td three-dot-style">
                        <button *ngIf="!tableData?.showOrder" [disabled]="showAction"
                            class="btn btn-link btn-no-border me-1" (click)="showQrCode(data)">
                            <img src="./assets/images/svg/admin-show.svg" alt="admin-show-icon" />
                        </button>
                        <button *ngIf="!tableData?.showOrder" [disabled]="showAction" class="btn btn-link btn-no-border"
                            (click)="downloadSingleQrCode(data.serial_number)">
                            <img src="./assets/images/svg/admin-download.svg" alt="admin-download-icon" />
                        </button>
                        <div *ngIf="!tableData?.showWarehouseQR" class="three-dot-menu"
                            [ngClass]="{'three-dot-menuop' : !(data?.member_id || data?.qr_status == '3' || data?.qr_status == '2' || tableData?.showOrder) || showAction}">
                            <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="assets/images/svg/3-dots-horizontal.svg" alt="" class="3-dots-icon"
                                    id="inactive-icon" />
                            </button>
                            <ul class="dropdown-menu"
                                [ngClass]="{'d-none' : !(data?.member_id || data?.qr_status == '3' || data?.qr_status == '2' || tableData?.showOrder) || showAction}"
                                aria-labelledby="dropdownMenuButton1">
                                <li *ngIf="data?.member_id && !tableData?.showOrder">
                                    <a class="dropdown-item" href="{{memorialUrl + data?.member_id}}" target="blank">Go
                                        to Memorial Page</a>
                                </li>
                                <li *ngIf="data?.qr_status == '3' && !tableData?.showOrder">
                                    <a class="dropdown-item" (click)="_unassignQR(data.id)">Unassign Agent</a>
                                </li>
                                <li *ngIf="data?.qr_status == '2' && !tableData?.showOrder">
                                    <a class="dropdown-item" (click)="markAsSold(data,data.created_at)">Mark As
                                        Issued</a>
                                </li>
                                <li *ngIf="tableData?.showOrder">
                                    <a class="dropdown-item" (click)="changeShippingStatus(data)">Change
                                        Status</a>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div class="td" *ngIf="column.fieldType == 'edit'">
                        <button class="btn btn-link" [routerLink]="[tableData.routerLink, data.page]">
                            Edit Details
                        </button>
                    </div>
                    <div class="td" *ngIf="column.fieldType == 'HTML'">
                        <p hidden [innerHTML]="data.content" #whatsNew></p>
                        <p *ngIf="!router.url.includes('cms-list')">{{ getInnerText(whatsNew).length > 50 ?
                            (getInnerText(whatsNew) | slice:0:50) +'...' :
                            getInnerText(whatsNew) }}</p>
                        <p *ngIf="router.url.includes('cms-list')" class="cmsListCSS">{{ getInnerText(whatsNew)}}</p>

                    </div>
                    <div title="{{data[column.fieldName]}}" class="td" *ngIf="column.fieldType == 'HTMLURL'">
                        <p hidden [innerHTML]="data[column.fieldName]" #whatsNew></p>
                        <p>{{ getInnerText(whatsNew).length > 50 ? (getInnerText(whatsNew) | slice:0:50) +'...' :
                            getInnerText(whatsNew) }}</p>
                    </div>
                    <div class="td"
                        *ngIf="column.fieldType == 'action' && column.fieldName != 'Vendor' && column.fieldName != 'VendorDetails'">
                        <div class="three-dot-menu">
                        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton12"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="assets/images/svg/3-dots-horizontal.svg" alt="" class="3-dots-icon"
                                id="inactive-icon" />
                        </button>

                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton12">
                            <li>
                                <a class="dropdown-item" [routerLink]="[tableData.routerLink, data.id]"
                                    (click)="viewProfile(data.id)">View
                                    Profile</a>
                            </li>
                            <li *ngIf="tableData?.tableType === 'user'">
                                <a class="dropdown-item" (click)="editProfileUser(data)">Edit Details</a>
                            </li>
                            <li *ngIf="tableData?.tableType === 'user'">
                                <a class="dropdown-item" (click)="createUserPassword(data)">
                                    Create Password</a>
                            </li>
                            <li *ngIf="tableData?.tableType === 'agent'">
                                <a class="dropdown-item" (click)="editProfile(data)">Edit</a>
                            </li>
                            <li>
                                <a *ngIf="data?.status == 0" class="right-msg dropdown-item"
                                    (click)="deactiveAccount(data)">
                                    Activate Account</a>
                                <a *ngIf="data?.status == 1" class="wrong-msg dropdown-item"
                                    (click)="deactiveAccount(data)">
                                    Deactivate Account</a>
                            </li>
                            <li *ngIf="tableData?.tableType === 'user'">
                                <a class="wrong-msg dropdown-item" (click)="deleteAccount(data)">
                                    Delete Account</a>
                            </li>
                        </ul>
                    </div>
                    </div>
                    <div class="td" *ngIf="column.fieldType == 'action' && column.fieldName == 'Vendor'">
                        <a class="btn btn-link-view" href="javascript:;" [routerLink]="[tableData.routerLink, data.id]"
                            (click)="viewProfile(data.id)">View
                        </a>
                    </div>
                    <div class="td" *ngIf="column.fieldType == 'action' && column.fieldName == 'VendorDetails'">
                        <a class="btn btn-link-view" [ngClass]="{'text-red' : !data[column.fieldName]}"
                            href="javascript:;" (click)="deletePayment(data,column)">Delete
                        </a>
                    </div>
                </ng-container>
            </div>
        </div>

        <div *ngIf="dataSource?.length === 0 || !dataSource" class="no-data-container">
            <div>
                <img src="assets/images/svg/no-data-icon.svg" alt="no-data-icon">
            </div>
            <p>No Data Available</p>
        </div>
    </div>

    <div class="pagination-section" *ngIf="!tableData?.hidePagination && dataSource?.length > 0"
        [hidden]="paginationData?.last_page < 1">
        <div class="total-records">
            <p>Total Records: <span class="text-bold">{{paginationData?.total}}</span></p>
        </div>
        <pagination-controls [directionLinks]="true" [autoHide]="true" (pageChange)="pageChanged($event)"
            class="my-pagination" previousLabel="" nextLabel=""></pagination-controls>
    </div>
