import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ChangePasswordComponent } from '../../../private/change-password/change-password.component';
import { IResponse } from '../../app-interface';
import { AuthService } from '../../services/auth.service';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public userData;
  constructor(public modalService: BsModalService, public authService: AuthService, public commonStateService: CommonService, public toastr: ToastrService,
    public router: Router) { }

  ngOnInit(): void {
    // To get the admin data
    const data = localStorage.getItem('adminData');
    this.userData = JSON.parse(data);
  }
  /**
   * Opening the change password modal
  */
  openChangePassword() {
    this.modalService.show(ChangePasswordComponent, { class: 'max-width-605 modal-dialog-centered' });
  }
  /**
   * To logout the admin
  */
  logout() {
    this.authService.logout().subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.commonStateService.userData.next(null);
        localStorage.clear();
        this.toastr.success('Logged out successfully');
        this.router.navigate(['/']);
      }
    });
  }
}
