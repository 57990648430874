import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html'
})
export class SideBarComponent implements OnInit {
  showDropDown = true;
  showDropDown2 = true;
  ngOnInit(): void {
    $(document).on('click', 'dropdown-menu dropdown-menu-end sales-drop-menu .dropdown-menu', function (e) {
      e.stopPropagation();
    });
  }
}
