import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public userData: BehaviorSubject<any>;
  public addPayment: BehaviorSubject<any>;
  public commissionData: BehaviorSubject<any>;
  public commissionDataStatus: BehaviorSubject<any>;
  public searchData: BehaviorSubject<any>;


  constructor() {
    const data = localStorage.getItem('adminData');
    const userData = JSON.parse(data);
    this.addPayment = new BehaviorSubject(false);
    this.userData = new BehaviorSubject(userData);
    this.commissionData= new BehaviorSubject(null);
    this.commissionDataStatus= new BehaviorSubject(null);
    this.searchData = new BehaviorSubject(null);
  }
}


