import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(public http: HttpClient) { }
  onSignIn(payload) {
    return this.http.post(`${environment.apiUrl}admin-login?email=${payload.email}&password=${payload.password}`, '');
  }
  forgotPassword(email) {
    return this.http.post(`${environment.apiUrl}reset_email?email=${email}`, '');
  }
  resetPassword(payload) {
    return this.http.post(`${environment.apiUrl}reset_password?token=${payload.token}&email=${payload.email}
    &new_password=${payload.new_password}&confirm_password=${payload.confirm_password}`, '');
  }
  logout() {
    return this.http.post(`${environment.apiUrl}admin-logout`, '');
  }
  changePasswordAdmin(requestParams) {
    return this.http.post(`${environment.apiUrl}change_password?current_password=${requestParams.oldPassword}
      &new_password=${requestParams.newPassword}&confirm_password=${requestParams.cnfrmPassword}`, '');
  }
}
