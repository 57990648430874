import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(public http: HttpClient) { }

  getAnalyticsData(params) {
    if(params!==undefined){
      return this.http.get(`${environment.apiAdminUrl}get-analytics-report?${params}&timezone=${moment.tz.guess()}`);
    }else{
      return this.http.get(`${environment.apiAdminUrl}get-analytics-report?timezone=${moment.tz.guess()}`);
    }
  }

  getRevenueData(params) {
    if(params!==undefined){
      return this.http.get(`${environment.apiAdminUrl}get-revenue-report?${params}&timezone=${moment.tz.guess()}`);
    }else{
      return this.http.get(`${environment.apiAdminUrl}get-revenue-report?timezone=${moment.tz.guess()}`);
    }
  }

  getCommissionData(params) {
    if(params!==undefined){
      return this.http.get(`${environment.apiAdminUrl}get-commission-report?${params}&timezone=${moment.tz.guess()}`);
    }else{
      return this.http.get(`${environment.apiAdminUrl}get-commission-report?timezone=${moment.tz.guess()}`);
    }
  }

  getOverviewData(params) {
    if(params!==undefined){
      return this.http.get(`${environment.apiAdminUrl}get-plan-report?${params}&timezone=${moment.tz.guess()}`);
    }else{
      return this.http.get(`${environment.apiAdminUrl}get-plan-report?timezone=${moment.tz.guess()}`);
    }
  }

  // getDashboardUserData() {
  //   return this.http.get(`${environment.apiUrl}vendor/get-qrcodes-data?`);
  // }

  // getData(userId: number) {
  //   return this.http.get(`${environment.apiAdminUrl}get-all-vendors?user_id=${userId}`);
  // }

  // getData(params) {
  //   return this.http.get(`${environment.apiAdminUrl}get-vendor-qrcodes?${params}`);
  // }

  // getReturn() {
  //   return this.http.get(`${environment.apiUrl}common/return-reasons`);
  // }
  // getDatas(params) {
  //   return this.http.get(`${environment.apiAdminUrl}get-vendor-qrcodes?vendor_id=70`);
  // }

  // markAsSold(params) {
  //   return this.http.post(`${environment.apiAdminUrl}change-sold-status/${params}`, '');
  // }

  // markAsReturn(params) {
  //   return this.http.post(`${environment.apiUrl}vendor/return-qr/${params}`, '');
  // }

  // customSalesFilter(params) {
  //   return this.http.get(`${environment.apiUrl}vendor/get-commission-amount?${params}&timezone=${moment.tz.guess()}`);
  // }

}
