import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public toastr: ToastrService, public router: Router) {
  }
  /**
 * To protect the private pages
 *
*/
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (localStorage.getItem('adminData')) {
      return true;
    } else {
      this.router.navigate(['/signin']);
      return false;
    }
  }

}


@Injectable({
  providedIn: 'root'
})
export class CanAuthLoginGuard implements CanActivate {
  constructor(public toastr: ToastrService, public router: Router) {

  }
  /**
   * To protect the public  pages without login
   *
  */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!localStorage.getItem('adminData')) {
      return true;
    } else {

      return false;
    }
  }

}

