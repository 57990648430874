import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../../environments/environment';
import { MagicNumber } from '../../app-magic-string';

@Component({
  selector: 'app-view-infomation',
  templateUrl: './view-infomation.component.html'
})
export class ViewInfomationComponent implements OnInit {
  public data;
  public viewInformationForm: FormGroup;
  public showMaxlength = false;
  public isSubmitted = false;
  public event = new EventEmitter();
  public tinyMceKey;
  constructor(public modalRef: BsModalRef, private readonly formBuilder: FormBuilder, public loader: NgxSpinnerService) {
    this.tinyMceKey = environment.tinyMceKey;
    this.viewInformationForm = this.formBuilder.group({
      content: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.loader.show();
    setTimeout(() => {
      this.loader.hide();
    }, MagicNumber.twoThousand);
    this.viewInformationForm.patchValue({
      content: this.data && this.data.section,
    });
  }

  get informationContent() { return this.viewInformationForm.get('content'); }

  /**
   * Updating the changes in form
  */
  updateChanges() {
    this.isSubmitted = true;
    if (this.viewInformationForm.valid) {
      if (!this.showMaxlength) {
        this.event.emit({ data: this.viewInformationForm.value.content, res: 200 });
        this.modalRef.hide();
      }

    } else {
      this.viewInformationForm.markAllAsTouched();
    }
  }
  /**
     * To listen editor changes event
    */
  editorChanges(e) {
    this._getCountValidation();
  }
  /**
     * Couting the words in editor
    */
  private _getCountValidation() {
    const countWords = document.getElementById('contentInformation')?.innerText;
    const countWordLen = countWords.trim().split(/\s+/).length;

    if (countWordLen > MagicNumber.threeHundred) {
      this.showMaxlength = true;
    } else {
      this.showMaxlength = false;
    }
  }
}
