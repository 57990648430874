<!-- Add profile Modal -->
<div class="modal-dialog modal-dialog-centered max-width-605">
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" (click)="modalRef.hide()" data-bs-dismiss="modal"
                aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <h1 class="modal-title">Edit Sales Agent</h1> 
            <form [formGroup]="editProfile" (ngSubmit)="submitProfile()" class="modal-form">
                <div class="form-floating"
                    [ngClass]="fullName?.invalid && (fullName?.dirty || fullName?.touched)?'invalid-input':''">
                    <input type="text" class="form-control" formControlName="fullName" id="floatingInput"
                        placeholder="name@example.com" maxlength="50">
                    <label for="floatingInput">Full Name</label>
                    <ng-container *ngIf="fullName?.invalid && (fullName?.dirty || fullName?.touched)">
                        <span class="invalid-input-text-err" *ngIf="fullName?.errors?.required">Full Name is
                            required</span>
                            <span class="invalid-input-text-err" *ngIf="fullName?.errors?.pattern">Full Name is
                                invalid</span>
                    </ng-container>
                </div>
                <div class="form-floating"
                    [ngClass]="mobile?.invalid && (mobile?.dirty || mobile?.touched)?'invalid-input':''">
                    <input type="text" class="form-control" formControlName="mobile"
                        id="floatingInput" placeholder="name@example.com" minlength="10" maxlength="10" appNumbersonly
                        (focusin)="showCountryCode=true " (focusout)="mobile?.errors?.required?showCountryCode=false:
                    '' ">
                    <label for="floatingInput">Phone Number (+1)</label>
                    <ng-container *ngIf="mobile?.invalid && (mobile?.dirty || mobile?.touched)">
                        <span class="invalid-input-text-err" *ngIf="mobile?.errors?.required">Phone Number is
                            required</span>
                        <span class="invalid-input-text-err" *ngIf="mobile?.errors?.minlength">Phone Number should not
                            be less than 10 numbers</span>
                            <span class="invalid-input-text-err" *ngIf="mobile?.errors?.pattern">Phone Number should not
                                be less than 10 numbers</span>
                    </ng-container>
                </div>
                <div class="form-floating"
                    [ngClass]="emailId?.invalid && (emailId?.dirty || emailId?.touched)?'invalid-input':''">
                    <input [attr.disabled]="true" type="text" class="form-control" formControlName="emailId" id="floatingInput">
                    <label for="floatingInput">Email ID</label>
                    <ng-container *ngIf="emailId?.invalid && (emailId?.dirty || emailId?.touched)">
                        <span class="invalid-input-text-err" *ngIf="emailId?.errors?.required">Email is
                            required</span>
                        <span class="invalid-input-text-err" *ngIf="emailId?.errors?.pattern">Email is
                            invalid</span>
                    </ng-container>
                </div>
                <div class="form-floating"
                    [ngClass]="address?.invalid && (address?.dirty || address?.touched)?'invalid-input':''">
                    <input type="text" class="form-control" formControlName="address" id="floatingInput"
                        placeholder="name@example.com" maxlength="100">
                    <label for="floatingInput">Address</label>
                    <ng-container *ngIf="address?.invalid && (address?.dirty || address?.touched)">
                        <span class="invalid-input-text-err" *ngIf="address?.errors?.required">Address is
                            required</span>
                            <span class="invalid-input-text-err" *ngIf="address?.errors?.maxlength">Address should not
                                be more than 100 characters</span>
    
                    </ng-container>
                </div>
                <div class="form-floating"
                [ngClass]="commission?.invalid && (commission?.dirty || commission?.touched)?'invalid-input':''">
                <input onkeypress='return event.charCode >= 48 && event.charCode <= 57' type="text" class="form-control" formControlName="commission"
                    id="floatingInput" placeholder="name@example.com" maxlength="3" appNumbersonly
                     (focusout)="commission?.errors?.required?showCountryCode=false:'' ">
                <label for="floatingInput">Commission (%)</label>
                <ng-container *ngIf="commission?.invalid && (commission?.dirty || commission?.touched)">
                    <span class="invalid-input-text-err" *ngIf="commission?.errors?.required">Commission is
                        required</span>
                    <span class="invalid-input-text-err" *ngIf="commission?.errors?.pattern">Commission should not
                        be greater than 100%</span>
    
                </ng-container>
            </div>
                <button type="submit" class="btn primary w-100" [disabled]="!(editProfile.valid) || (editProfile.pristine)">Update</button>
            </form>
        </div>
    </div>