import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AD_MNGMNT } from './ad-management.col.def';
import { AdManagementService } from '../../common/services/ad-management.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IResponse } from '../../common/app-interface';
import { NgxSpinnerService } from 'ngx-spinner';
import { MagicNumber } from '../../common/app-magic-string';
@Component({
  selector: 'app-ad-managment',
  templateUrl: './ad-managment.component.html',
  styleUrls: ['./ad-managment.component.scss']
})
export class AdManagmentComponent implements OnInit {
  public ad1Form: FormGroup;
  public ad2Form: FormGroup;


  public colFields = AD_MNGMNT;
  public tableData = {
    tableHeading: 'Ad Management', tableType: 'Advertisement',
    serviceName: 'adService', routerLink: '/ad-managment',
    containerClass: 'table-container-ad-management mb-5', headClass: 'table-head-user', rowClass: 'table-row-user',
   showExport: true, hideFilter: true
  };
  adDetails: any;
  imageUploading = false;
  showAd1 = false;
  showAd2 = false;
  num1 = MagicNumber.twoThousand;
  num2 = MagicNumber.oneZeroTwoFour;
  num3 = MagicNumber.five;


 


  // ad1Detail: adParam;

  constructor( public formBuilder:FormBuilder, public adService: AdManagementService,
    public toastr: ToastrService, public loader: NgxSpinnerService) {
      this.getAdData();

      this.ad1Form = this.formBuilder.group ({
        ad1Id: [''],
        ad1Click: [''],
        ad1Name: ['', [Validators.required, Validators.pattern(/^[\w\s!@#$%^&*()-_+=\[\]{}|\\;:'",.<>\/?]*$/)]],
        ad1Placement: ['', Validators.required],
        ad1Url: ['', [Validators.required, Validators.pattern(/[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/)]],
        ad1Image: ['', Validators.required],
      });

      this.ad2Form = this.formBuilder.group ({
        ad2Id: [''],
        ad2Click: [''],
        ad2Name: ['', [Validators.required, Validators.pattern(/^[\w\s!@#$%^&*()-_+=\[\]{}|\\;:'",.<>\/?]*$/)]],
        ad2Placement: ['', Validators.required],
        ad2Url: ['', [Validators.required, Validators.pattern(/[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/)]],
        ad2Image: ['', Validators.required],
      });

      this.ad1Form.markAsPristine();
      this.ad2Form.markAsPristine();
     }


  ngOnInit(): void {
    this.getAdData();
    this.loader.show();
      setTimeout(() => {
        this.loader.hide();
        this.ad1Form.patchValue({
          ad1Id: this.adDetails && this.adDetails[0].id,
          ad1Status: this.adDetails && this.adDetails[0].status,
          ad1Click: this.adDetails && this.adDetails[0].ad_space_click_count,
          ad1Name: this.adDetails && this.adDetails[0].name,
          ad1Placement: this.adDetails && this.adDetails[0].placement,
          ad1Url: this.adDetails && this.adDetails[0].url,
          ad1Image:this.adDetails && this.adDetails[0].image
        });
        this.ad2Form.patchValue({
          ad2Id: this.adDetails && this.adDetails[1].id,
          ad2Status: this.adDetails && this.adDetails[1].status,
          ad2Click: this.adDetails && this.adDetails[1].ad_space_click_count,
          ad2Name: this.adDetails && this.adDetails[1].name,
          ad2Placement: this.adDetails && this.adDetails[1].placement,
          ad2Url: this.adDetails && this.adDetails[1].url,
          ad2Image:this.adDetails && this.adDetails[1].image
        });
      }, this.num1);
  }

  get ad1Name() { return this.ad1Form.get('name'); }
  get ad1Click() { return this.ad1Form.get('ad_space_click_count'); }
  get ad1Placement() { return this.ad1Form.get('placement'); }
  get ad1Url() { return this.ad1Form.get('url'); }
  get ad1Image() { return this.ad1Form.get('image'); }



  checkAdClick1(id,val){
    this.showAd1 = !val;
    this.changeStatusForAd(id,this.showAd1);
  }

checkAdClick2(id,val){
  this.showAd2 = !val;
  this.changeStatusForAd(id,this.showAd2);
  
}

  removeAd1Image() {
    this.ad1Form.patchValue({ ad1Image: '' });
    this.ad1Form.markAsDirty();
  }

  removeAd2Image() {
    this.ad2Form.patchValue({ ad2Image: '' });
    this.ad2Form.markAsDirty();
  }

  uploadAd1Image(ev: Event) {
    const file = (ev?.target as HTMLInputElement)?.files?.[0];
    if (file && ['image/png', 'image/jpeg', 'image/jpg'].includes(file?.type)) {
      const fileSizeInMb = file.size / (this.num2 * this.num2);
      if (fileSizeInMb > this.num3) {
        this.toastr.error('Image size should not be more than 5 MB.');
        return;
      }
      this.imageUploading = true;
      this.adService.uploadFile(file).then(res => {
        this.ad1Form.patchValue({ ad1Image: res });
        this.imageUploading = false;
      }).catch(() => {
        this.toastr.error('Oops something went wrong.');
        this.imageUploading = false;
      });
    } else {
      this.toastr.error('Only jpeg and png images are allowed.');
    }
  }

  uploadAd2Image(ev: Event) {
    const file = (ev?.target as HTMLInputElement)?.files?.[0];
    if (file && ['image/png', 'image/jpeg', 'image/jpg'].includes(file?.type)) {
      const fileSizeInMb = file.size / (this.num2 * this.num2);
      if (fileSizeInMb > this.num3) {
        this.toastr.error('Image size should not be more than 5 MB.');
        return;
      }
      this.imageUploading = true;
      this.adService.uploadFile(file).then(res => {
        this.ad2Form.patchValue({ ad2Image: res });
        this.imageUploading = false;
      }).catch(() => {
        this.toastr.error('Oops something went wrong.');
        this.imageUploading = false;
      });
    } else {
      this.toastr.error('Only jpeg and png images are allowed.');
    }
  }





  getAdData() {
    this.adService.getAdData().subscribe((res: any) => {
      if (res && !res.errors) {
        this.adDetails = res.data;
        if(this.adDetails[0]){
        if(this.adDetails[0]?.status === 1){
        this.showAd1 = true;
        } else{
          this.showAd1 = false;
        }
      }
      if(this.adDetails[1]){
        if(this.adDetails[1]?.status === 1){
          this.showAd2 = true;
          } else{
            this.showAd2 = false;
          }
        }
      }
    });
  }

  changeStatusForAd(id, status) {
    let adStatus;
    if (status === true) {
      adStatus = 1;
    } else{
      adStatus = 0;
    }
      this.adService.changeAdStatus(id,adStatus).subscribe((res: IResponse) => {
        this.toastr.success(res.message);
        this.getAdData();
      });
  }

  submitAd1() {
    this.ad1Form.markAllAsTouched();
    if (this.imageUploading) {
      this.toastr.warning('Warn!', 'Please wait...');
      return;
    }
    
    if (this.ad1Form.invalid) {
      this.toastr.error('All Fields are Mandatory. Please Fill all details.');
      return;
    }
    const formValue = this.ad1Form.getRawValue();
    
    const editAd1 = {

      ad_space_id: formValue.id,
      name: formValue.ad1Name,
      placement: formValue.ad1Placement,
      url: formValue.ad1Url,
      image: formValue.ad1Image
    };

    const params = 'ad_space_id=' + formValue.ad1Id + '&name=' + formValue.ad1Name + '&placement=' +
    formValue.ad1Placement + '&url=' + formValue.ad1Url + '&image=' +
    formValue.ad1Image;

      this.adService.editAdSpace(params).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toastr.success(res.message);
          this.ngOnInit();
        }
      });
    }
  

    submitAd2() {
      this.ad2Form.markAllAsTouched();
      if (this.imageUploading) {
        this.toastr.warning('Warn!', 'Please wait...');
        return;
      }
      
      if (this.ad2Form.invalid) {
        this.toastr.error('All Fields are Mandatory. Please Fill all details.');
        return;
      }
      const formValue = this.ad2Form.getRawValue();
      
      const editAd2 = {
  
        ad_space_id: formValue.id,
        name: formValue.ad1Name,
        placement: formValue.ad1Placement,
        url: formValue.ad1Url,
        image: formValue.ad1Image
      };
  
      const params = 'ad_space_id=' + formValue.ad2Id + '&name=' + formValue.ad2Name + '&placement=' +
      formValue.ad2Placement + '&url=' + formValue.ad2Url + '&image=' +
      formValue.ad2Image;
  
        this.adService.editAdSpace(params).subscribe((res: IResponse) => {
          if (res && !res.errors) {
            this.toastr.success(res.message);
            this.ngOnInit();
          }
        });
      }


}
