import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../app-interface';
import { MagicNumber } from '../../app-magic-string';
import { AgentManagementService } from '../../services/agent-management.service';

@Component({
  selector: 'app-agent-add-profile',
  templateUrl: './agent-add-profile.component.html'
})
export class AgentAddProfileComponent implements OnInit {
  public showPass = false;
  public addProfile: FormGroup;
  public data;
  public addEvent = new EventEmitter();
  showCountryCode = false;
  constructor(public formBuilder: FormBuilder, public modalRef: BsModalRef, public agentService: AgentManagementService, public toaster: ToastrService) {
    this.addProfile = this.formBuilder.group({
      fullName: ['', [Validators.required, Validators.pattern(/^(?!^\d+$)[a-zA-Z0-9\s]*[a-zA-Z][a-zA-Z0-9\s]*$/)]],
      emailId: ['', [Validators.required, Validators.pattern(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&}{])[A-Za-z\d$@$!%*?&}{].{7,}')]],
        mobile: ['', [Validators.required, Validators.minLength(MagicNumber.ten),Validators.maxLength(MagicNumber.ten),Validators.pattern(/^[0-9]+$/)]],
      address: ['', [Validators.required,Validators.maxLength(MagicNumber.hundred)]],
      // commission: ['', [Validators.required, Validators.pattern(/^([1-9]\d|\d|100)$|^0\d$|^00\d$/)]]
      commission: ['', [Validators.required, Validators.pattern(/^(0*(\d{1,2}(\.\d+)?)|\.\d+|100(\.0+$)?)$/)]]
    });
  }

  ngOnInit(): void {
    this.addProfile.patchValue({
      fullName: this.data && this.data.name,
      emailId: this.data && this.data.email,
      password: this.data && this.data.password,
      mobile: this.data && this.data.mobile,
      address:this.data && this.data.address,
      commission: this.data && this.data.commission
    });
  }
  get fullName() { return this.addProfile.get('fullName'); }
  get emailId() { return this.addProfile.get('emailId'); }
  get password() { return this.addProfile.get('password');}
  get mobile() { return this.addProfile.get('mobile'); }
  get address() { return this.addProfile.get('address'); }
  get commission() { return this.addProfile.get('commission'); }

  /**
   * API Call for submitting the user profile
  */
  submitProfile() {
    if (this.addProfile.valid) {
      const params = '&full_name=' + this.addProfile.value.fullName + '&email=' +
      this.addProfile.value.emailId + '&password=' + this.addProfile.value.password + '&mobile=' + this.addProfile.value.mobile + '&address=' + this.addProfile.value.address + 
      '&commission=' + this.addProfile.value.commission;
      this.agentService.addAgent(params).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toaster.success(res.message);
          this.addEvent.emit({ res: 200 });
          this.modalRef.hide();
        }
      });
    } else {
      this.addProfile.markAllAsTouched();
    }
  }
}
