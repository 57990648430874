<div class="main-right-wrapper">
    <div class="dashboard-container">
        <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item breadcrumb-item-admin">
                    <a (click)="movetoList()">Information Management</a>
                </li>
                <li class="breadcrumb-item breadcrumb-item-admin active" aria-current="page">
                    {{ informationInfo && informationInfo.name }}
                </li>
            </ol>
        </nav>
    </div>

    <!--  add member admin start from here  -->

    <div class="add-member-admin">
        <div class="add-member-info padding-right-16 margin-bottom-21">
            <span class="profile-user-img-wrapper info-img-wrapper">
                <img [src]="informationInfo && informationInfo.profile_picture" alt="info-user-img" class="" />
            </span>
            <div class="row info-profile-row">
                <div class="col-3 info-des-col">
                    <div class="add-member-des">
                        <span class="member-year">{{ informationInfo && informationInfo.dob | date: "yyyy" }}-{{
                            informationInfo && informationInfo.dod | date: "yyyy"
                            }}</span>
                        <h2 class="underline-remove">
                            {{ informationInfo && informationInfo.first_name }} {{ informationInfo &&
                            informationInfo.middle_name }} {{ informationInfo && informationInfo.last_name }}
                        </h2>
                        <span class="member-name">
                            <img src="./assets/images/svg/map-pin-icon.svg" alt="map-pin-icon" />
                            {{
                            informationInfo &&
                            informationInfo.city_name &&
                            informationInfo.city_name.name
                            }},
                            {{
                            informationInfo &&
                            informationInfo.state_name &&
                            informationInfo.state_name.code
                            }}, US
                        </span>
                    </div>
                </div>
                <div class="col-4 info-date-col">
                    <div class="added-text">
                        <p>Added By</p>
                        <p>Added On</p>
                    </div>
                    <div class="info-date">
                        <p [routerLink]="['/user-management/users-detail',informationInfo?.user_id]">{{ informationInfo
                            && informationInfo.added_by }}</p>
                        <p class="info-date">
                            {{ informationInfo && informationInfo.created_at | date: "MM/dd/yyyy" }}
                        </p>
                    </div>
                </div>
                <div class="col-5 preview-btn-col">
                    <a class="text-preview" href="{{ informationInfo && informationInfo.url }}" target="blank">Preview Memorial</a>
                </div>
            </div>

            <div class="box-edit-right">
                <button *ngIf="informationInfo?.status == 0" (click)="deactiveAccount()"  class="btn button-activate sales-profile-btn"> Activate Account</button>
                <button *ngIf="informationInfo?.status == 1" (click)="deactiveAccount()" class="btn button-deactivate sales-profile-btn"> Deactivate Account</button>
            </div>
        </div>
        <div class="deactivate-user" *ngIf="informationInfo?.status == 0">
            <p>
                <span>Deactivated User</span>(Reason: {{ informationInfo?.deactivated_reason }})
            </p>
        </div>
        <div class="bottom-margin-wrapper">

        </div>
        <app-information-details [informationData]="informationInfo" [isEdit]="true"></app-information-details>
    </div>