<!-- Generate QR Memory code Manually -->
<div class="modal-dialog modal-dialog-centered max-width-607">
    <div class="modal-content">
        <div class="modal-header">
            <button (click)="onClosed()" type="button" class="btn-close" data-bs-dismiss="modal"
                aria-label="Close"></button>
        </div>
        <div class="modal-body pb-8">
            <h1 class="modal-title">Change Status</h1>
            <form class="modal-form edit-about-form">
                <div class="select-custom-view">
                    <ng-select
                    placeholder="{{selectedOption ? '' : 'Select Status'}}"
                    class="select-custom"
                    [clearable]="false"
                    [searchable]="false"
                    [(ngModel)]="selectedOption"
                    [ngModelOptions]="{standalone: true}"
                    (change)="selectOption($event)">
                    <ng-option
                      id="select-items"
                      *ngFor="let option of statusOptions"
                      [value]="option.name">
                      {{ option?.name | titlecase }}
                    </ng-option>
                  </ng-select>
                </div>

                <div>
                    <button (click)="assignShipping()" type="submit"
                        class="btn primary unassign-qr-btn w-100">Update</button>
                </div>
            </form>
        </div>
    </div>
</div>
