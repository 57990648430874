import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../app-interface';
import { MagicNumber } from '../../app-magic-string';
import { UserManagementService } from '../../services/user-management.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html'
})
export class EditProfileComponent implements OnInit {
  public editProfile: FormGroup;
  public data;
  public editEvent = new EventEmitter();
  showCountryCode = true;
  constructor(public formBuilder: FormBuilder, public modalRef: BsModalRef, public userService: UserManagementService, public toaster: ToastrService) {
    this.editProfile = this.formBuilder.group({
      fullName: ['', [Validators.required, Validators.pattern(/^([a-zA-Z0-9 _-]+)$/),Validators.pattern(/^(?!\s)[\w\s-]*$/)]],
      emailId: ['', [Validators.required, Validators.pattern(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      contactNumber: ['', [Validators.required, Validators.minLength(MagicNumber.ten), Validators.maxLength(MagicNumber.ten)]],
    });
  }

  ngOnInit(): void {
    this.editProfile.patchValue({
      fullName: this.data && this.data.name,
      emailId: this.data && this.data.email,
      contactNumber: this.data && this.data.contact_number,
    });
  }
  get fullName() { return this.editProfile.get('fullName'); }
  get emailId() { return this.editProfile.get('emailId'); }
  get contactNumber() { return this.editProfile.get('contactNumber'); }

  /**
   * API Call for submitting the user profile
  */
  submitProfile() {
    if (this.editProfile.valid) {
      const params = 'id=' + this.data.id + '&full_name=' + this.editProfile.value.fullName + '&email=' +
        this.editProfile.value.emailId + '&contact_number=' + this.editProfile.value.contactNumber + '&contact_id=' + this.data.contact_id;
      this.userService.updateProfile(params).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toaster.success(res.message);
          this.editEvent.emit({ res: 200 });
          this.modalRef.hide();
        }
      });
    } else {
      this.editProfile.markAllAsTouched();
    }
  }
}
