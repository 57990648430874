// Modules
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputRestrictionDirective } from './pipe';
import { AccordionModule } from 'ngx-bootstrap/accordion';

// Services
import { NumbersonlyDirective } from './common/directives/numbers-only.service';
import { DatePipe } from '@angular/common';
import { InterceptorService } from './common/services/interceptor.service';

// Components
import { AppComponent } from './app.component';
import { SigninComponent } from './public/signin/signin.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './public/reset-password/reset-password.component';
import { ChangePasswordComponent } from './private/change-password/change-password.component';
import { UserManagementComponent } from './private/user-management/user-management.component';
import { UsersListComponent } from './private/user-management/users-list/users-list.component';
import { UserDetailComponent } from './private/user-management/user-detail/user-detail.component';
import { InformationManagementComponent } from './private/information-management/information-management.component';
import { InformationListComponent } from './private/information-management/information-list/information-list.component';
import { InformationDetailComponent } from './private/information-management/information-detail/information-detail.component';
import { PaymentManagementComponent } from './private/payment-management/payment-management.component';
import { QrcodeManagementComponent } from './private/qrcode-management/qrcode-management.component';
import { ContentManagementComponent } from './private/content-management/content-management.component';
import { CmsListComponent } from './private/content-management/cms-list/cms-list.component';
import { CmsDetailComponent } from './private/content-management/cms-detail/cms-detail.component';
import { GenerateQrcodeComponent } from './common/modal/generate-qrcode/generate-qrcode.component';
import { ViewQrcodeComponent } from './common/modal/view-qrcode/view-qrcode.component';
import { ViewInfomationComponent } from './common/modal/view-infomation/view-infomation.component';
import { DeactiveAccountComponent } from './common/modal/deactive-account/deactive-account.component';
import { EditProfileComponent } from './common/modal/edit-profile/edit-profile.component';
import { HeaderComponent } from './common/component/header/header.component';
import { SideBarComponent } from './common/component/side-bar/side-bar.component';
import { PrivateComponent } from './private/private/private.component';
import { LoaderComponent } from './common/component/loader/loader.component';
import { DataTableComponent } from './common/component/data-table/data-table.component';
import { InformationDetailsComponent } from './common/component/information-details/information-details.component';
import { AgentManagementComponent } from './private/agent-management/agent-management.component';
import { AgentListComponent } from './private/agent-management/agent-list/agent-list.component';
import { AgentDetailComponent } from './private/agent-management/agent-detail/agent-detail.component';
import { AdManagmentComponent } from './private/ad-managment/ad-managment.component';
import { AgentEditProfileComponent } from './common/modal/agent-edit-profile/agent-edit-profile.component';
import { AgentDashboardComponent } from './private/agent-dashboard/agent-dashboard.component';
import { AgentAddProfileComponent } from './common/modal/agent-add-profile/agent-add-profile.component';
import { MarkAsSoldComponent } from './common/modal/mark-as-sold/mark-as-sold.component';
import { UnassignQrcodeComponent } from './common/modal/unassign-qrcode/unassign-qrcode.component';
import { ManageQrcodeComponent } from './common/modal/manage-qrcode/manage-qrcode.component';
import { ExportSalesReportComponent } from './common/modal/export-sales-report/export-sales-report.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import { MarkAsReturnedComponent } from './common/modal/mark-as-returned/mark-as-returned.component';
import { CustomFilterComponent } from './common/modal/custom-filter/custom-filter.component';
import { ReportsComponent } from './private/reports/reports.component';
import { VendorCommissionComponent } from './private/vendor-commission/vendor-commission.component';
import { VendorListComponent } from './private/vendor-commission/vendor-list/vendor-list.component';
import { VendorDetailComponent } from './private/vendor-commission/vendor-detail/vendor-detail.component';
import { DeleteReasonComponent } from './common/modal/delete-reason/delete-reason.component';
import { AddPaymentComponent } from './common/modal/add-payment/add-payment.component';
import { ExportSalesVendorComponent } from './common/modal/export-sales-vendor/export-sales-vendor.component';
import { ShowAddressDetailComponent } from './common/modal/address-detail/show-address-detail.component';
import { FaqsComponent } from './private/faqs/faqs.component';
import { DeleteFaqComponent } from './common/modal/delete-faq/delete-faq.component';
import { AddFaqComponent } from './common/modal/add-faq/add-faq.component';
import { EditFaqComponent } from './common/modal/edit-faq/edit-faq.component';
import { WarehouseManagementComponent } from './private/warehouse-management/warehouse-management.component';
import { GuestUserManagementComponent } from './private/guest-user-management/guest-user-management.component';
import { GuestUsersListComponent } from './private/guest-user-management/guest-users-list/guest-users-list.component';
import { AssignQrAgentComponent } from './common/modal/assign-qr-agent/assign-qr-agent.component';
import { ExportGuestDataComponent } from './common/modal/export-guest-data/export-guest-data.component';
import { ExportWarehouseDataComponent } from './common/modal/export-warehouse-data/export-warehouse-data.component';
import { DeleteUserComponent } from './common/modal/delete-user/delete-user.component';
import { CreateUserPasswordComponent } from './private/create-user-password/create-user-password.component';
import { OrderManagementComponent } from './private/order-management/order-management.component';
import { ChangeShippingStatusComponent } from './common/modal/change-shipping-status/change-shipping-status.component';
import { ManageCoOwnersComponent } from './common/modal/manage-co-owners/manage-co-owners.component';
import { ExportUserDataComponent } from './common/modal/export-user-data/export-user-data.component';
import { ExportUserMemberDataComponent } from './common/modal/export-user-member-data/export-user-member-data.component';
import { ExportTransactionComponent } from './common/modal/export-transaction/export-transaction.component';
import { ExportInformationComponent } from './common/modal/export-information/export-information.component';
import { ExportOrderComponent } from './common/modal/export-order/export-order.component';
import { ExportQrCodeComponent } from './common/modal/export-qr-code/export-qr-code.component';


@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    CreateUserPasswordComponent,
    UserManagementComponent,
    UsersListComponent,
    UserDetailComponent,
    GuestUserManagementComponent,
    GuestUsersListComponent,
    InformationManagementComponent,
    InformationListComponent,
    InformationDetailComponent,
    PaymentManagementComponent,
    QrcodeManagementComponent,
    ContentManagementComponent,
    CmsListComponent,
    CmsDetailComponent,
    GenerateQrcodeComponent,
    ViewQrcodeComponent,
    ViewInfomationComponent,
    DeactiveAccountComponent,
    EditProfileComponent,
    HeaderComponent,
    SideBarComponent,
    PrivateComponent,
    LoaderComponent,
    DataTableComponent,
    InformationDetailsComponent,
    NumbersonlyDirective,
    AgentManagementComponent,
    AgentListComponent,
    AgentDetailComponent,
    AdManagmentComponent,
    AgentEditProfileComponent,
    AgentDashboardComponent,
    AgentAddProfileComponent,
    MarkAsSoldComponent,
    UnassignQrcodeComponent,
    ManageQrcodeComponent,
    ChangeShippingStatusComponent,
    AssignQrAgentComponent,
    ExportSalesReportComponent,
    ExportGuestDataComponent,
    ExportUserDataComponent,
    ExportUserMemberDataComponent,
    ExportTransactionComponent,
    ExportInformationComponent,
    ExportOrderComponent,
    ExportWarehouseDataComponent,
    ExportQrCodeComponent,
    MarkAsReturnedComponent,
    CustomFilterComponent,
    InputRestrictionDirective,
    ReportsComponent,
    VendorCommissionComponent,
    VendorListComponent,
    VendorDetailComponent,
    DeleteReasonComponent,
    AddFaqComponent,
    EditFaqComponent,
    DeleteFaqComponent,
    DeleteUserComponent,
    AddPaymentComponent,
    ExportSalesVendorComponent,
    ShowAddressDetailComponent,
    FaqsComponent,
    WarehouseManagementComponent,
    OrderManagementComponent,
    ManageCoOwnersComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-center',
      preventDuplicates: true
    }),
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    EditorModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    NgxPaginationModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HttpClientModule,
    MatNativeDateModule,
    AccordionModule.forRoot()
  ],
  providers: [
    BsModalService,
    BsModalRef,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
  ],
  exports: [MatDatepickerModule,MatNativeDateModule],
  entryComponents: [ViewQrcodeComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
