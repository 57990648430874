export const AGENT_DSHBRD_COLS = [
    {
        headerName: 'QR Memory Code',
        fieldName: 'serial_number',
        // nestedField:null,
        fieldType: 'TEXT',
        sortOrder:true,
        orderby:'serial_number'
    },
    {
        headerName: 'Box Number',
        fieldName: 'box_no',
        nestedField:null,
        fieldType: 'TEXT',
        sortOrder:false
    },
    {
        headerName: 'Issued To',
        fieldName: 'name',
        // nestedField:'name',
        fieldType: 'TEXT',
        sortOrder:true,
        orderby:'name'
    },
    // {
    //     headerName: 'Sold On',
    //     fieldName: 'sold_on',
    //     // nestedField:'created_at',
    //     fieldType: 'DATE_OBJ',
    //     sortOrder:true,
    //     orderby:'sold_on'
    // },
    {
        headerName: 'Issued On',
        fieldName: 'sold_on',
        // nestedField:null,
        fieldType: 'DATE',
        sortOrder:true,
        orderby:'sold_on'
    },
    {
        headerName: 'Active Package',
        fieldName: 'active_package',
        fieldType: 'TEXT',
        sortOrder:true,
        orderby:'active_package'
    },
    {
        headerName: 'Status',
        fieldName: 'qr_status',
        fieldType: 'STATUSSOLD',
        sortOrder: true
    },
    {
        headerName: 'Action',
        fieldName: 'TEXT',
        fieldType: 'SOLD/UNSOLD',
    },
];
