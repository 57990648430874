<div class="modal-dialog modal-dialog-centered max-width-605">
    <div class="modal-content">
        <div class="modal-header">
            <div class="modal-header-wrapper"><img src="./assets/images/svg/header-infinity-white.svg"
                    alt="header-infinity.svg" class="modal-header-img" /></div>
            <button type="button" class="btn-close" (click)="modalRef.hide()" data-bs-dismiss="modal"
                aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <h1 class="modal-title">Please give a reason for Deactivating</h1>
            <form [formGroup]="deactiveAccountForm" class="modal-form" (ngSubmit)="submitReason()">
                <div class="textarea-message mb-20">
                    <div class="form-floating textarea-after"
                        [ngClass]="reason?.invalid && (reason?.dirty || reason?.touched)?'invalid-input':''">
                        <textarea class="form-control" (focus)="reasonLabel = !reasonLabel"
                            placeholder="Leave a comment here" id="floatingTextarea2" style="height: 162px"
                            maxlength="250" formControlName="reason">
                    </textarea>
                        <label for="floatingTextarea2">Enter here</label>
                        <ng-container *ngIf="reason?.invalid && (reason?.dirty || reason?.touched)">
                            <span class="invalid-input-text-err" *ngIf="reason?.errors?.required">Reason is
                                required</span>
                            <span class="invalid-input-text-err" *ngIf="reason?.errors?.pattern">Reason is
                                invalid</span>
                        </ng-container>
                    </div>
                </div>
                <button type="submit" class="btn primary w-100">Submit</button>
            </form>
        </div>
        <div class="modal-footer">
            <div class="modal-footer-wrapper">
                <img src="./assets/images/svg/footer-heart-white.svg" alt="footer-heart" class="modal-footer-img" />
            </div>
        </div>
    </div>
</div>
