import { Component, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../app-interface';
import { AgentManagementService } from '../../services/agent-management.service';
import { InformationManagementService } from '../../services/information-management.service';
import { UserManagementService } from '../../services/user-management.service';

@Component({
  selector: 'app-deactive-account',
  templateUrl: './deactive-account.component.html'
})
export class DeactiveAccountComponent {
  public deactiveAccountForm: FormGroup;
  public data;
  public deactiveEvent = new EventEmitter();
  public reasonLabel = true;
  constructor(public formBuilder: FormBuilder, public userService: UserManagementService, public agentService: AgentManagementService,
    public modalRef: BsModalRef, public toastr: ToastrService, private readonly _informationService: InformationManagementService) {
    this.deactiveAccountForm = this.formBuilder.group({
      reason: ['', [Validators.required,Validators.pattern(/^\s*\S.*$/)]]
    });
  }


  get reason() { return this.deactiveAccountForm.get('reason'); }

  /**
   * API Call for submitting the reason
  */
  submitReason() {
    if (this.deactiveAccountForm.valid) {
      const payload = {
        is_deactivate: 0,
        deactivated_reason: this.deactiveAccountForm.value.reason,
        userId: this.data.userId
      };
      const setService = this.data.type === 'user' ? this.userService : this.data.type === 'agent' ? this.agentService : this._informationService;
      setService.deactiveAccount(payload).subscribe((res: IResponse) => {
        this.toastr.success(res.message);
        this.deactiveEvent.emit({ type: this.data.type === 'user' ? 'user' : this.data.type === 'agent' ? 'agent' : 'information', res: 200 });
        this.modalRef.hide();
      });
    } else {
      this.deactiveAccountForm.markAllAsTouched();
    }
  }
}
