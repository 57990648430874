import { Component, OnInit } from '@angular/core';
import { VENDOR_COMMISSION_COLS } from '../vendor-commission.component.col.def';

@Component({
  selector: 'app-vendor-list',
  templateUrl: './vendor-list.component.html'
})
export class VendorListComponent {
  public colFields = VENDOR_COMMISSION_COLS;
  public tableData = {
    tableHeading: 'Vendor Commission', tableType: 'vendorCommission',
    containerClass: 'table-container table-container-payment', headClass: 'table-head-user',
    routerLink: '/vendor-commission/vendor-detail',
    rowClass: 'table-row-user', bodyClass: 'table-body-user', hideDownloadCsv: false, showGenerateQR: false,showAddSalesVendor: true
  };

}
