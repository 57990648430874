<!-- Generate QR Memory code Manually -->
<div class="modal-dialog modal-dialog-centered max-width-607">
    <div class="modal-content">
        <div class="modal-header">
            <button (click)="onClosed()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body pb-8">
            <h1 class="modal-title margin-bottom-12">Assign QR Memory Codes</h1>
            <form class="modal-form edit-about-form">
                    <div *ngIf="!rememberAssign" class="select-custom-view">
                        <ng-select placeholder="{{selectedOption ? '' : 'Select Agent'}}" class="select-custom mb-3" [(ngModel)]="selectedOption" [ngModelOptions]="{standalone: true}">
                          <ng-option id="select-items" *ngFor="let option of agentDetails" [value]="option.id" (click)="selectOption(option)">{{option?.name | titlecase}}</ng-option>
                       </ng-select>
                    </div>
                    <div class="qr-code-check-sec">
                        <div class="qr-code-check">
                            <div class="form-check form-check-qr">
                                <input (change)="updateCheckedOptions($event)" class="form-check-input" type="checkbox" id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                </label>
                            </div>
                        </div>
                        <div class="qr-code-check-text">Assign to EternallyMe Warehouse</div>
                    </div>
                <div>
                    <button (click)="modalRef.hide()" type="submit"  class="btn btn-outline-primary unassign-qr-btn margin-right-25">Cancel</button>
                    <button (click)="assignQrCode()" type="submit" class="btn primary unassign-qr-btn">Save</button>
                </div>
            </form>
        </div>
    </div>
</div>