<div class="left-nav-wrapper left-nav-inner-wrapper scrollbar">
    <a href="dashboard.html" class="logo-container">
        <div class="logo-box">
        </div>
    </a>
    <div class="left-nav">
        <ul>
            <li>
                <a href="user-management.html" routerLinkActive="active" routerLink="/user-management">
                    <span class="user-management-logo nav-text-logo"><img
                            src="./assets/images/svg/sidebar-user-logo.svg" width="17px"
                            alt="sidebar-user-logo" /></span>
                    <span class="nav-text ">User Management</span>
                </a>
            </li>
            <li>
                <a href="guest-user-management.html" routerLinkActive="active" routerLink="/guest-user-management">
                    <span class="user-management-logo nav-text-logo"><img src="./assets/images/svg/guest-user.svg"
                            width="17px" alt="sidebar-user-logo" /></span>
                    <span class="nav-text ">Guest User Management</span>
                </a>
            </li>
            <li>
                <a href="information-management.html" routerLinkActive="active" routerLink="/information-management">
                    <span class="user-management-logo nav-text-logo"><img
                            src="./assets/images/svg/sidebar-info-logo.svg" alt="sidebar-info-logo" /></span>

                    <span class="nav-text">Information Management</span>
                </a>
            </li>


            <li>
                <a href="content-management.html" routerLinkActive="active" routerLink="/content-management">
                    <span class="content-management-logo nav-text-logo"><img
                            src="./assets/images/svg/sidebar-content-logo.svg" alt="sidebar-content-logo" /></span>

                    <span class="nav-text">Content Management</span>
                </a>
            </li>
            <li>
                <a href="agent-management.html" routerLinkActive="active" routerLink="/ad-managment">
                    <span class="user-management-logo nav-text-logo"><img
                            src="./assets/images/svg/icon-ad-management.svg" alt="sidebar-qrcode-logo" /></span>

                    <span class="nav-text">Ad Management</span>
                </a>
            </li>

        </ul>
        <div class="sales-drop-sec" let="value">
            <a type='button' class="sales-drop-btn show" data-bs-toggle="dropdown" (click)="showDropDown =!showDropDown"
                aria-expanded="true">SALES MANAGEMENT
                <span><img class="sales-drop-icon" [ngClass]="showDropDown ? 'open':'close'"
                        src="./assets/images/svg/icon-drop-arrow-color.svg" alt="down-arrow" /></span>
            </a>
            <div *ngIf="showDropDown">
                <ul class="dropdown-menu dropdown-menu-end sales-drop-menu show">
                    <li>
                        <a href="qr-code-management.html" routerLinkActive="active" routerLink="/qrcode-management">
                            <span class="user-management-logo nav-text-logo"><img
                                    src="./assets/images/svg/sidebar-qrcode-logo.svg"
                                    alt="sidebar-qrcode-logo" /></span>

                            <span class="nav-text">QR Memory Code Management</span>
                        </a>
                    </li>
                    <li>
                        <a href="agent-management.html" routerLinkActive="active" routerLink="/agent-management">
                            <span class="user-management-logo nav-text-logo"><img
                                    src="./assets/images/svg/icon-sales-tracking.svg"
                                    alt="sidebar-qrcode-logo" /></span>

                            <span class="nav-text">Sales Tracking</span>
                        </a>
                    </li>
                    <li>
                        <a href="warehouse-management.html" routerLinkActive="active"
                            routerLink="/warehouse-management">
                            <span class="user-management-logo nav-text-logo warehouse-logo"><img
                                    src="./assets/images/svg/wharehouse.svg" alt="sidebar-qrcode-logo" /></span>

                            <span class="nav-text warehouse-text">Warehouse Management</span>
                        </a>
                    </li>
                    <li>
                        <a href="order-management.html" routerLinkActive="active" routerLink="/order-management">
                            <span class="user-management-logo nav-text-logo warehouse-logo"><img
                                    src="./assets/images/svg/order-management.svg" alt="sidebar-qrcode-logo" /></span>

                            <span class="nav-text warehouse-text">Order Management</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="sales-drop-sec mb-4" let="value">
            <a type='button' class="sales-drop-btn show" data-bs-toggle="dropdown"
                (click)="showDropDown2 =!showDropDown2" aria-expanded="true">TRANSACTION MANAGEMENT
                <span><img class="sales-drop-icon" [ngClass]="showDropDown2 ? 'open':'close'"
                        src="./assets/images/svg/icon-drop-arrow-color.svg" alt="down-arrow" /></span>
            </a>
            <div *ngIf="showDropDown2">
                <ul class="dropdown-menu dropdown-menu-end sales-drop-menu show">
                    <li>
                        <a href="reports.html" routerLinkActive="active" routerLink="/reports">
                            <span class="user-management-logo nav-text-logo"><img
                                    src="./assets/images/svg/analytics-chart.svg" alt="sidebar-qrcode-logo" /></span>
                            <span class="nav-text">Analytics & Reports</span>
                        </a>
                    </li>
                    <li>
                        <a href="payment-management.html" routerLinkActive="active" routerLink="/payment-management">
                            <span class="user-management-logo nav-text-logo"><img
                                    src="./assets/images/svg/sidebar-payment-logo.svg"
                                    alt="sidebar-payment-logo" /></span>
                            <span class="nav-text">Transactions</span>
                        </a>
                    </li>
                    <li>
                        <a href="vendor-commission.html" routerLinkActive="active" routerLink="/vendor-commission">
                            <span class="user-management-logo nav-text-logo"><img
                                    src="./assets/images/svg/vendor-commission.svg" alt="sidebar-qrcode-logo" /></span>
                            <span class="nav-text">Vendor Commission</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <ul class="faq-ul">
            <li>
                <a href="faqs.html" routerLinkActive="active" routerLink="/faqs">
                    <span class="user-management-logo nav-text-logo"><img src="./assets/images/svg/sidebar-faq-logo.svg"
                            alt="sidebar-qrcode-logo" /></span>

                    <span class="nav-text">FAQs</span>
                </a>
            </li>
        </ul>
    </div>

</div>
