<div class="main-right-wrapper">
    <div class="dashboard-container">
        <h1 class="section-title title-border-bottom">Ad Management</h1>
    </div>
    <form [formGroup]="ad1Form">
    <div class="ad-management-wrapper">
        <div class="ad-management-box">
            <div class="ad-management-top">
                <div class="ad-title-sec">
                    <div class="ad-title-sec-left">
                        <div class="title">Advertisement <span>1</span></div>
                        <div class="title-switch">
                            <label class="switchSmall">
                                <input [ngModelOptions]="{standalone: true}" [ngModel]="showAd1" [checked]="showAd1" type="checkbox" (change) = "checkAdClick1(ad1Form.controls.ad1Id.value,showAd1)">
                                <small></small>
                            </label>
                        </div>
                        <div class="ad-title-total">Total Clicks: <span>{{ad1Form.controls.ad1Click.value}}</span></div>
                    </div>
                    <div class="ad-title-sec-right">
                        <button [disabled]="ad1Form.pristine" (click)="submitAd1()" class="btn primary">Save Ad</button>
                    </div>
                </div>
            </div>
            <div class="ad-management-middle">
                    <div class="form-input-sec">
                        <div class="form-floating">
                            <input maxlength="50" type="text" class="form-control" formControlName="ad1Name" id="floatingInput"
                                placeholder="Mike Williams">
                            <label for="floatingInput">Add Name</label>
                            <ng-container *ngIf="ad1Name?.invalid && (ad1Name?.dirty || ad1Name?.touched)">
                                <span class="invalid-input-text" *ngIf="ad1Name?.errors?.required">Ad Name is
                                    required</span>
                                    <span class="invalid-input-text" *ngIf="ad1Name?.errors?.pattern">Ad Name is
                                        invalid</span>
                            </ng-container>
                        </div>
                        <div class="form-floating state-input right-margin-0 select-top-zero">
                            <select class="form-select plan-form-select" id="floatingSelect"
                                aria-label="Floating label select example" name="planType" formControlName="ad1Placement">
                                <option value="1">Top</option>
                                <option value="2">Bottom</option>
                            </select>
                            <label for="floatingSelect" class="plan-type-label">Placement</label>
                            <ng-container *ngIf="ad1Placement?.invalid && (ad1Placement?.dirty || ad1Placement?.touched)">
                                <span class="invalid-input-text" *ngIf="ad1Placement?.errors?.required">Placement is
                                    required</span>
                            </ng-container>
                        </div>
                        <div class="form-floating">
                            <input type="text" class="form-control" formControlName="ad1Url" id="floatingInput"
                                placeholder="Mike Williams">
                            <label for="floatingInput">Add URL</label>
                            <ng-container *ngIf="ad1Url?.invalid && (ad1Url?.dirty || ad1Url?.touched)">
                                <span class="invalid-input-text" *ngIf="ad1Url?.errors?.required">Url is
                                    required</span>
                                    <span class="invalid-input-text" *ngIf="ad1Url?.errors?.pattern">Url is
                                        invalid</span>
                            </ng-container>
                        </div>
                    </div>
            </div>
            <div *ngIf="!ad1Form.get('ad1Image')?.value as imageUrl" class="ad-management-bottom">
                <input (change)="uploadAd1Image($event)" accept="image/png, image/jpeg, image/jpg" type="file" class="form-control upload-banner-input">
                <div class="upload-banner-sec">
                    <div class="upload-title">Upload banner image</div>
                    <div class="upload-subtitle">Upload or Drag & Drop here</div>
                    <div class="upload-subtitle">(JPEG, PNG, Max 5 MB, Image aspect ratio must be 49:6)</div>
                    <p class="detail">
                        <span *ngIf="ad1Form.get('ad1Image')?.touched && ad1Form.get('ad1Image')?.invalid" class="invalid-feedback">Field is required.</span>
                      </p>
                </div>
            </div>
            <div *ngIf="ad1Form.get('ad1Image')?.value as imageUrl" class="after-upload-image">
                <img [attr.src]="imageUrl || 'assets/images/placeholder-default.png'" class="uploaded-image" alt="upload" />
                <a (click)="removeAd1Image()" class="remove-text">Remove <img src="./assets/images/svg/icons-close-small.svg" alt="cross" /></a>
              </div>
        </div>
    </div>
</form>

<form [formGroup]="ad2Form">
    <div class="ad-management-wrapper">
        <div class="ad-management-box">
            <div class="ad-management-top">
                <div class="ad-title-sec">
                    <div class="ad-title-sec-left">
                        <div class="title">Advertisement <span>2</span></div>
                        <div class="title-switch">
                            <label class="switchSmall">
                                <input [ngModelOptions]="{standalone: true}" [ngModel]="showAd2" [checked]="showAd2" type="checkbox" (change) = "checkAdClick2(ad2Form.controls.ad2Id.value,showAd2)">
                                <small></small>
                              </label>
                        </div>
                        <div class="ad-title-total">Total Clicks: <span>{{ad2Form.controls.ad2Click.value}}</span></div>
                    </div>
                    <div class="ad-title-sec-right">
                        <button [disabled]="ad2Form.pristine" (click)="submitAd2()" class="btn primary">Save Ad</button>
                    </div>
                </div>
            </div>
            <div class="ad-management-middle">
                    <div class="form-input-sec">
                        <div class="form-floating">
                            <input type="text" class="form-control" formControlName="ad2Name" id="floatingInput"
                                placeholder="Mike Williams" maxlength="50">
                            <label for="floatingInput">Add Name</label>
                        </div>
                        <div class="form-floating state-input right-margin-0 select-top-zero">
                            <select class="form-select plan-form-select" id="floatingSelect"
                                aria-label="Floating label select example" name="planType" formControlName="ad2Placement">
                                <option value="1">Top</option>
                                <option value="2">Bottom</option>
                            </select>
                            <label for="floatingSelect" class="plan-type-label">Placement</label>
                        </div>
                        <div class="form-floating">
                            <input type="text" class="form-control" formControlName="ad2Url" id="floatingInput"
                                placeholder="Mike Williams">
                            <label for="floatingInput">Add URL</label>
                        </div>
                    </div>
            </div>
            <div *ngIf="!ad2Form.get('ad2Image')?.value as imageUrl" class="ad-management-bottom">
                <input (change)="uploadAd2Image($event)" accept="image/png, image/jpeg, image/jpg" type="file" class="form-control upload-banner-input">
                <div class="upload-banner-sec">
                    <div class="upload-title">Upload banner image</div>
                    <div class="upload-subtitle">Drag & Drop your image or <a href="#">click here</a> to select</div>
                    <div class="upload-subtitle">(JPEG, PNG, Max 5 MB, Image aspect ratio must be 49:6)</div>
                    <p class="detail">
                        <span *ngIf="ad1Form.get('ad2Image')?.touched && ad1Form.get('ad2Image')?.invalid" class="invalid-feedback">Field is required.</span>
                      </p>
                </div>
            </div>
            <div *ngIf="ad2Form.get('ad2Image')?.value as imageUrl" class="after-upload-image">
                <img [attr.src]="imageUrl || 'assets/images/placeholder-default.png'" class="uploaded-image" alt="upload" />
                <a (click)="removeAd2Image()" class="remove-text">Remove <img src="./assets/images/svg/icons-close-small.svg" alt="cross" /></a>
              </div>
        </div>
    </div>
</form>
</div>
<div class="qd-management-table-sec">
    <app-data-table [colFields]="colFields" [tableData]="tableData"></app-data-table>
</div>
