<div class="header">
    <span class="header-app-logo"><img src="./assets/images/svg/admin-header-logo.svg" alt="admin-header-logo" />
    </span>
    <div class="btn-group user-btn-group ms-auto">
        <span><img src="./assets/images/svg/user-logo.svg" alt="user-logo" /></span>
        <button type="button" class="btn btn-secondary dropdown-toggle dropdown-bg-none" data-bs-toggle="dropdown"
            aria-expanded="false">
            {{userData && userData?.user?.email}}
            <span><img src="./assets/images/svg/down-arrow1.svg" alt="down-arrow" /></span>
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
            <li><button class="dropdown-item" type="button" (click)="openChangePassword()">Change Password</button></li>
            <li><button class="dropdown-item" type="button" (click)="logout()">Log Out</button></li>
        </ul>
    </div>
</div>
