<div class="main-right-wrapper">
    <div class="dashboard-container">
        <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item breadcrumb-item-admin"><a routerLink="/content-management">Content
                        Management</a></li>
                <li class="breadcrumb-item breadcrumb-item-admin active" aria-current="page">{{cmsName}}
                </li>
            </ol>
        </nav>
    </div>
    <!--  text-editor start from here  -->
    <div class="edit-about-form">
        <form [formGroup]="cmsForm" (ngSubmit)="saveChanges()">
            <div class="text-editor height-500">
                <div class="text-editor-header">
                    <editor formControlName="content" [apiKey]="tinyMceKey" #tinymce id="editor custom-editor" [init]="{
                    height: 500,
                    menubar: false,
                    max_chars: 300,
                    branding:false,
                    statusbar:false,
                    plugins: 'lists',
                    placeholder: 'Type here...',
                    toolbar:
                      'undo redo | formatselect | bold italic backcolor | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat | help '
                  }"></editor>
                </div>
                <ng-container *ngIf="(isSubmitted && content?.invalid) && (content?.dirty || content?.touched)">
                    <span class="invalid-input-text" *ngIf="content?.errors?.required">Description is required</span>
                    <span class="invalid-input-text" *ngIf="content?.errors?.maxlength">Max length limit reached</span>

                </ng-container>

            </div>
            <div class="file-input-wrapper bottom-margin" *ngIf="page=='about-us'">
                <!-- file input section disable -->
                <span class="file-input-heading">Add Images</span>
                <div class="add-picture-row" *ngIf="picturesArr?.length">
                    <div class="add-picture-col" *ngFor="let picture of picturesArr;let i=index">
                        <div class="custom-file-input ">
                            <span>Add picture</span>
                            <input class="form-control" (change)="fileSelected($event,i)" type="file" id="formFile"
                                accept="image/png, image/jpeg /image/jpg image/PNG, image/JPEG, /image/JPG " />
                            <button type="button" *ngIf="picture" class="btn btn-link delete-img-btn "
                                (click)="deleteImage(i)"><img src="assets/images/svg/delete-img-icon.svg"
                                    alt="delete-img "></button>
                            <div>
                                <img [src]="picture" *ngIf="picture" alt="about-us-img" class="add-pic-about" />
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div class="edit-about-btn">
                <button type="button" class="btn btn-outline-primary margin-right-25"
                    routerLink="/content-management">Cancel</button>
                <button type="submit" class="btn primary">Save Changes</button>
            </div>
        </form>
    </div>

</div>