import { Component } from '@angular/core';
import { PAYMENT_MNGMNT_COLS } from './payment-management.component.col.def';

@Component({
  selector: 'app-payment-management',
  templateUrl: './payment-management.component.html'
})
export class PaymentManagementComponent {
  public colFields = PAYMENT_MNGMNT_COLS;
  public tableData = {
    tableHeading: 'Transactions', tableType: 'payment',
    containerClass: 'table-container table-container-payment-transaction', headClass: 'table-head-user', showTransactionExport: true,
    rowClass: 'table-row-user', bodyClass: 'table-body-user', hideDownloadCsv: false, showGenerateQR: false
  };


}
