import { Component, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../app-interface';
import { AgentManagementService } from '../../services/agent-management.service';
import { InformationManagementService } from '../../services/information-management.service';
import { UserManagementService } from '../../services/user-management.service';
import { VendorCommissionService } from '../../services/vendor-commission.service';

@Component({
  selector: 'app-delete-reason',
  templateUrl: './delete-reason.component.html'
})
export class DeleteReasonComponent{
  public deletePaymentForm: FormGroup;
  public data;
  public deactiveEvent = new EventEmitter();
  public reasonLabel = true;
  constructor(
    public formBuilder: FormBuilder, 
    public userService: UserManagementService, 
    public agentService: AgentManagementService,
    public modalRef: BsModalRef, 
    public toastr: ToastrService, 
    readonly _informationService: InformationManagementService,
    public vendorCommissionService : VendorCommissionService
    ) {
    this.deletePaymentForm = this.formBuilder.group({
      reason: ['', [Validators.required,Validators.pattern(/^\s*\S.*$/)]]
    });
  }


  get reason() { return this.deletePaymentForm.get('reason'); }

  /**
   * API Call for submitting the reason
  */
  submitReason() {
    if (this.deletePaymentForm.valid) {
      const payloads = `id=${this.data.id}&reason=${this.deletePaymentForm.value.reason}`;
      this.vendorCommissionService.deletePayment(payloads).subscribe((res: IResponse) => {
        this.toastr.success(res.message);
        this.deactiveEvent.emit({ type: true });
        this.modalRef.hide();
      });
    } else {
      this.deletePaymentForm.markAllAsTouched();
    }
  }

}
