import { Component, OnInit } from '@angular/core';
import { VENDOR_COMMISSION_COLS } from './vendor-commission.component.col.def';

@Component({
  selector: 'app-vendor-commission',
  templateUrl: './vendor-commission.component.html'
})
export class VendorCommissionComponent {
  

}
