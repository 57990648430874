import { Component, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../app-interface';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-delete-faq',
  templateUrl: './delete-faq.component.html'
})
export class DeleteFaqComponent{
  public deletePaymentForm: FormGroup;
  public data;
  public deleteFaqPoint = new EventEmitter();
  public reasonLabel = true;
  constructor(
    public formBuilder: FormBuilder, 
    public modalRef: BsModalRef, 
    public toastr: ToastrService, 
    public utilityService: UtilityService
    ) {
  }


  confirmDelete() {
      const payloads = `id=${this.data.id}`;
      this.utilityService.deleteFaq(payloads).subscribe((res: IResponse) => {
        this.toastr.success(res.message);
        this.deleteFaqPoint.emit({ type: true });
        this.modalRef.hide();
      });
    
  }

}
