import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MagicNumber } from '../../app-magic-string';
import { QrcodeManagementService } from '../../services/qrcode-management.service';

@Component({
  selector: 'app-generate-qrcode',
  templateUrl: './generate-qrcode.component.html',
  styleUrls: ['./generate-qrcode.component.scss']
})
export class GenerateQrcodeComponent implements OnInit {
  public generateQrCodeForm: FormGroup;
  public QRCodes = [MagicNumber.ten, MagicNumber.fifty, MagicNumber.hundred, MagicNumber.twoHundred, MagicNumber.fiveHundred];
  public selectedIndex: number = MagicNumber.zero;
  @Output() onClose = new EventEmitter();
  agentDetails;
  dropdownOpen = false;
  selectedOption: any;
  selectAgent = false;
  activeToggle: boolean;
  rememberAssign = false;

  constructor(public formBuilder: FormBuilder, public modalService: BsModalService, public qrcodeService : QrcodeManagementService) {
    this.generateQrCodeForm = this.formBuilder.group({
      qrCodeType: ['quick'],
      qrCodeNumber: [null, [Validators.required]],
      agentID: [''],
      rememberAssign: ['']
    });
  }


  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
    this.activeToggle = true;
  }

  selectOption(option: string) {
    this.selectedOption = option['name'];
    this.generateQrCodeForm.get('agentID').setValue(option['id']);
    this.dropdownOpen = false;
    this.selectAgent = true;
    this.activeToggle = false;
  }

  ngOnInit(): void {
    this.getAgent();
  }

  getAgent() {
    this.qrcodeService.getAgentList().subscribe((res) => {
      this.agentDetails = res['data'];
    });
  }





  /**
   * form param return
  */

  get qrCodeType() { return this.generateQrCodeForm.get('qrCodeType'); }

  get qrCodeNumber() { return this.generateQrCodeForm.get('qrCodeNumber'); }



  /**
   * On generate QR code form submit
  */
  generateQrCode() {
    if(this.selectedOption){
    this.generateQrCodeForm.get('agentID').setValue(this.selectedOption);
    }
    if (this.generateQrCodeForm.value.qrCodeType === 'quick' && this.generateQrCodeForm.value.qrCodeNumber === null) {
      this.generateQrCodeForm.controls.qrCodeNumber.setValue(MagicNumber.ten);
    }
    if (this.generateQrCodeForm.valid) {
      const data = {
        close: true,
        generateQrCodeForm: this.generateQrCodeForm.value
      };
      this.onClose.next(data);
      this.onClosed();
    } else {
      this.generateQrCodeForm.markAllAsTouched();
    }
  }

  updateCheckedOptions(event) {
    this.rememberAssign = event.target.checked;
    if(this.rememberAssign === true){
    this.generateQrCodeForm.get('rememberAssign').setValue(1);
    } else{
    this.generateQrCodeForm.get('rememberAssign').setValue(0);
    }
  }

  /**
   * close modal
  */
  onClosed() {
    this.modalService.hide();
  }


  /**
   * Activate Selected QR Code
   * @param index is an selected index
   * @param value is selected Item
  */
  setIndex(index: number, value: number) {
    this.selectedIndex = index;
    this.generateQrCodeForm.controls.qrCodeNumber.setValue(value);
  }


  /**
   * Change event type
   * @param event is an action
  */
  onTypeChange(event) {
    if (event.target.defaultValue === 'manual') {
      this.generateQrCodeForm.controls.qrCodeNumber.setValue(null);
    }
  }
}
