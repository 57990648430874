import { Component, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../app-interface';
import { UserManagementService } from '../../services/user-management.service';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html'
})
export class DeleteUserComponent{
  public deletePaymentForm: FormGroup;
  public data;
  public deleteUser = new EventEmitter();
  public reasonLabel = true;
  constructor(
    public formBuilder: FormBuilder, 
    public userService: UserManagementService, 
    public modalRef: BsModalRef, 
    public toastr: ToastrService,
    ) {
  }


  confirmDelete() {
      this.userService.deleteUser(this.data.id).subscribe((res: IResponse) => {
        this.toastr.success(res.message);
        this.deleteUser.emit({ type: true });
        this.modalRef.hide();
      });
  }
}
