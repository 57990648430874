<div class="owner-modal-header">
    <button type="button" class="btn-close ms-auto" (click)="modelRef.hide()" aria-label="Close"></button>
</div>
<div class="modal-body owner-modal-body">
    <div class="modal-title">Sub-Owners</div>
    <div class="owner-list-wrap">
        <div class="table-container co-owner-table">
            <div class="table-head">
                <div class="table-row">
                    <div class="th with-sort">
                        Name
                        <span class="sort-icon-wrap">
                            <img class="sort-icon-down" (click)="getCoOwners('asc')"
                                src="./assets/images/svg/sort-icon-down.svg" alt="icon-sort" />
                            <img class="sort-icon-up" (click)="getCoOwners('desc')"
                                src="./assets/images/svg/sort-icon-up.svg" alt="icon-sort" />

                        </span>

                    </div>
                    <div class="th">Email</div>
                    <div class="th">Phone Number</div>
                    <div class="th">Type</div>
                </div>
            </div>
            <!-- table-head end here -->
            <div class="table-body">
                <!-- repeat this div -->
                <div class="table-row" *ngFor="let data of subOwnerData?.data; let i=index">
                    <div class="td">
                        <span class="profile-img-wrap">
                            <img [src]="data?.profile_picture || 'assets/images/svg/user-logo.svg'" alt="profile img"
                                class="profile-img" />
                        </span>
                        <span class="ellipsis-text">
                            {{data?.name | titlecase}}

                        </span>
                    </div>
                    <div class="td"><span class="ellipsis-text">{{data?.email}}</span> </div>
                    <div class="td"><span class="ellipsis-text">{{data?.contact_number}}</span> </div>
                    <div class="td" *ngIf="data?.account_type === 3">Funeral Home</div>
                    <div class="td" *ngIf="data?.account_type === 2">Co-Owner</div>
                    <div class="td" *ngIf="data?.account_type === 1">Owner</div>


                </div>
            </div>
        </div>
    </div>
</div>
