import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../common/services/common.service';
import { CustomFilterComponent } from '../../common/modal/custom-filter/custom-filter.component';
import { MagicNumber } from '../../common/app-magic-string';
import { ReportsService } from '../../common/services/reports.service';

declare const google;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  yearRange: any;
  monthRange: any;
  dayRange: any;
  customRange: any;
  customResponse: any;
  wrongText: any = 'Something went wrong.';
  filterDaysText: any = 'Current Month';
  filterMonthsText: any = 'Current Year';
  filterYearsText: any = 'Total';
  filterCustomText: any = 'Custom';
  dateRangeRevenue = {};
  dateRangeCommission = {};
  dateRangeOverview = {};
  typeRevenue = this.filterDaysText;
  typeOverview = this.filterDaysText;
  typeCommission = this.filterDaysText;
  dashboardDetails: any;
  commissionDetails: any;
  cssClass1 = 'max-width-605 modal-dialog-centered';
  params: any;
  filterStatus = this.filterDaysText;
  filterOld = this.filterDaysText;
  customFilter: FormGroup;
  days = [];
  months = [];
  years = [];
  commissionPaid: any;
  totalCommission: any;
  totalBasicPlan: any;
  totalStandardPlan: any;
  totalUpgradedPlan: any;
  totalBasicPlanRevenue: any;
  totalStandardPlanRevenue: any;
  totalUpgradedPlanRevenue: any;

  constructor(
    public modalService: BsModalService,
    public formBuilder: FormBuilder,
    public activateRoute: ActivatedRoute,
    public reportService: ReportsService,
    public toastr: ToastrService,
    public modelref: BsModalRef,
    public commonService: CommonService
  ) {
    this.yearRange = 'total';
    this.monthRange = 'yearly';
    this.dayRange = 'monthly';
    this.customRange = 'custom';
  }

  ngOnInit(): void {
    this.days = this.getCurrenDays();
    this.months = this.getCurrentMonths();
    this.years = this.getCurrentYears();

    this.getOverviewGraph('DAYS');
    this.getCommissionGraph('DAYS');
    this.getRevenueGraph('DAYS');

    this.customFilter = this.formBuilder.group({
      start_date: [
        new Date().toISOString().slice(MagicNumber.zero, MagicNumber.eight) +
          '01',
        [Validators.required],
      ],
      end_date: [
        new Date().toISOString().slice(MagicNumber.zero, MagicNumber.ten),
        [Validators.required],
      ],
    });
    this.getCommission('monthly');
    this.commonService.commissionData.subscribe((res) => {
      this.commissionDetails = res?.data;
      res?.status ? (this.filterStatus = 'Custom') : '';
    });
    this.commonService.commissionDataStatus.subscribe((res) => {
      res?.status ? (this.filterStatus = this.filterOld) : '';
    });
  }

  getCurrenDays() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);
    const timezoneOffset =
      MagicNumber.fourThirty * MagicNumber.sixty * MagicNumber.thousand; // Offset in milliseconds for +05:30 timezone
    const dates = [];

    for (
      let date = startDate;
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      const timezoneDate = new Date(date.getTime() + timezoneOffset);
      const formattedDate = timezoneDate.toISOString().split('T')[0];
      dates.push(formattedDate);
    }

    return dates;
  }

  getCurrentMonths() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const months = [];

    for (let month = 0; month < MagicNumber.twelve; month++) {
      const date = new Date(year, month, 1);
      const monthName = date
        .toLocaleString('default', { month: 'short' })
        .toUpperCase();
      months.push(monthName);
    }

    return months;
  }

  getCurrentYears() {
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let i = 9; i >= 0; i--) {
      const year = currentYear - i;
      years.push(year.toString());
    }

    return years;
  }

  getRevenueGraph(dateRange, xAxis?, sameYear?) {
    const ten = 10;

    if (dateRange === 'YEARS') {
      this.dateRangeRevenue = this.yearRange;
      xAxis = this.years;
      this.typeRevenue = this.filterYearsText;
    }

    if (dateRange === 'DAYS') {
      this.dateRangeRevenue = this.dayRange;
      xAxis = this.days;
      this.typeRevenue = this.filterDaysText;
    }

    if (dateRange === 'MONTHS') {
      this.dateRangeRevenue = this.monthRange;
      xAxis = this.months;
      this.typeRevenue = this.filterMonthsText;
    }

    if (dateRange === 'CUSTOM') {
      this.dateRangeRevenue = this.customRange;
      this.typeRevenue = this.filterCustomText;
    }

    let params;

    if (this.dateRangeRevenue === 'custom') {
      params = this.customResponse.params;
    } else {
      params = `&type=${this.dateRangeRevenue}`;
    }

    this.reportService.getRevenueData(params).subscribe(
      (res: any) => {
        const revenueData = [];
        let graphMaxValue = 0;
        this.totalBasicPlanRevenue = res.total_basic_plan;
        this.totalStandardPlanRevenue = res.total_standard_plan;
        this.totalUpgradedPlanRevenue = res.total_upgraded_plan;
        const RendomMonthData = res.data;

        if (this.dateRangeRevenue === 'custom') {
          const array = [];
          if (xAxis <= MagicNumber.thirtyOne) {
            for (let i = 0; i < RendomMonthData.length; i++) {
              const element = RendomMonthData[i].date; // Example element value, replace it with your own value
              array.push(element);
              xAxis = array;
            }
          } else if (
            xAxis > MagicNumber.thirtyOne &&
            xAxis <= MagicNumber.threeSixtyFive &&
            sameYear === true
          ) {
            for (let i = 0; i < RendomMonthData.length; i++) {
              const element = RendomMonthData[i].month_name;
              array.push(element);
              xAxis = array;
            }
          } else {
            for (let i = 0; i < RendomMonthData.length; i++) {
              const element = RendomMonthData[i].date;
              array.push(element);
              xAxis = array;
            }
          }
        }

        xAxis.forEach((el, i) => {
          if (el.length === MagicNumber.ten) {
            let d = [];
            d = [
              el.slice(MagicNumber.minusTwo),
              parseInt(RendomMonthData[i].basic_plan_amount),
              parseInt(RendomMonthData[i].standard_plan_amount),
              parseInt(RendomMonthData[i].upgraded_plan_amount),
            ];
            revenueData.push(d);
          } else {
            let d = [];
            d = [
              el,
              parseInt(RendomMonthData[i].basic_plan_amount),
              parseInt(RendomMonthData[i].standard_plan_amount),
              parseInt(RendomMonthData[i].upgraded_plan_amount),
            ];
            revenueData.push(d);
          }

          for (const key in RendomMonthData[el]) {
            if (Math.floor(Number(RendomMonthData[el][key])) > graphMaxValue) {
              graphMaxValue = Math.floor(Number(RendomMonthData[el][key]));
            }
          }
        });
        graphMaxValue = graphMaxValue + ten;
        const column = [
          { type: 'string', columnName: 'Month' },
          { type: 'number', columnName: 'Basic Plan' },
          { type: 'number', columnName: 'Standard Plan' },
          { type: 'number', columnName: 'Upgraded Plan' },
        ];

        const lineColors = ['#885D40', '#01a5f7', '#c70869', '#38a3a5'];

        const options = {
          height: 500,
          legend: {
            position: 'none',
          },
          colors: lineColors,
          chartArea: {
            left: 100,
            width: '100%',
          },
          hAxis: {
            title: dateRange,
            titleTextStyle: {
              fontName: 'Manrope',
              color: '#123167',
              fontSize: 15,
              bold: true,
              italic: false,
            },
            textStyle: {
              color: '#898989',
              fontSize: 10,
              bold: true,
              italic: false,
            },
            baselineColor: '#a8a8a8',
            gridlines: {
              color: '#141414',
              count: 1,
            },
          },
          vAxis: {
            title: 'REVENUE ($)',
            titleTextStyle: {
              letterSpacing: '12px',
              fontName: 'Manrope',
              color: '#123167',
              fontSize: 15,
              bold: true,
              italic: false,
            },
            textStyle: {
              color: '#898989',
              fontSize: 10,
              bold: true,
              italic: false,
            },
            baseline: 0,
            baselineColor: '#a8a8a8',
            maxValue: graphMaxValue,
            gridlines: {
              count: 1,
              color: '#141414',
            },
          },
          backgroundColor: '#fff',
        };

        this.createLineGraph(revenueData, column, 'revenue-report', options);
      },
      (err) => {
        this.toastr.warning('Error!', this.wrongText);
      }
    );
  }

  getOverviewGraph(dateRange, xAxis?, sameYear?) {
    const ten = 10;

    if (dateRange === 'YEARS') {
      this.dateRangeOverview = this.yearRange;
      xAxis = this.years;
      this.typeOverview = this.filterYearsText;
    }

    if (dateRange === 'DAYS') {
      this.dateRangeOverview = this.dayRange;
      xAxis = this.days;
      this.typeOverview = this.filterDaysText;
    }

    if (dateRange === 'MONTHS') {
      this.dateRangeOverview = this.monthRange;
      xAxis = this.months;
      this.typeOverview = this.filterMonthsText;
    }

    if (dateRange === 'CUSTOM') {
      this.dateRangeOverview = this.customRange;
      this.typeOverview = this.filterCustomText;
    }

    let params;

    if (this.dateRangeOverview === 'custom') {
      params = this.customResponse.params;
    } else {
      params = `&type=${this.dateRangeOverview}`;
    }

    this.reportService.getOverviewData(params).subscribe(
      (res: any) => {
        const overviewData = [];
        let graphMaxValue = 0;
        this.totalBasicPlan = res.total_basic_plan;
        this.totalStandardPlan = res.total_standard_plan;
        this.totalUpgradedPlan = res.total_upgraded_plan;
        const RendomMonthData = res.data;

        if (this.dateRangeOverview === 'custom') {
          const array = [];
          if (xAxis <= MagicNumber.thirtyOne) {
            for (let i = 0; i < RendomMonthData.length; i++) {
              const element = RendomMonthData[i].date; // Example element value, replace it with your own value
              array.push(element);
              xAxis = array;
            }
          } else if (
            xAxis > MagicNumber.thirtyOne &&
            xAxis <= MagicNumber.threeSixtyFive &&
            sameYear === true
          ) {
            for (let i = 0; i < RendomMonthData.length; i++) {
              const element = RendomMonthData[i].month_name;
              array.push(element);
              xAxis = array;
            }
          } else {
            for (let i = 0; i < RendomMonthData.length; i++) {
              const element = RendomMonthData[i].date;
              array.push(element);
              xAxis = array;
            }
          }
        }

        xAxis.forEach((el, i) => {
          if (el.length === MagicNumber.ten) {
            let d = [];
            d = [
              el.slice(MagicNumber.minusTwo),
              parseInt(RendomMonthData[i].basic_plan_amount),
              parseInt(RendomMonthData[i].standard_plan_amount),
              parseInt(RendomMonthData[i].upgraded_plan_amount),
            ];
            overviewData.push(d);
          } else {
            let d = [];
            d = [
              el,
              parseInt(RendomMonthData[i].basic_plan_amount),
              parseInt(RendomMonthData[i].standard_plan_amount),
              parseInt(RendomMonthData[i].upgraded_plan_amount),
            ];
            overviewData.push(d);
          }

          for (const key in RendomMonthData[el]) {
            if (Math.floor(Number(RendomMonthData[el][key])) > graphMaxValue) {
              graphMaxValue = Math.floor(Number(RendomMonthData[el][key]));
            }
          }
        });
        graphMaxValue = graphMaxValue + ten;
        const column = [
          { type: 'string', columnName: 'Month' },
          { type: 'number', columnName: 'Basic Plan' },
          { type: 'number', columnName: 'Standard Plan' },
          { type: 'number', columnName: 'Upgraded Plan' },
        ];

        const lineColors = ['#885D40', '#01a5f7', '#c70869', '#38a3a5'];
        const options = {
          height: 500,
          legend: {
            position: 'none',
          },
          colors: lineColors,
          chartArea: {
            left: 100,
            width: '100%',
            bottom: '20px',
          },
          hAxis: {
            title: dateRange,
            titleTextStyle: {
              fontName: 'Manrope',
              color: '#123167',
              fontSize: 15,
              bold: true,
              italic: false,
            },
            textStyle: {
              color: '#898989',
              fontSize: 10,
              bold: true,
              italic: false,
            },
            baselineColor: '#a8a8a8',
            gridlines: {
              color: '#141414',
              count: 1,
              minSpacing: 20,
              interval: 10,
            },
          },
          vAxis: {
            title: 'NO. OF PLANS',
            titleTextStyle: {
              fontName: 'Manrope',
              color: '#123167',
              fontSize: 15,
              bold: true,
              italic: false,
            },
            baseline: 0,
            baselineColor: '#000000',
            maxValue: graphMaxValue,
            textStyle: {
              color: '#a8a8a8',
              fontSize: 10,
              bold: true,
              italic: false,
            },
            gridlines: {
              count: 1,
              color: '#141414',
            },
          },
          backgroundColor: '#fff',
        };
        this.createLineGraph(overviewData, column, 'overview-report', options);
      },
      (err) => {
        this.toastr.warning('Error!', this.wrongText);
      }
    );
  }

  getCommissionGraph(dateRange, xAxis?, sameYear?) {
    const ten = 10;
    if (dateRange === 'DAYS') {
      this.dateRangeCommission = this.dayRange;
      xAxis = this.days;
      this.typeCommission = this.filterDaysText;
    }

    if (dateRange === 'MONTHS') {
      this.dateRangeCommission = this.monthRange;
      xAxis = this.months;
      this.typeCommission = this.filterMonthsText;
    }

    if (dateRange === 'YEARS') {
      this.dateRangeCommission = this.yearRange;
      xAxis = this.years;
      this.typeCommission = this.filterYearsText;
    }

    if (dateRange === 'CUSTOM') {
      this.dateRangeCommission = this.customRange;
      this.typeCommission = this.filterCustomText;
    }

    let params;

    if (this.dateRangeCommission === 'custom') {
      params = this.customResponse.params;
    } else {
      params = `&type=${this.dateRangeCommission}`;
    }

    this.reportService.getCommissionData(params).subscribe(
      (res: any) => {
        const commissionData = [];
        let graphMaxValue = 0;
        this.totalCommission = res.total_commission;
        this.commissionPaid = res.commission_paid;
        const RendomMonthData = res.data;

        if (this.dateRangeCommission === 'custom') {
          const array = [];
          if (xAxis <= MagicNumber.thirtyOne) {
            for (let i = 0; i < RendomMonthData.length; i++) {
              const element = RendomMonthData[i].date; // Example element value, replace it with your own value
              array.push(element);
              xAxis = array;
            }
          } else if (
            xAxis > MagicNumber.thirtyOne &&
            xAxis <= MagicNumber.threeSixtyFive &&
            sameYear === true
          ) {
            for (let i = 0; i < RendomMonthData.length; i++) {
              const element = RendomMonthData[i].month_name;
              array.push(element);
              xAxis = array;
            }
          } else {
            for (let i = 0; i < RendomMonthData.length; i++) {
              const element = RendomMonthData[i].date;
              array.push(element);
              xAxis = array;
            }
          }
        }

        xAxis.forEach((el, i) => {
          if (el.length === MagicNumber.ten) {
            let d = [];
            d = [
              el.slice(MagicNumber.minusTwo),
              parseInt(RendomMonthData[i].commission_paid),
              parseInt(RendomMonthData[i].total_commission),
            ];
            commissionData.push(d);
          } else {
            let d = [];
            d = [
              el,
              parseInt(RendomMonthData[i].commission_paid),
              parseInt(RendomMonthData[i].total_commission),
            ];
            commissionData.push(d);
          }
          for (const key in RendomMonthData[i]) {
            if (RendomMonthData[i][key] > graphMaxValue) {
              graphMaxValue = RendomMonthData[i][key];
            }
          }
        });
        graphMaxValue = graphMaxValue + ten;
        const column = [
          { type: 'string', columnName: 'Month' },
          { type: 'number', columnName: 'Commission Paid' },
          { type: 'number', columnName: 'Total Commission' },
        ];

        const lineColors = ['#9ec74e', '#f3bb2a'];
        const options = {
          height: 500,
          legend: {
            position: 'none',
          },
          colors: lineColors,
          chartArea: {
            left: 100,
            width: '100%',
          },
          hAxis: {
            title: dateRange,
            titleTextStyle: {
              fontName: 'Manrope',
              color: '#123167',
              fontSize: 15,
              bold: true,
              italic: false,
            },
            textStyle: {
              color: '#898989',
              fontSize: 10,
              bold: true,
              italic: false,
            },
            baselineColor: '#000000',
            gridlines: {
              color: '#000000',
              count: 1,
            },
          },
          vAxis: {
            title: 'REVENUE ($)',
            titleTextStyle: {
              fontName: 'Manrope',
              color: '#123167',
              fontSize: 15,
              bold: true,
              italic: false,
            },
            baselineColor: '#a8a8a8',
            maxValue: graphMaxValue,
            textStyle: {
              color: '#a8a8a8',
              fontSize: 10,
              bold: true,
              italic: false,
            },
            gridlines: {
              count: 1,
              color: '#141414',
            },
          },
          backgroundColor: '#fff',
        };

        const mappedHtmlIdWithData = [
          { user: commissionData, id: 'commission-report' },
        ];

        mappedHtmlIdWithData.forEach((e) => {
          this.createLineGraph(e.user, column, 'commission-report', options);
        });
      },
      (err) => {
        this.toastr.warning('Error!', this.wrongText);
      }
    );
  }

  createLineGraph(da, column, id, options) {
    google.charts.load('current', { packages: ['corechart', 'line'] });
    google.charts.setOnLoadCallback(drawBackgroundColor);

    function drawBackgroundColor() {
      const data = new google.visualization.DataTable();
      column.forEach((col) => {
        data.addColumn(col.type, col.columnName);
      });
      data.addRows(da);

      const chart = new google.visualization.LineChart(
        document.getElementById(id)
      );
      chart.draw(data, options);
    }
  }

  getCommission(value) {
    this.params = `&type=${value}`;
    const parts = ['&type=', value];
    if (value === 'custom') {
      parts.push(
        '&start_date=',
        this.customFilter.value.start_date,
        '&end_date=',
        this.customFilter.value.end_date
      );
    }
    this.params = parts.join('');
    this.reportService.getAnalyticsData(this.params).subscribe((res: any) => {
      if (res && !res.errors) {
        this.commissionDetails = res;
        this.modelref.hide();
      }
    });
    this.customFilter.get('start_date').valueChanges.subscribe((val) => {
      this.customFilter.get('end_date').setValidators(Validators.required);
      this.customFilter.get('end_date').updateValueAndValidity();
    });
  }

  searchStatus(value: string) {
    const selectedValue = value;
    switch (selectedValue) {
      case 'custom':
        this.filterStatus = 'Custom';
        this.customFilter.patchValue({
          start_date: '',
          end_date: '',
        });
        this.customFilterModal();
        break;
      case 'month':
        this.filterStatus = this.filterDaysText;
        this.filterOld = this.filterDaysText;
        this.customFilter.patchValue({
          start_date:
            new Date()
              .toISOString()
              .slice(MagicNumber.zero, MagicNumber.eight) + '01',
          end_date: new Date()
            .toISOString()
            .slice(MagicNumber.zero, MagicNumber.ten),
        });
        this.getCommission('monthly');
        break;
      case 'year':
        this.filterStatus = this.filterMonthsText;
        this.filterOld = this.filterMonthsText;

        this.customFilter.patchValue({
          start_date: this.getCalulateStartYear(),
          end_date: this.getCalulateEndYear(),
        });
        this.getCommission('yearly');
        break;
      case 'total':
        this.filterStatus = 'Total';
        this.filterOld = 'Total';
        this.getCommission('total');
        break;
      default:
        break;
    }
  }

  customGraph(type: any) {
    this.customFilter.patchValue({
      start_date: '',
      end_date: '',
    });
    this.customFilterModals(type);
  }
  getCalulateStartYear() {
    const date = new Date();
    date.setMonth(MagicNumber.zero); // set month to December (index 11)
    date.setDate(MagicNumber.one); // set day to 31
    return date.toISOString().substr(MagicNumber.zero, MagicNumber.ten); // extract the first 10 characters (YYYY-MM-DD)
  }
  getCalulateEndYear() {
    const date = new Date();
    date.setMonth(MagicNumber.eleven); // set month to December (index 11)
    date.setDate(MagicNumber.thirtyOne); // set day to 31
    return date.toISOString().substr(MagicNumber.zero, MagicNumber.ten); // extract the first 10 characters (YYYY-MM-DD)
  }

  customFilterModal() {
    this.modelref = this.modalService.show(CustomFilterComponent, {
      class: this.cssClass1,
    });
  }

  getDuration(startDate, endDate) {
    const startTimestamp = startDate.getTime();
    const endTimestamp = endDate.getTime();
    const duration = endTimestamp - startTimestamp;

    // Convert duration to the desired unit (e.g., days, hours, minutes)
    const milliseconds = duration;
    const seconds = Math.floor(duration / MagicNumber.thousand);
    const minutes = Math.floor(
      duration / MagicNumber.thousand / MagicNumber.sixty
    );
    const hours = Math.floor(
      duration / MagicNumber.thousand / MagicNumber.sixty / MagicNumber.sixty
    );
    const days = Math.floor(
      duration /
        MagicNumber.thousand /
        MagicNumber.sixty /
        MagicNumber.sixty /
        MagicNumber.twentyFour
    );
    return { milliseconds, seconds, minutes, hours, days };
  }

  areSameYear(startDate, endDate) {
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    return startYear === endYear;
  }

  customFilterModals(type?) {
    const initialState = { data: { userType: type } };
    this.modelref = this.modalService.show(CustomFilterComponent, {
      class: this.cssClass1,
      initialState,
    });
    this.modelref.content.customEvent.subscribe((res) => {
      if (res) {
        this.customResponse = res;
        const duration = this.getDuration(
          new Date(this.customResponse.startDate),
          new Date(this.customResponse.endDate)
        );
        const sameYear = this.areSameYear(
          new Date(this.customResponse.startDate),
          new Date(this.customResponse.endDate)
        );
        if (res?.data?.userType === 'revenue') {
          this.getRevenueGraph('CUSTOM', duration.days, sameYear);
        } else if (res?.data?.userType === 'commission') {
          this.getCommissionGraph('CUSTOM', duration.days, sameYear);
        } else {
          this.getOverviewGraph('CUSTOM', duration.days, sameYear);
        }
      }
    });
  }
}
