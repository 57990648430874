export const CONTENT_MNGMNT_COLS = [
    {
        headerName: 'Section Name',
        fieldName: 'title',
        fieldType: 'TEXT',
    },
    {
        headerName: 'Description',
        fieldName: 'content',
        fieldType: 'HTML',
    },
    {
        headerName: 'Updated On',
        fieldName: 'updated_at',
        fieldType: 'DATE',
    },
    {
        headerName: 'Action',
        fieldName: '',
        fieldType: 'edit',
    },
];

