import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IResponse } from '../../common/app-interface';
import { AuthService } from '../../common/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent {
  public forgotPasswordForm: FormGroup;
  constructor(public formBuiler: FormBuilder, public router: Router, public modalService: BsModalService, public modalRef: BsModalRef, 
    public authService: AuthService, public toastr: ToastrService) {
    this.forgotPasswordForm = this.formBuiler.group({
      emailId: ['', [Validators.required, 
      Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]]
    });
  }
  
  get emailId() { return this.forgotPasswordForm.get('emailId'); }

  /**
   * Forget Password Functionality
   */
  onSendForgetPasswordMail() {
    if (this.forgotPasswordForm.valid) {
      this.authService.forgotPassword(this.forgotPasswordForm.value.emailId).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toastr.success(res.message);
        }
      });
    } else {
      this.forgotPasswordForm.markAllAsTouched();
    }
  
  }

}
