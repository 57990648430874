<!-- Change Password Modal -->
<div class="modal-header">
  <div class="modal-header-wrapper">
    <img src="./assets/images/svg/header-infinity-white.svg" alt="header-infinity" class="modal-header-img" />
  </div>
  <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="modalRef.hide()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <h1 class="modal-title">Create Password</h1>
  <form [formGroup]="changePasswordForm" (ngSubmit)="onChangePassword()" class="modal-form">
    <div class="form-floating">
      <input type="text" class="form-control" [value]="data?.email" disabled id="floatingInput">
      <label for="floatingInput">Email ID</label>
    </div>
    <div class="form-floating" [ngClass]="
            newPassword?.invalid && (newPassword?.dirty || newPassword?.touched)
              ? 'invalid-input'
              : ''
          ">
      <input [type]="newShow ? 'text' : 'password'" class="form-control" id="floatingInput"
        formControlName="newPassword" placeholder="name@example.com" maxlength="20" />
      <label for="floatingInput">New Password</label>
      <span class="hide-eye"><img src="assets/images/svg/{{
                newShow ? 'show' : 'hide'
              }}-password.svg" (click)="newShow = !newShow" alt="hide-password" />
      </span>
    </div>
    <ng-container *ngIf="
            newPassword?.invalid && (newPassword?.dirty || newPassword?.touched)
          ">
      <span class="invalid-input-text" *ngIf="newPassword?.errors?.required">New Password is required</span>
      <span class="invalid-input-text" *ngIf="newPassword?.errors?.pattern">New Password must be 8 to 20 characters,
        atleast one alpha-numeric, one special character, one uppercase and one lowercase.
      </span>
    </ng-container>




    <button type="submit" class="btn primary w-100">
      Send Email
    </button>
  </form>
</div>
<div class="modal-footer">
  <div class="modal-footer-wrapper">
    <img src="./assets/images/svg/footer-heart-white.svg" alt="footer-heart" class="modal-footer-img" />
  </div>
</div>
