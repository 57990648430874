import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { FilterData } from '../app-interface';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class DashboardAgentService {

  constructor(public http: HttpClient) { }

  getDashboardUserData() {
    return this.http.get(`${environment.apiUrl}vendor/get-qrcodes-data?`);
  }

  // getData(userId: number) {
  //   return this.http.get(`${environment.apiAdminUrl}get-all-vendors?user_id=${userId}`);
  // }

  getData(params) {
    return this.http.get(`${environment.apiAdminUrl}get-vendor-qrcodes?${params}`);
  }

  getReturn() {
    return this.http.get(`${environment.apiUrl}common/return-reasons`);
  }
  // getDatas(params) {
  //   return this.http.get(`${environment.apiAdminUrl}get-vendor-qrcodes?vendor_id=70`);
  // }

  markAsSold(params) {
    return this.http.post(`${environment.apiAdminUrl}change-sold-status/${params}`, '');
  }

  markAsReturn(params) {
    return this.http.post(`${environment.apiUrl}vendor/return-qr/${params}`, '');
  }

  customSalesFilter(params) {
    return this.http.get(`${environment.apiUrl}vendor/get-commission-amount?${params}&timezone=${moment.tz.guess()}`);
  }

}
