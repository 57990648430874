<!-- Change Password Modal -->
<div class="modal-dialog modal-dialog-centered max-width-605">
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-header-wrapper">
        <img src="./assets/images/svg/header-infinity-white.svg" alt="header-infinity.svg" class="modal-header-img" />
      </div>
      <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="modalRef.hide()"
        aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <h1 class="modal-title">Change Password</h1>
      <form [formGroup]="changePasswordForm" (ngSubmit)="onChangePassword()" class="modal-form">
        <div class="form-floating" [ngClass]="
            oldPassword?.invalid && (oldPassword?.dirty || oldPassword?.touched)
              ? 'invalid-input'
              : ''
          ">
          <input [type]="oldShow ? 'text' : 'password'" class="form-control" id="floatingInput"
            formControlName="oldPassword" placeholder="name@example.com" maxlength="20" />
          <label for="floatingInput">Old Password</label>
          <span class="hide-eye"><img src="assets/images/svg/{{
                oldShow ? 'show' : 'hide'
              }}-password.svg" (click)="oldShow = !oldShow" alt="hide-password" />
          </span>
        </div>
        <ng-container *ngIf="
            oldPassword?.invalid && (oldPassword?.dirty || oldPassword?.touched)
          ">
          <span class="invalid-input-text" *ngIf="oldPassword?.errors?.required">Old Password is required</span>
        </ng-container>
        <div class="form-floating" [ngClass]="
            newPassword?.invalid && (newPassword?.dirty || newPassword?.touched)
              ? 'invalid-input'
              : ''
          ">
          <input [type]="newShow ? 'text' : 'password'" class="form-control" id="floatingInput"
            formControlName="newPassword" placeholder="name@example.com" maxlength="20" />
          <label for="floatingInput">New Password</label>
          <span class="hide-eye"><img src="assets/images/svg/{{
                newShow ? 'show' : 'hide'
              }}-password.svg" (click)="newShow = !newShow" alt="hide-password" />
          </span>
        </div>
        <ng-container *ngIf="
            newPassword?.invalid && (newPassword?.dirty || newPassword?.touched)
          ">
          <span class="invalid-input-text" *ngIf="newPassword?.errors?.required">New Password is required</span>
          <span class="invalid-input-text" *ngIf="newPassword?.errors?.pattern">New Password must be 8 to 20 characters,
            atleast one alpha-numeric, one special character, one uppercase and one lowercase.
          </span>
        </ng-container>

        <div class="form-floating" [ngClass]="
            cnfrmPassword?.invalid &&
            (cnfrmPassword?.dirty || cnfrmPassword?.touched)
              ? 'invalid-input'
              : ''
          ">
          <input [type]="currentShow ? 'text' : 'password'" class="form-control" id="floatingInput"
            formControlName="cnfrmPassword" placeholder="name@example.com" maxlength="20" />
          <label for="floatingInput">Confirm New Password</label>
          <span class="hide-eye"><img src="assets/images/svg/{{
                currentShow ? 'show' : 'hide'
              }}-password.svg" (click)="currentShow = !currentShow" alt="show-password" />
          </span>
        </div>
        <ng-container *ngIf="
            cnfrmPassword?.invalid &&
            (cnfrmPassword?.dirty || cnfrmPassword?.touched)
          ">
          <span class="invalid-input-text" *ngIf="cnfrmPassword?.errors?.required">Confirm Password is required</span>
        </ng-container>
        <span class="invalid-input-text" *ngIf="
            (cnfrmPassword?.valid || newPassword?.valid) &&
            (cnfrmPassword?.value != newPassword?.value) && cnfrmPassword?.value.length!=0
          ">New Password and Confirm Password must match
        </span>
        <button type="submit" class="btn primary w-100">
          Change Password
        </button>
      </form>
    </div>
    <div class="modal-footer">
      <div class="modal-footer-wrapper">
        <img src="./assets/images/svg/footer-heart-white.svg" alt="footer-heart" class="modal-footer-img" />
      </div>
    </div>
  </div>
</div>
