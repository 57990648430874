import { Component } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DeactiveAccountComponent } from '../../../../app/common/modal/deactive-account/deactive-account.component';
import { AGENT_MNGMNT_COLS } from './agent-management.col.def';

@Component({
  selector: 'app-agent-list',
  templateUrl: './agent-list.component.html'
})
export class AgentListComponent {

  public colFields = AGENT_MNGMNT_COLS;
  public tableData = {
    tableHeading: 'Sales Tracking', tableType: 'agent',
    serviceName: 'agentService', routerLink: '/agent-management/agent-detail',
    containerClass: 'table-container-sales', headClass: 'table-head-user', rowClass: 'table-row-user',
   hideDownloadCsv: true, showAddSales: true, hideFilter: true
  };

  constructor(public modalService: BsModalService) {}



  /**
     * To open the deactive account profile
    */
  deactiveAccount() {
    this.modalService.show(DeactiveAccountComponent, { class: 'max-width-605 modal-dialog-centered' });
  }
}
