import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MagicNumber } from '../../app-magic-string';
import { AgentManagementService } from '../../services/agent-management.service';
import { CommonService } from '../../services/common.service';
import { VendorDetailsService } from '../../services/vendor-details.service';

@Component({
  selector: 'app-export-sales-vendor',
  templateUrl: './export-sales-vendor.component.html'
})
export class ExportSalesVendorComponent {
  exportSales : FormGroup;
  tomorrow = new Date();
  params: any;
  filteredData: any;
  constructor(
    public formBuilder: FormBuilder,
    public modalRef: BsModalRef,
    @Inject(DOCUMENT) private readonly document: Document,
    public agentService: AgentManagementService,
    public toaster: ToastrService,
    public commonService : CommonService,
    public vendorDetailsService : VendorDetailsService
    ) {
    this.tomorrow.setDate(this.tomorrow.getDate() + MagicNumber.zero );
    this.exportSales = this.formBuilder.group({
      start_date: [''],
      end_date: [''],
      status: ['All'],
    });
    this.exportSales.get('start_date').valueChanges.subscribe(val => {
      this.exportSales.get('end_date').setValidators(Validators.required);
      this.exportSales.get('end_date').updateValueAndValidity();
    });
    this.commonService.searchData.subscribe((res)=>{
      this.filteredData = res;
      if(this.filteredData?.status){
        this.exportSales.patchValue({status:this.filteredData?.status === 1 || this.filteredData?.status === 0  ? this.filteredData?.status : 'All'});
      }
    });
  }

  submitProfile(){
    if (this.exportSales.valid) {
      if(this.exportSales.value.start_date){
        this.params = `&start_date=${this.getCalulateDate(this.exportSales.value.start_date)}&end_date=${this.getCalulateDate(this.exportSales.value.end_date)}`;
      }
      if (this.exportSales.value.status !== 'All') {
        this.params += `&status=${this.exportSales.value.status}`;
      }
      if(this.filteredData?.search){
        this.params += `&search=${this.filteredData?.search}`;
      }
      if(this.filteredData?.order_by){
        this.params += `&order_by=${this.filteredData?.order_by}&order=${this.filteredData?.order}`;
      }
      this.vendorDetailsService.exportSalesReport(this.params);
      this.toaster.success('Data exported successfully.');
      this.modalRef.hide();
      // .subscribe((res: any) => {
      //   if (res && !res.errors) {
      //     this.toaster.success(res.message);
      //     this.modalRef.hide();
      //     this.document.location.href = res.data;
      //   }
      // });
    } else {
      this.exportSales.markAllAsTouched();
    }
  }

  getCalulateDate(date){
    const month = date.getMonth() + MagicNumber.one;
    const formattedMonth = month < MagicNumber.ten ? `0${month}` : month.toString();
    const day = date.getDate();
    const formattedDay = day < MagicNumber.ten ? `0${day}` : day.toString();
    const year = date.getFullYear();
    return `${year}-${formattedMonth}-${formattedDay}`;
  }

}
