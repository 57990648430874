import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DashboardAgentService } from '../../services/dashboard-agent.service';
import { MagicNumber } from '../../app-magic-string';

@Component({
  selector: 'app-mark-as-returned',
  templateUrl: './mark-as-returned.component.html'
})
export class MarkAsReturnedComponent implements OnInit {
  public showPass = false;
  public data;
  public soldEvent = new EventEmitter();
  showCountryCode = true;
  returnReasons: any;
  @Output() onClose = new EventEmitter();
  selectedOption: any;
  inputAreaOn = false;
  show = true;
  public markAsReturnedCodeForm: FormGroup;
  textAreaValue ='';
  selectedState = false;
  
  constructor(public formBuilder: FormBuilder, public modalService: BsModalService, public modalRef: BsModalRef, 
    public dashboardService: DashboardAgentService, public toaster: ToastrService) {
    this.markAsReturnedCodeForm = this.formBuilder.group({
      retReasonId: [null, [Validators.required]],
      retReasonComment: ['']
    });
  }

  ngOnInit(): void {
    this.getReturnReason();
  }

  getReturnReason() {
    this.dashboardService.getReturn().subscribe((res) => {
      this.returnReasons = res['data'];
    });
  }

  selectOption(event) {
    this.markAsReturnedCodeForm.patchValue({retReasonId:event.target.value});
    if(event.target.value == MagicNumber.two){
      this.inputAreaOn = true;
      this.markAsReturnedCodeForm.patchValue({retReasonComment:''});
    } else{
      this.inputAreaOn = false;
      this.show = false; 
    }
  }
  onChangevalue(){
    if(this.textAreaValue){
      this.show = false;
    }else{
      this.show = true;
    }
  }

  markAsReturn() {
    if(!this.markAsReturnedCodeForm.value.retReasonId){
      this.toaster.error('','Please Select Issue');
      return;
    }

    if(this.inputAreaOn === true && !this.markAsReturnedCodeForm.value.retReasonComment){
      this.toaster.error('','Please Enter Your Issue');
      return;
    }

    if (this.markAsReturnedCodeForm.valid) {
      const data = {
        close: true,
        markAsReturnedCodeForm: this.markAsReturnedCodeForm.value
      };
      this.onClose.next(data);
      this.onClosed();
    } else {
      this.markAsReturnedCodeForm.markAllAsTouched();
    }
  }

  onClosed() {
    this.modalService.hide();
  }
}

