<div class="main-right-wrapper">
    <div class="dashboard-container">
        <div [routerLink]="['/agent-management/agent-list']" class="back-title"><img src="./assets/images/svg/back-arrow-color.svg " alt="print-logo" />  Back</div>
    </div>
    <div class="sales-box-wrapper">
        <div class="box-titile-wrapper">
            <div class="box-titile-left">
                <div class="box-titile-sec">
                    <div class="box-titile">{{ agentDetails?.name }}</div>
                    <button *ngIf="agentDetails?.status == 1" class="btn button-primary active">Active</button>
                    <button *ngIf="agentDetails?.status == 0" class="btn button-primary inactive">Inactive</button>
                </div>
                <div class="box-email">{{ agentDetails?.email }}</div>
            </div>
            <div class="box-titile-right">
                <button (click)="editProfile()" class="btn btn-outline-primary sales-edit-btn" data-bs-toggle="modal" data-bs-target="#editSalesAgentModal">
                    <svg width="14" height="12" viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="#885D40" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M7 11h6M8.885 1.363a1.239 1.239 0 0 1 1.752 1.752l-7.3 7.3L1 11l.584-2.336 7.3-7.301z"/>
                        </g>
                    </svg>
                    Edit</button>
                <button *ngIf="agentDetails?.status == 0" class="btn button-activate sales-profile-btn" (click)="deactiveAccount()"> Activate Account</button>
                <button *ngIf="agentDetails?.status == 1" class="btn button-deactivate sales-profile-btn" (click)="deactiveAccount()"> Deactivate Account</button>
            </div>
        </div>
        <div class="box-phone-sec">
            <div class="box-phone"><img src="./assets/images/svg/icon-phone.svg" alt="print-logo" />{{agentDetails?.mobile}}</div>
            <div class="box-location"><img src="./assets/images/svg/map-pin-icon.svg" alt="print-logo" />{{agentDetails?.address}}</div>
        </div>
        <div class="qr-code-wrapper-sec">
            <div class="qr-code-wrapper qr-code-flex">
                <div class="qr-code-sec">
                    <div class="qr-code-title">Total QR Memory Codes</div>
                    <div class="qr-code-num">{{ agentDetails?.vendor_total_qrcodes }}</div>
                </div>
                <div class="qr-code-sec">
                    <div class="qr-code-title">QR Memory Codes Issued</div>
                    <div class="qr-code-num">{{ agentDetails?.vendor_sold_qrcodes }}</div>
                </div>
                <div class="qr-code-sec">
                    <div class="qr-code-title">Active Profiles</div>
                    <div class="qr-code-num">{{ agentDetails?.active_profiles }}</div>
                </div>
                <div class="qr-code-sec">
                    <div class="qr-code-title">Commission</div>
                    <div class="qr-code-num">{{ agentDetails?.commission }}%</div>
                </div>
            </div>
            <div class="qr-code-wrapper qr-code-flex commission-left position-relative">
                <div class="qr-code-sec">
                    <div class="qr-code-title">Commission Amount</div>
                    <div class="qr-code-num">${{commissionDetails | number: '1.2-2'}}</div>
                    <div class="qr-code-drop commition-agent">
                        <button type="button" class="dropdown-toggle dropdown-btn-none" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        {{filterStatus}}<span><img src="./assets/images/svg/down-arrow1.svg" alt="down-arrow" /></span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li><button class="dropdown-item" type="button" (click)="searchStatus('month')">Current Month</button></li>
                        <li><button class="dropdown-item" type="button" (click)="searchStatus('year')">Current Year</button></li>
                        <li><button class="dropdown-item" type="button" (click)="searchStatus('total')">Total</button></li>
                        <li><button class="dropdown-item" type="button" (click)="searchStatus('Custom')">Custom</button></li>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-data-table [colFields]="colFields" [tableData]="tableData"></app-data-table>
