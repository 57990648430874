export const VENDOR_COMMISSION_COLS = [
    {
      headerName: 'Name',
      fieldName: 'name',
      fieldType: 'PAYMENT_TEXT',
      fieldAlterName: 'full_name',
      sortOrder: true
    },
    {
      headerName: 'Email ID',
      fieldName: 'email',
      fieldType: 'PAYMENT_TEXT',
      fieldAlterName: 'basic_plan_email',
      sortOrder: false
    },
    {
      headerName: 'Phone Number',
      fieldName: 'mobile',
      fieldType: 'TEXT',
      fieldAlterName: null,
      sortOrder: false
    },
    {
      headerName: 'Commission',
      fieldName: 'commission',
      fieldType: 'COMM',
      fieldAlterName: null,
      sortOrder: true
    },
    {
      headerName: 'Commission Amount',
      fieldName: 'total_commission',
      fieldType: 'DOLLAR',
      fieldAlterName: null,
      sortOrder: false
    },
    {
      headerName: 'Commission Paid',
      fieldName: 'commission_paid',
      fieldType: 'DOLLAR',
      fieldAlterName: null,
      sortOrder: false
    },
    {
      headerName: 'Status',
      fieldName: 'status',
      fieldType: 'STATUS',
      fieldAlterName: null,
      sortOrder: false
    },   
    {
        headerName: 'Action',
        fieldName: 'Vendor',
        fieldType: 'action',
    },
  ];
  
  
  
  