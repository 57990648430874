<div class="main-right-wrapper">
    <div class="dashboard-container">
        <h1 class="section-title title-border-bottom">FAQs</h1>
        <button (click)="addFaq()" class="btn primary faq-add-btn">Add</button>
    </div>
    <div *ngIf="faqData?.data?.length !== 0" class="faq-body">
        <accordion [isAnimated]="true">
            <accordion-group heading="{{data?.question}}" *ngFor="let data of faqData?.data">
                <p class="accordion-des">{{data?.answer}}</p>
                <div class="accordion-button-wrap">
                    <a (click)="deleteFaq(data)" class="accordion-btn">Delete</a>
                    <a (click)="editFaq(data)" class="accordion-btn edit">Edit</a>
                </div>
            </accordion-group>
        </accordion>

    </div>

    <div *ngIf="faqData?.length === 0" class="no-data-container">
        <div>
            <img src="assets/images/svg/no-data-icon.svg" alt="no-data-icon">
        </div>
        <p>No FAQ Available</p>
    </div>
</div>
