import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../../common/app-interface';
import { DeactiveAccountComponent } from '../../../../app/common/modal/deactive-account/deactive-account.component';
import { UserManagementService } from '../../../../app/common/services/user-management.service';
import { ManageCoOwnersComponent } from '../../../../app/common/modal/manage-co-owners/manage-co-owners.component';
import { ExportUserMemberDataComponent } from '../../../../app/common/modal/export-user-member-data/export-user-member-data.component';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html'
})
export class UserDetailComponent {
  public userId;
  public userDetails;

  constructor(public modalService: BsModalService, public activateRoute: ActivatedRoute, public userService: UserManagementService,
    public toastr: ToastrService, public modelref: BsModalRef, public router: Router) {
    this.activateRoute.params.subscribe((params) => {
      if (params.id) {
        this.userId = params.id;
        this.getUserDetails();
      }
    });
  }



  /**
   * API Call for deactivating the account
  */
  deactiveAccount() {
    if (this.userDetails.status === 1) {
      const initialState = { data: { userId: this.userId, type: 'user' } };
      this.modelref = this.modalService.show(DeactiveAccountComponent, { class: 'max-width-605 modal-dialog-centered', initialState });
      this.modelref.content.deactiveEvent.subscribe((res) => {
        if (res.type === 'user') {
          this.getUserDetails();
        }
      });
    } else {
      this.activateAccount();
    }
  }

  exportMember(memberId) {
    const initialState =  { data: memberId };
    this.modelref = this.modalService.show(ExportUserMemberDataComponent, { class: 'max-width-605 modal-dialog-centered', initialState});

  }

  /**
   * To open the edit profile
  */
  showSubOwners() {
    const initialState = { data: this.userDetails };
    this.modelref = this.modalService.show(ManageCoOwnersComponent, { class: 'max-width-729 modal-dialog-centered manage-co-owner-modal', initialState });
    this.modelref.content.editEvent.subscribe((res) => {
      if (res) {
        this.getUserDetails();
      }
    });
  }
  /**
   * API Call for getting user details
  */
  getUserDetails() {
    this.userService.getUserDetails(this.userId).subscribe((res: any) => {
      if (res && !res.errors) {
        this.userDetails = res.data;
      }
    });
  }
  /**
   * API Call for activating the account
  */
  activateAccount() {
    const payload = {
      is_deactivate: 1,
      userId: this.userId
    };
    this.userService.deactiveAccount(payload).subscribe((res: IResponse) => {
      this.toastr.success(res.message);
      this.getUserDetails();
    });
  }
  /**
   * To view the member information details
  */
  ViewMemberDetails(memberId) {
    this.router.navigate(['/information-management/information-detail', memberId]);
  }
  /**
     * To navigate list
    */
  movetoList() {
    this.router.navigate(['/user-management/users-list']);
  }
}
