import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MagicNumber } from '../../app-magic-string';
import { CommonService } from '../../services/common.service';
import { GuestUserManagementService } from '../../services/guest-user-management.service';
import { UserManagementService } from '../../services/user-management.service';
import { InformationManagementService } from '../../services/information-management.service';

@Component({
  selector: 'app-export-information',
  templateUrl: './export-information.component.html'
})
export class ExportInformationComponent {
  exportSales: FormGroup;
  tomorrow = new Date();
  params: any;
  public data;
  filteredData: any;

  constructor(
    public formBuilder: FormBuilder,
    public modalRef: BsModalRef,
    @Inject(DOCUMENT) private readonly document: Document,
    public informationService: InformationManagementService,
    public toaster: ToastrService,
    public commonService: CommonService
  ) {
    this.tomorrow.setDate(this.tomorrow.getDate() + MagicNumber.zero);
    this.exportSales = this.formBuilder.group({
      start_date: [''],
      end_date: ['']  // Excel pre-selected and required
      });

    this.exportSales.get('start_date').valueChanges.subscribe(val => {
      this.exportSales.get('end_date').setValidators(Validators.required);
      this.exportSales.get('end_date').updateValueAndValidity();
    });

    this.commonService.searchData.subscribe((res) => {
      this.filteredData = res;
      if (this.filteredData?.file_type) {
        this.exportSales.patchValue({
          file_type: this.filteredData?.file_type === 1 || this.filteredData?.file_type === 0 ? this.filteredData?.file_type : '1'
        });
      }
    });
  }

  submitProfile() {
    if (this.exportSales.valid) {
      if (this.exportSales.value.start_date) {
        this.params = `start_date=${this.getCalulateDate(this.exportSales.value.start_date)}&end_date=${this.getCalulateDate(this.exportSales.value.end_date)}`;
      }
      if (this.filteredData?.search) {
        this.params += `&search=${this.filteredData?.search}`;
      }
      if (this.filteredData?.order_by) {
        this.params += `&order_by=${this.filteredData?.order_by}&order=${this.filteredData?.order}`;
      }
      this.informationService.exportInformation(this.params); // Call one API if 'user-list' is in the route
      this.toaster.success('Data exported successfully.');
          this.modalRef.hide();
    } else {
      this.exportSales.markAllAsTouched();
    }
  }

  getCalulateDate(date) {
    const month = date.getMonth() + MagicNumber.one;
    const formattedMonth = month < MagicNumber.ten ? `0${month}` : month.toString();
    const day = date.getDate();
    const formattedDay = day < MagicNumber.ten ? `0${day}` : day.toString();
    const year = date.getFullYear();
    return `${year}-${formattedMonth}-${formattedDay}`;
  }
}
