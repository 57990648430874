<!-- start main wrapper -->
<div class="container-fluid">
  <div class="row">
      <div class="col-12 p-0">
          <div class="signin-container">
              <!-- sign in start here -->
              <div class="signin-logo">
                  <img src="./assets/images/svg/signin-logo.svg" alt="signin-logo" />
              </div>

              <form class="login-form" action="dashboard.html">
                  <div class="signin-section">
                      <span class="modal-title" id="exampleModalLabel"><img
              src="./assets/images/svg/header-infinity.svg"
              alt="header-infinity"
              class="modal-header-img"
          /></span>
                      <h1 class="signin-title">Sign In</h1>
                      <p class="sub-head">
                          Sign In to <strong>Eternally Me</strong> with your original account details
                      </p>
                      <div class="login-form-wrapper">
                          <form class="login-container" [formGroup]="signInForm" (ngSubmit)="onSignIn()">
                              <div class="form-floating" [ngClass]="
                  emailId?.invalid && (emailId?.dirty || emailId?.touched)
                    ? 'invalid-input'
                    : ''
                ">
                                  <input type="email" class="form-control" id="emailId" formControlName="emailId" placeholder="name@example.com" />
                                  <label for="emailId">Email ID</label>
                              </div>
                              <ng-container *ngIf="
                  emailId?.invalid && (emailId?.dirty || emailId?.touched)
                ">
                                  <span class="invalid-input-text" *ngIf="emailId?.errors?.required">Email is required</span
                >
                <span
                  class="invalid-input-text"
                  *ngIf="emailId?.errors?.pattern"
                  >Email is not valid</span
                >
              </ng-container>
              <div
                class="form-floating"
                [ngClass]="
                  password?.invalid && (password?.dirty || password?.touched)
                    ? 'invalid-input'
                    : ''
                "
              >
                <input
                  [type]="isShow ? 'text' : 'password'"
                  class="form-control"
                  formControlName="password"
                  id="password"
                  placeholder="Password"
                />
                <label for="password">Password</label>
                <span class="hide-eye">
                  <img
                    src="./assets/images/svg/{{
                      isShow ? 'show' : 'hide'
                    }}-password.svg"
                    (click)="isShow = !isShow"
                    alt="hide-password"
                  />
                </span>
                      </div>

                      <ng-container *ngIf="
                  password?.invalid && (password?.dirty || password?.touched)
                ">
                          <span class="invalid-input-text" *ngIf="password?.errors?.required">Password is required</span
                >
              </ng-container>
              <div class="forgot-pw">
                <a title="Forgot Password?" (click)=" forgotPassword()" class="forgot-link ms-auto">Forgot
                    Password?</a>
            </div>
              <div class="form-action">
                <button class="btn primary w-100" type="submit">
                  Sign In
                </button>
              </div>
            </form>
            <span class="signin-footer">
              <img
                src="./assets/images/svg/footer-heart.svg"
                alt="footer-heart"
                class="modal-footer-img"
              />
            </span>
                  </div>
          </div>
          </form>
      </div>
      <!-- end signin container wrapper -->
  </div>
</div>
</div>
<!-- end main wrapper -->