<div style="margin-top: 25px;">
    <div class="modal-header add-sales-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modalRef.hide()"></button>
    </div>
    <div class="modal-body">
        <h1 class="modal-export-title">Export Data</h1>
        <h1 class="modal-export-subtitle">Please select the date range for the data you wish to export.</h1>
        <form class="modal-form" [formGroup]="exportSales" (ngSubmit)="submitProfile()">
            <div class="form-floating form-floating-search birth-input date-picker-sec">
                <mat-form-field appearance="fill">
                    <mat-label>Select Date Range</mat-label>
                    <mat-date-range-input (click)="picker.open()" [rangePicker]="picker" [max]="tomorrow">
                        <input matStartDate placeholder="MM/DD/YY - MM/DD/YY" formControlName="start_date">
                        <input matEndDate formControlName="end_date">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
                <mat-error class="invalid-input-text-date" *ngIf="exportSales.controls.start_date.invalid && exportSales.controls.start_date.touched">
                    Start date is required.
                </mat-error>
                <mat-error class="invalid-input-text-date" *ngIf="exportSales.controls.end_date.invalid && exportSales.controls.end_date.touched">
                    End date is required.
                </mat-error>
            </div>
            <button type="submit" [disabled]="exportSales.invalid" class="btn primary w-100 mb-3">Export</button>
        </form>
    </div>
</div>
