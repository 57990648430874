import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../app-interface';
import { MagicNumber } from '../../app-magic-string';
import { AgentManagementService } from '../../services/agent-management.service';
import { QrcodeManagementService } from '../../services/qrcode-management.service';

@Component({
  selector: 'app-change-shipping-status',
  templateUrl: './change-shipping-status.component.html',
  styleUrls: ['./change-shipping-status.component.scss']
})
export class ChangeShippingStatusComponent implements OnInit {

  public data;
  public manageShipping = new EventEmitter();
  public manageShippingForm: FormGroup;
  public QRCodes = [MagicNumber.ten, MagicNumber.fifty, MagicNumber.hundred, MagicNumber.twoHundred, MagicNumber.fiveHundred];
  public selectedIndex: number = MagicNumber.zero;
  @Output() onClose = new EventEmitter();
  statusOptions;
  dropdownOpen = false;
  selectedOption: any;
  selectedOptionId: any;
  rememberAssign = false;
  remAssign = false;
  paramsAg: string;

  constructor(public formBuilder: FormBuilder, public modalRef: BsModalRef , public modalService: BsModalService, 
    public qrcodeService : QrcodeManagementService, public toaster: ToastrService,  public agentService: AgentManagementService) {
  }


  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  selectOption(event: any) {
    const selectedStatus = event; // Event will hold the selected status value
    this.selectedOption = selectedStatus;
  }

  ngOnInit(): void {
    this.statusOptions = [{name: 'Ordered'},{name: 'Re-Shipped'},{name: 'Processing'},{name: 'Completed'}];
    const defaultStatus = this.data?.admin_shipping_status || '';
    this.manageShippingForm = this.formBuilder.group({
      selectedOption: [defaultStatus], // Pre-fill the selected status
      rememberAssign: [false]
    });
    this.selectedOption = this.manageShippingForm.value.selectedOption;
  }






  /**
   * form param return
  */

  get qrCodeType() { return this.manageShippingForm.get('qrCodeType'); }

  get qrCodeNumber() { return this.manageShippingForm.get('qrCodeNumber'); }



  /**
   * On generate QR code form submit
  */
  manageQrCode() {
    if (this.manageShippingForm.valid) {
      const data = {
        close: true,
        manageQrCodeForm: this.manageShippingForm.value
      };
      this.onClose.next(data);
      this.onClosed();
    } else {
      this.manageShippingForm.markAllAsTouched();
    }
  }

    assignShipping() {
      if( !this.selectedOption){
        this.toaster.error('Please select status');
        return;
      }
      this.paramsAg = 'qr_code_id=' + this.data.id + '&admin_shipping_status=' + this.selectedOption;

      this.qrcodeService.changeShipping(this.paramsAg).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toaster.success(res.message);
          this.manageShipping.emit({ res: 200 });
          this.modalRef.hide();
        }
      });
  }

  onClosed() {
    this.modalService.hide();
  }


  /**
   * Activate Selected QR Code
   * @param index is an selected index
   * @param value is selected Item
  */



//   unassignQrCode() {
//     this.agentService.unassignQrCod(this.data).subscribe((res: IResponse) => {
//       if (res && !res.errors) {
//         this.toaster.success(res.message);
//         this.unassignEvent.emit({ res: 200 });
//         this.modalRef.hide();
//       }
//     });
// }


  /**
   * Change event type
   * @param event is an action
  */



}

