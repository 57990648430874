import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../common/app-interface';
import { AuthService } from '../../../app/common/services/auth.service';
import { UserManagementService } from '../../common/services/user-management.service';

@Component({
  selector: 'app-create-user-password',
  templateUrl: './create-user-password.component.html'
})
export class CreateUserPasswordComponent implements OnInit {
  public data;
  public changePasswordForm: FormGroup;
  public oldShow = false;
  public newShow = false;
  public currentShow = false;
  public createPassword = new EventEmitter();

  constructor(public formBuilder: FormBuilder, public modalRef: BsModalRef, public authService: AuthService, public userService: UserManagementService,
    public toastr: ToastrService, public router: Router) {
    this.changePasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]]
    });
  }

  ngOnInit(): void {
  }




  /**
   * form param return
  */

  get oldPassword() { return this.changePasswordForm.get('oldPassword'); }
  get newPassword() { return this.changePasswordForm.get('newPassword'); }
  get cnfrmPassword() { return this.changePasswordForm.get('cnfrmPassword'); }

  /**
   * On Change Password form submit
  */
  onChangePassword() {
    if (this.changePasswordForm.valid) {
        const params = {
          id: this.data.id,
          newPassword: this.changePasswordForm.value.newPassword
        };
        this.userService.changeUserPassword(params).subscribe((res: IResponse) => {
          if (res && !res.errors) {
            this.toastr.success(res.message);
          this.createPassword.emit({ type: true });
          this.modalRef.hide();
          } else {
            this.toastr.error(res.message);
          }
          
        });
    } else {
      this.changePasswordForm.markAllAsTouched();
    }
  }
}
