export const GUEST_USER_MNGMNT_COLS = [
    {
        headerName: 'Name',
        fieldName: 'name',
        fieldType: 'IMG_TEXT',
        sortOrder: true
    },
    {
        headerName: 'Email ID',
        fieldName: 'email',
        fieldType: 'TEXT',
        sortOrder: true
    },
    {
        headerName: 'Phone Number',
        fieldName: 'contact_number',
        fieldType: 'TEXT',
        sortOrder: false
    },
    {
        headerName: 'Relationship',
        fieldName: 'relation',
        fieldType: 'TEXT',
        sortOrder: false
    },
    {
        headerName: 'Email Opt-out',
        fieldName: 'opt_out',
        fieldType: 'OPT',
        sortOrder: false
    },
    {
        headerName: 'Added On',
        fieldName: 'created_at',
        fieldType: 'DATE',
        sortOrder: true
    },
    {
        headerName: 'Deceased Member',
        nestedField: null,
        fieldName: 'member',
        fieldType: 'DEC_TEXT_GUEST',
        sortOrder: true
    }
];

