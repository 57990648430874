import { Component } from '@angular/core';
import { CONTENT_MNGMNT_COLS } from './cms-list-col-def';

@Component({
  selector: 'app-cms-list',
  templateUrl: './cms-list.component.html'
})
export class CmsListComponent {
  public colFields = CONTENT_MNGMNT_COLS;
  public tableData = {
    tableHeading: 'Content Management', tableType: 'cms', routerLink: '/content-management/cms-detail', containerClass: 'table-container-user table-container-content',
    headClass: 'table-head-user',
    rowClass: 'table-row-user',
    bodyClass: 'table-body-user',
    hideSearch: true,
    hideFilter: true,
    hidePagination: true,
    hideSort: true
  };


}
