import { Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DeactiveAccountComponent } from '../../../../app/common/modal/deactive-account/deactive-account.component';
import { UserManagementService } from '../../../../app/common/services/user-management.service';
import { USER_MNGMNT_COLS } from './user-management.col.def';
@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html'
})
export class UsersListComponent {
  public colFields = USER_MNGMNT_COLS;
  public tableData = {
    tableHeading: 'User Management', tableType: 'user', serviceName: 'userService',
    containerClass: 'table-container-user', headClass: 'table-head-user', rowClass: 'table-row-user',
    bodyClass: 'table-body-user', routerLink: '/user-management/users-detail', exportUserData: true
  };

  constructor(public modalService: BsModalService, public bsModalRef: BsModalRef, private readonly _userService: UserManagementService) { }



  deactiveAccount() {
    this.bsModalRef = this.modalService.show(DeactiveAccountComponent);
  }
}
