<div class="container-fluid">
  <div class="row">
    <div class="col-12 p-0">
      <div class="signin-container">
        <!-- sign in start here -->
        <div class="signin-logo">
          <img src="./assets/images/svg/signin-logo.svg" alt="signin-logo" />
        </div>

        <form class="login-form">
          <div class="signin-section">
            <span class="modal-title" id="exampleModalLabel"
              ><img
                src="./assets/images/svg/header-infinity.svg"
                alt="header-infinity"
                class="modal-header-img"
            /></span>
            <h1 class="signin-title">Forgot Password?</h1>
            <p class="sub-head">
              Enter your registered email address and we’ll mail you the Reset
              password instructions
            </p>
            <div class="login-form-wrapper">
              <form class="login-container" [formGroup]="forgotPasswordForm">
                <div class="form-floating">
                  <input
                    type="email"
                    class="form-control"
                    id="emailId"
                    placeholder="name@example.com"
                    formControlName="emailId"
                  />
                  <label for="emailId">Email ID</label>
                </div>
                <ng-container *ngIf="emailId?.invalid && (emailId?.dirty || emailId?.touched)">
                  <p class="invalid-input-text" *ngIf="emailId?.errors?.required">Email is required</p>
                  <p class="invalid-input-text" *ngIf="emailId?.errors?.pattern">Email is not valid</p>
              </ng-container>
                <div class="form-action">
                  <button class="btn primary w-100" type="submit" (click)="onSendForgetPasswordMail()">
                    Send Reset Password Link
                  </button>
                </div>
                <p class="signup-para text-center">
                  Back to <a routerLink="/signin" class="signup-link">Sign In</a>
                </p>
              </form>
              <span class="signin-footer">
                <img
                  src="./assets/images/svg/footer-heart.svg"
                  alt="footer-heart"
                  class="modal-footer-img"
                />
              </span>
            </div>
          </div>
        </form>
      <!-- end signin container wrapper -->
    </div>
  </div>
</div>
