<div class="modal-header">
    <button type="button" class="btn-close" (click)="modalRef.hide()" data-bs-dismiss="modal"
        aria-label="Close"></button>
</div>
<div class="modal-body">
    <h1 class="modal-title">Delete FAQ?</h1>
    <p class="subhead">Are you sure you want to delete FAQ?</p>
    <div class="faq-btn-wrap">
        <button (click)="modalRef.hide()" class="btn primary btn-outline-primary">Cancel</button>
        <button (click)="confirmDelete()" class="btn primary">Yes</button>
    </div>
</div>
