<div class="container-fluid">
    <div class="main-wrapper">
        <app-header></app-header>
        <div class="main-right-wrapper padding-dashboard">
            <div class="dashboard-container">
                <h1 class="section-title title-border-bottom m-0">Analytics & Reports</h1>
            </div>
            <div class="analytics-head-wrapper">
              <div class="analytics-head-text">Analytics</div>
              <div class="analytics-head-right">
                <div class="analytics-drop">
                        <button type="button" class="dropdown-toggle dropdown-btn-none" id="filterdMenuButton1" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        {{filterStatus}}
                        <span><img src="./assets/images/svg/down-arrow-color.svg" alt="down-arrow" /></span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="filterdMenuButton1">
                      <li><button class="dropdown-item" type="button" (click)="searchStatus('month')">Current Month</button></li>
                      <li><button class="dropdown-item" type="button" (click)="searchStatus('year')">Current Year</button></li>
                      <li><button class="dropdown-item" type="button" (click)="searchStatus('total')">Total</button></li>
                      <li><button class="dropdown-item" type="button" (click)="searchStatus('custom')">Custom</button></li>
                    </ul>
                    </div>
                </div>
            </div>
            <div class="qr-code-sold-wrapper margin-botom">
                <div class="qr-code-sold-sec">
                    <div class="qr-code-title">Active Users</div>
                    <div class="qr-code-text">{{commissionDetails?.active_user || 0}}</div>
                </div>
                <div class="qr-code-sold-sec">
                    <div class="qr-code-title">No. of Basic Plans</div>
                    <div class="qr-code-text">{{commissionDetails?.total_basic_plan || 0}}</div>
                </div>
                <div class="qr-code-sold-sec">
                    <div class="qr-code-title">No. of Standard Plans</div>
                    <div class="qr-code-text">{{commissionDetails?.total_standard_plan || 0}}</div>
                </div>
                <div class="qr-code-sold-sec">
                    <div class="qr-code-title">No. of Upgraded Plans</div>
                    <div class="qr-code-text">{{commissionDetails?.total_upgraded_plan || 0}}</div>
                </div>
            </div>
            <div class="container-fluids top-border-head">
                <div class="row grap-top-margin">
                  <div class="col-12">
                    <div class="graph-box position-relative">
                      <div class="row">
                        <div class="col-9">
                          <div class="graph-head">
                            Revenue Generated
                          </div>
                        </div>
                        <div class="col-4">
                          <!-- filter option -->
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3">
                          <div class="graph-legend">
                            <div class="brown-color-box"></div> <div class="count-text">${{totalBasicPlanRevenue | number: '1.2-2'}}<br><span>Basic Plan</span></div>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="graph-legend">
                            <div class="blue-color-box"></div> <div class="count-text">${{totalStandardPlanRevenue | number: '1.2-2'}}<br><span>Standard Plan</span></div>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="graph-legend">
                            <div class="red-color-box"></div> <div class="count-text">${{totalUpgradedPlanRevenue | number: '1.2-2'}}<br><span>Upgraded Plan</span></div>
                          </div>
                        </div>
                      </div>
                      <div id="revenue-report"></div>
                      <div class="filter-dropdown">
                        <div class="qr-code-drop graph-sec">
                          <button type="button" class="dropdown-toggle dropdown-btn-none" id="filterdMenuButton" data-bs-toggle="dropdown"
                          aria-expanded="false">
                          {{typeRevenue}}
                          <span><img src="./assets/images/svg/down-arrow-color.svg" alt="down-arrow" /></span>
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="filterdMenuButton">
                          <li><button (click)="getRevenueGraph('DAYS')" class="dropdown-item" type="button" >Current Month</button></li>
                          <li><button (click)="getRevenueGraph('MONTHS')" class="dropdown-item" type="button">Current Year</button></li>
                          <li><button (click)="getRevenueGraph('YEARS')" class="dropdown-item" type="button">Total</button></li>
                          <li><button (click)="customGraph('revenue')" class="dropdown-item" type="button">Custom</button></li>
                      </ul>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="graph-box position-relative">
                      <div class="row">
                        <div class="col-9">
                          <div class="graph-head">
                            Commission
                          </div>
                        </div>
                        <div class="col-4">
                          <!-- filter option -->
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3">
                          <div class="graph-legend">
                            <div class="yellow-color-box"></div> <div class="count-text"> ${{totalCommission | number: '1.2-2'}}<br><span>Total Commission</span></div>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="graph-legend">
                            <div class="green-color-box"></div> <div class="count-text"> ${{commissionPaid | number: '1.2-2'}} <br><span>Commission Paid</span></div>
                          </div>
                        </div>
                      </div>
                      <div id="commission-report"></div>
                      <div class="filter-dropdown">
                        <div class="qr-code-drop graph-sec">
                          <button type="button" class="dropdown-toggle dropdown-btn-none" id="filterdMenuButton" data-bs-toggle="dropdown"
                          aria-expanded="false">
                          {{typeCommission}}
                          <span><img src="./assets/images/svg/down-arrow-color.svg" alt="down-arrow" /></span>
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="filterdMenuButton">
                          <li><button (click)="getCommissionGraph('DAYS')" class="dropdown-item" type="button" >Current Month</button></li>
                          <li><button (click)="getCommissionGraph('MONTHS')" class="dropdown-item" type="button">Current Year</button></li>
                          <li><button (click)="getCommissionGraph('YEARS')" class="dropdown-item" type="button">Total</button></li>
                          <li><button (click)="customGraph('commission')" class="dropdown-item" type="button">Custom</button></li>
                      </ul>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 px-2">
                    <div class="graph-box position-relative">
                      <div class="row">
                        <div class="col-9">
                          <div class="graph-head">
                            Overview
                          </div>
                        </div>
                        <div class="col-3">
                          <!-- filter option -->
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3">
                          <div class="graph-legend">
                            <div class="brown-color-box"></div> <div class="count-text">{{totalBasicPlan | number: '1.0-0'}}<br><span>Basic Plan</span></div>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="graph-legend">
                            <div class="blue-color-box"></div> <div class="count-text">{{totalStandardPlan | number: '1.0-0'}}<br><span>Standard Plan</span></div>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="graph-legend">
                            <div class="red-color-box"></div> <div class="count-text">{{totalUpgradedPlan | number: '1.0-0'}}<br><span>Upgraded Plan</span></div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12"><div id="overview-report"></div></div>
                      </div>
                      <div class="filter-dropdown">
                        <div class="qr-code-drop graph-sec">
                          <button type="button" class="dropdown-toggle dropdown-btn-none" id="filterdMenuButton" data-bs-toggle="dropdown"
                          aria-expanded="false">
                          {{typeOverview}}
                          <span><img src="./assets/images/svg/down-arrow-color.svg" alt="down-arrow" /></span>
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="filterdMenuButton">
                          <li><button (click)="getOverviewGraph('DAYS')" class="dropdown-item" type="button" >Current Month</button></li>
                          <li><button (click)="getOverviewGraph('MONTHS')" class="dropdown-item" type="button">Current Year</button></li>
                          <li><button (click)="getOverviewGraph('YEARS')" class="dropdown-item" type="button">Total</button></li>
                          <li><button (click)="customGraph('overview')" class="dropdown-item" type="button">Custom</button></li>
                      </ul>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>
    </div>
</div>