import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UtilityService } from '../../services/utility.service';
import { UserManagementService } from '../../services/user-management.service';
import { IResponse } from '../../app-interface';

@Component({
  selector: 'app-manage-co-owners',
  templateUrl: './manage-co-owners.component.html',
  styleUrls: ['./manage-co-owners.component.scss']
})
export class ManageCoOwnersComponent implements OnInit {
  data: any;
  coOwnersData = [];
  deleteUser:any;
  subOwnerData;

  constructor(public commonService: CommonService, public toastr: ToastrService,public router: Router,
     public utilityService: UtilityService,   public userService: UserManagementService,
    public modelRef: BsModalRef) {
  }


  ngOnInit(): void {
      this.getCoOwners();
  }

  getCoOwners(order?) {
    let params;
    if(order){
      params = `order=${order}&order_by=name&limit=100&user_id=${this.data.id}`;
    } else{
      params = `limit=100&user_id=${this.data.id}`;
    }
    this.userService.getSubOwners(params).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.subOwnerData = res.data;
      }
    });
  }


}
