export const AD_MNGMNT = [
    {
        headerName: 'Name',
        fieldName: 'name',
        fieldType: 'TEXT',
        sortOrder: true,
        orderby: 'name'
    },
    {
        headerName: 'IP Address',
        fieldName: 'ip_address',
        fieldType: 'TEXT',
        sortOrder: false
    },
    {
        headerName: 'Date',
        fieldName: 'created_at',
        fieldType: 'DATE',
        sortOrder: true,
        orderby: 'created_at'
    },
    {
        headerName: 'Time',
        fieldName: 'created_at',
        fieldType: 'TIME',
        sortOrder: false,
        orderby: 'created_at'
    },
    {
        headerName: 'URL',
        fieldName: 'url',
        fieldType: 'HTMLURL',
        sortOrder: true,
        orderby:'url'
    }
];
