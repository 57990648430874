<div style="margin-top: 25px;">
    <div class="modal-header add-sales-header">
        <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
    </div>
    <div class="modal-body">
        <h1 class="modal-export-title">Add Payment</h1>
        <h1 class="modal-export-subtitle">Add payments to your vendors</h1>
        <form class="modal-form" [formGroup]="addPaymentForm" (ngSubmit)="submitPayment()">
            <div class="form-floating">
                    <input type="number" class="form-control" formControlName="enterAmount" id="floatingInput"
                        placeholder="Enter Amount" maxlength="50">
                    <label for="floatingInput">Enter Amount</label>
                    <div class="invalid-input-text-err" *ngIf="addPaymentForm?.value?.enterAmount > vendorCommission">Payment amount is exceeding remaining amount.</div>
                    <div class="invalid-input-text-err" *ngIf="addPaymentForm?.controls.enterAmount.errors?.pattern">
                        Value should be greater than zero.
                      </div>
                          <div class="invalid-input-text-err" *ngIf="addPaymentForm?.value?.enterAmount === 0">
                            Value should be greater than zero.
                          </div>
                </div>
            <button type="submit" [disabled]="addPaymentForm?.invalid || addPaymentForm?.value?.enterAmount <=1 || (addPaymentForm?.value?.enterAmount > vendorCommission)" class="btn primary w-100 mb-3">Add Payment</button>
        </form>
    </div>
</div>