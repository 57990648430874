<!-- Edit about modal -->
<div class="modal-dialog modal-dialog-centered max-width-1029">
  <div class="modal-content">
    <div class="modal-header padding-bottom-0">
      <button type="button" class="btn-close edit-about-close" data-bs-dismiss="modal" (click)="modalRef.hide()"
        aria-label="Close"></button>
    </div>
    <div class="modal-body pb-8">
      <h1 class="modal-title margin-bottom-21">Edit {{ data?.type }}</h1>
      <form [formGroup]="viewInformationForm" (ngSubmit)="updateChanges()" class="modal-form edit-about-form">
        <editor formControlName="content" [apiKey]="tinyMceKey" #tinymce id="editor custom-editor"
          (onKeyUp)="editorChanges($event)" [init]="{
            height: 316,
            menubar: false,
            branding: false,
            statusbar: false,
            placeholder: 'Type here...',
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help'
          }"></editor>
        <p [innerHTML]="viewInformationForm.value.content" [hidden]="true" id="contentInformation"></p>
        <ng-container *ngIf="
            isSubmitted &&
            informationContent?.invalid &&
            (informationContent?.dirty || informationContent?.touched)
          ">
          <span class="invalid-input-text" *ngIf="informationContent?.errors?.required">{{ data.type }} is
            required</span>
        </ng-container>
        <span class="invalid-input-text" *ngIf="showMaxlength">{{ data.type }} should not be greater than 300
          words</span>
        <div class="edit-about-btn mt-32">
          <button type="button" class="btn btn-outline-primary margin-right-25" (click)="modalRef.hide()">
            Cancel
          </button>
          <button type="submit" class="btn primary">Submit</button>
        </div>
      </form>
    </div>
  </div>
</div>