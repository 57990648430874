import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../../services/utility.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IResponse } from '../../app-interface';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-view-qrcode',
  templateUrl: './view-qrcode.component.html'
})
export class ViewQrcodeComponent implements OnInit {
  public data;
  public qrCodeData;
  memorialUrl: string;
  constructor(public utilityService: UtilityService, public modalRef: BsModalRef, public toastr: ToastrService) { }

  ngOnInit(): void {
    this.memorialUrl = environment.memorialUrl;
    this.getQrCode();
  }
  copyMemoryCode(url) {
    const routeUrl = url;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.innerText = routeUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success('QR Memory Code Copied');
  }
  /**
   * Get QR Memory Code
  */
  getQrCode() {
    this.utilityService.getQRCodeSerialNumber(this.data && this.data.qrInfo.serial_number).subscribe((res: IResponse) => {
      this.qrCodeData = res.data;
    });
  }
}
