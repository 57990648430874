export const ORDER_MNGMNT_COLS = [
  {
      headerName: 'Memory Code',
      fieldName: 'serial_number',
      nestedField:null,
      fieldType: 'ADDRESS',
      sortOrder:true,
      orderby:'serial_number'
  },
  {
    headerName: 'Box Number',
    fieldName: 'box_no',
    nestedField:null,
    fieldType: 'TEXT',
    sortOrder:false
    },
  {
      headerName: 'Generated On',
      fieldName: 'created_at',
      fieldType: 'DATE',
      sortOrder: true,
      orderby:'generated_on'
  },
  {
      headerName: 'Issued To',
      fieldName: 'user_name',
      fieldType: 'TEXT',
      sortOrder: true,
      orderby:'name'
  },
  {
      headerName: 'Issued On',
      fieldName: 'sold_on',
      fieldType: 'DATE',
      sortOrder: true,
      orderby:'sold_on'
  },
  {
    headerName: 'Delivery Address',
    fieldName: 'address',
    fieldType: 'SHIPPING_ADDRESS',
    sortOrder: false
},
  {
    headerName: 'Shipping Status',
    fieldName: 'shipping_status',
    fieldType: 'SH_STATUS',
    sortOrder: true,
    orderby:'shipping_status'
},
  {
      headerName: 'Status',
      fieldName: 'ch_status',
      fieldType: 'CH_STATUS',
      sortOrder: true,
      orderby:'admin_shipping_status'
  },
  {
    headerName: 'Action',
    fieldName: 'added_by',
    fieldType: 'QR_ICON',
    sortOrder: false,
    orderby: null
}

];

