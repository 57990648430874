import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VendorCommissionService {

  constructor(public http: HttpClient) { }
  getData(params?) {
    return this.http.get(`${environment.apiUrl}admin/get-vendor-commission?${params}`);
  }
  getVendorDetails(userId: number) {
    return this.http.get(`${environment.apiAdminUrl}get-vendor-commission?user_id=${userId}`);
  }
  getVendorTransaction(userId: number){
    return this.http.get(`${environment.apiAdminUrl}vendor-transaction/${userId}`);
  }
  addVendorTransaction(payloads:any){
    return this.http.post(`${environment.apiAdminUrl}add-vendor-transaction?${payloads}`,'');
  }
  deletePayment(payloads:any){
    return this.http.delete(`${environment.apiAdminUrl}delete-transaction?${payloads}`);
  }
}
