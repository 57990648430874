<div class="container-fluid">
    <div class="main-wrapper">
        <app-header></app-header>
        <div class="main-right-wrapper padding-dashboard">
            <div class="dashboard-container">
                <h1 class="section-title title-border-bottom m-0">Dashboard</h1>
            </div>
            <div class="qr-code-sold-wrapper">
                <div class="qr-code-sold-sec">
                    <div class="qr-code-title">Total QR Memory Code</div>
                    <div class="qr-code-text">{{dashboardDetails?.vendor_total_qrcodes}}</div>
                </div>
                <div class="qr-code-sold-sec">
                    <div class="qr-code-title">QR Memory Code Issued</div>
                    <div class="qr-code-text">{{dashboardDetails?.vendor_sold_qrcodes}}</div>
                </div>
                <div class="qr-code-sold-sec">
                    <div class="qr-code-title">My Commission Percentage</div>
                    <div class="qr-code-text">{{dashboardDetails?.commission}}%</div>
                </div>
                <div class="qr-code-sold-sec">
                    <div class="qr-code-title">My Commission</div>
                    <div class="qr-code-text">${{commissionDetails | number: '1.2-2'}}</div>
                    <div class="qr-code-drop dashboard">
                        <button type="button" class="dropdown-toggle dropdown-btn-none" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        {{filterStatus}}<span><img src="./assets/images/svg/down-arrow1.svg" alt="down-arrow" /></span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li><button class="dropdown-item" type="button" (click)="searchStatus('month')">Current Month</button></li>
                        <li><button class="dropdown-item" type="button" (click)="searchStatus('year')">Current Year</button></li>
                        <li><button class="dropdown-item" type="button" (click)="searchStatus('total')">Total</button></li>
                        <li><button class="dropdown-item" type="button" (click)="searchStatus('Custom')">Custom</button></li>

                    </ul>
                    </div>
                </div>
            </div>
            <app-data-table [colFields]="colFields" [tableData]="tableData"></app-data-table>
        </div>
    </div>
</div>



