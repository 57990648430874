import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { of, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from './common.service';
import { MagicNumber } from '../app-magic-string';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {
  public token;
  public destroy$ = new Subject();
  constructor(public loaderService: NgxSpinnerService, public toastr: ToastrService,
    public modalService: BsModalService, public commonStateService: CommonService, 
    public router: Router) {
    this.commonStateService.userData.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.token = res.token;
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    this.loaderService.show();
    let authorizedRequest;
    // const headers
    authorizedRequest = req.clone({
      headers: this.token ? req.headers.set('Authorization', 'Bearer ' + this.token).set('Content-Type', 'application/json') : req.headers.set('Content-Type', 'application/json'),
    });
    if (req.url.includes('image-upload') || req.url.includes('update_static_content')) {
      authorizedRequest = req.clone({
        headers: this.token ? req.headers.set('Authorization', 'Bearer ' + this.token) : req.headers.set('Content-Type', 'application/json')
      });
    }

    return next.handle(authorizedRequest).pipe(

      tap(res => {
        if (res instanceof HttpResponse && res.status === MagicNumber.twoHundred) {

        }
        return res;
      }),
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.error.message !== 'No result(s) found.') {
            this.toastr.error(err.error.message ? err.error.message : 'Something went wrong');
          }
          if (err.status === MagicNumber.fourHundredOne) {
            this.commonStateService.userData.next(null);
            localStorage.clear();
            this.modalService.hide();
            this.router.navigate(['/']);
          }
        }
        return of(err);
      }),
      finalize(() => {
        this.loaderService.hide();
      }));
  }
}


