<div class="modal-header modal-return-head">
    <div class="modal-header-wrapper"><img src="./assets/images/svg/header-infinity.svg" alt="header-infinity.svg" class="modal-header-img" /></div>
    <button (click)="modalRef.hide()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body having-modal-sec">
    <div class="having-modal-title">Having Issues With The QR Memory Code?</div>
    <div class="having-modal-subtitle">Please select what is the issue you are facing with the QR Memory code.</div>
    <form [formGroup]="markAsReturnedCodeForm" (ngSubmit)="markAsReturn()" class="modal-form qr-code-modal-form">

        <div class="form-floating state-input right-margin-0 mb-34">
            <select class="form-select plan-form-select"  (change)="selectOption($event);selectedState=true" [(ngModel)]="selectedOption" formControlName="retReasonId" id="floatingSelect">
                <option *ngFor="let data of returnReasons; let idx = index" [value]="data.id" [id]='data.id'>{{data.reason}}</option>
              </select>
            <label for="floatingSelect" class="state-label" [ngClass]="selectedState?'removeMar':''">Select Your Issue</label>
        </div>
        <div *ngIf="inputAreaOn" class="textarea-sec">
            <div class="textarea-message">
                <div class="form-floating mb-20">
                    <textarea onkeypress="return this.value.trim() !== '' || /\S/.test(event.key)" [(ngModel)]="textAreaValue" (keyup)="onChangevalue()" maxlength="100" class="form-control height-112" placeholder="Message" formControlName="retReasonComment" id="floatingTextarea"></textarea>
                    <label for="floatingTextarea">Type your issue here</label>
                </div>
            </div>
        </div>
        <button type="submit" class="btn primary w-100" [disabled]="show || (!markAsReturnedCodeForm.value.retReasonComment && markAsReturnedCodeForm.value.retReasonId == 2)">Submit</button>
    </form>
    <!-- (!memoryCodeText && codeNow===3) || show -->
</div>
<div class="modal-footer pb-20">
    <div class="modal-footer-wrapper">
        <img src="./assets/images/svg/footer-heart.svg" alt="footer-heart" class="modal-footer-img" />
    </div>
</div>